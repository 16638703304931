<div class="pds-table--dense pds-card pt-10 external-dashboard-container">
  <form [formGroup]="filterForm">
    <!--Start of Filter Table-->
    <mat-table [dataSource]="dataSource" matSort multiTemplateDataRows (matSortChange)="sortData($event)" [matSortActive]="sortBy"
               matSortDirection="desc" class="pds-table--filter">
      <!--Using multi row in filter table-->
      <mat-header-row *matHeaderRowDef="externalAuditDisplayedColumns"></mat-header-row>
      <mat-row *matRowDef="let i = index; columns: externalAuditDisplayedColumns" class="example-element-row"></mat-row>
      <mat-footer-row *matFooterRowDef="['noResultsFound']; sticky: true" [ngClass]="{'hide':!hidePaginator}"></mat-footer-row>

      <!--Header field and filter display generic based on dashboardColumns array -->
      <ng-container matColumnDef="{{column?.key}}" *ngFor="let column of externalDashboardColumns; let i = index">
        <!--Display header column values-->
        <mat-header-cell *matHeaderCellDef [class.action-flex]="column?.key == 'action'">
          <!--Header and filter design getting from ACL-->
          <div class="pds-table--filter__header">
            <div class="pds-table--filter__header__label pl-0" [attr.aria-label]="column?.value">{{column?.value}}</div>
            <div class="pds-table--filter__header__sort" mat-sort-header *ngIf="column?.type != 'NONE'"></div>
            <!--Display filter icon for ACTION column-->
            <ng-container *ngIf="column?.key == 'action'" class = "action-flex">
              <div class="pds-table--filter__header__input filter-align" [ngClass]="{'active-filter':isFilterAction}">
                <mat-icon class="filter-icon pds-icon--xs" svgIcon="filter-f--xs" aria-hidden="false" [attr.aria-label]="isFilterAction ? 'Filter is active' : 'Filter is not active'" tabindex="0"></mat-icon>
              </div>
            </ng-container>
            <!-- Display Input, Dropdown and Date picker in header-->
            <ng-container *ngIf="column?.key != 'action'">
              <div class="pds-table--filter__header__input">

                <!--Checking for Input values-->
                <mat-form-field *ngIf="column?.type == 'INPUT'" class="cvs-compact-form-field" appearance="outline"
                                (keydown.enter)="stopPropagation($event)" (keydown.space)="$event.stopPropagation()">
                  <input matInput formControlName="{{column?.key}}" [attr.aria-label]="column?.value" id="{{column?.key}}"/>
                  <button *ngIf="filterForm.get(column?.key)?.value" matSuffix mat-icon-button aria-label="Clear input value"
                          (click)="clearCurrentFilter(column?.key)" (keydown.enter)="clearCurrentFilter(column?.key)">
                    <mat-icon svgIcon="close-btn--s" class="icon-size"></mat-icon>
                  </button>
                </mat-form-field>

                <!--Checking for Dropdown values-->
                <mat-form-field *ngIf="column?.type == 'DROP_DOWN'" class="cvs-compact-form-field" appearance="outline"
                                (keydown.enter)="stopPropagation($event)" (keydown.space)="$event.stopPropagation()">
                  <mat-select formControlName="{{column?.key}}" multiple [attr.aria-label]="column?.value" tabIndex="0">
                    <div class="select-all-option p-10" (click)="toggleAllSelection(column)" (keydown.enter)="toggleAllSelection(column)">
                      <mat-checkbox *ngIf="column.key == 'auditType'" #auditTypeDropdown [indeterminate]="isIndeterminate(column?.key)" (click)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()">Select All</mat-checkbox>
                      <mat-checkbox *ngIf="column.key == 'auditStatus'" #auditStatusDropdown [indeterminate]="isIndeterminate(column?.key)" (click)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()">Select All</mat-checkbox>
                    </div>
                    <mat-option *ngFor="let option of stateOptions[column?.key]" [value]="option.id" (click)="toggleOption(column)" (keydown.enter)="toggleOption(column)">{{option.value}}</mat-option>
                  </mat-select>
                </mat-form-field>

                <!--Checking for Date-->
                <mat-form-field *ngIf="column.type == 'DATE'"  class="cvs-compact-form-field" appearance="outline"
                                (keydown.enter)="showDateRangePopover($event, column.key)" (click)="showDateRangePopover($event,column.key)" (keydown.space)="$event.stopPropagation()">
                  <input *ngIf="column.key == 'notificationDate'" matInput formControlName="notificationDate" [attr.aria-label]="column?.value" readonly #notificationDateField/>
                  <input *ngIf="column.key == 'createdDate'" matInput formControlName="createdDate" [attr.aria-label]="column?.value" readonly #createdDateField/>
                  <input *ngIf="column.key == 'auditScopeStartDate'" matInput formControlName="auditScopeStartDate" [attr.aria-label]="column?.value" readonly #auditScopeStartDateField/>
                  <input *ngIf="column.key == 'auditScopeEndDate'" matInput formControlName="auditScopeEndDate" [attr.aria-label]="column?.value" readonly #auditScopeEndDateField/>
                  <button matSuffix mat-icon-button aria-label="Open Date Picker"
                          (click)="showDateRangePopover($event, column.key)" (keydown.enter)="showDateRangePopover($event, column.key)">
                    <mat-icon svgIcon="calendar--s" class="icon-size"></mat-icon>
                  </button>
                </mat-form-field>

              </div>
            </ng-container>
          </div>
        </mat-header-cell>
        <!--Display row values-->
        <mat-cell *matCellDef='let audit; let index = index' [class.action-flex]="column?.key == 'action'" tabindex="0">
          <!--AMSAuditRecordId row value -->
          <ng-container>
            <!--Action column value icon-->
            <ng-container *ngIf="column?.key === 'action' && tabStatus === Constants.ACTIVE_TAB">
              <button class="pds-icon-only-btn" [matMenuTriggerFor]="actionsMenu" (menuOpened)="menuFocus(index)" (keydown.enter)="menuHandler(index)" [attr.aria-label]="'Expand Action Menu'">
                <mat-icon svgIcon='more-vert-f--s' class='dot-icon' aria-hidden='false' aria-label='Action column icon'></mat-icon>
              </button>
              <mat-menu #actionsMenu="matMenu" xPosition="after">
                <button mat-menu-item  class="table-action-button"
                        (click)="viewAuditRequest(audit.amsAuditRecordId)">
                  View Audit Request
                </button>
                <button mat-menu-item *ngIf="audit.isApproved || audit.auditStatus === 'Cancelled'"
                        (click)="manageDocuments(audit)"
                        class="table-action-button">
                  Document Management
                </button>
                <button mat-menu-item *ngIf="audit.isApproved || audit.auditStatus === 'Cancelled'"
                        (click)="openActualTimeline(audit)"
                        class="table-action-button">
                  Actual Timeline
                </button>
                <button mat-menu-item
                        (click)="viewStatusNotesDialog(audit.auditId)"
                        class="table-action-button">
                  View Status Notes
                </button>
              </mat-menu>
            </ng-container>
            <ng-container *ngIf="column?.key === 'action' && tabStatus === Constants.DRAFT_TAB">
              <button class="pds-icon-only-btn" [matMenuTriggerFor]="menu" (menuOpened)="menuFocus(index)" (keydown.enter)="menuHandler(index)" [attr.aria-label]="'Expand Action Menu'">
                <mat-icon svgIcon='more-vert-f--s' class='dot-icon' aria-hidden='false' aria-label='Action column icon'></mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item
                        (click)="editAuditRequest(audit.amsAuditRecordId)"
                        class="table-action-button">
                  Edit Audit Request
                </button>
                <button mat-menu-item
                        *ngIf="audit.isAuditor"
                        cvsDialog
                        [headline]="'Delete Confirmation'"
                        body="You have selected to permanently delete this audit request, do you wish to continue with the delete?"
                        actionLabel="Delete"
                        cancelLabel="Cancel"
                        class="table-action-button"
                        (onConfirmClick)="deleteAuditRequest(audit.auditId)">
                  Delete Audit Request
                </button>
                <button mat-menu-item
                        (click)="viewStatusNotesDialog(audit.auditId)"
                        class="table-action-button">
                  View Status Notes
                </button>
              </mat-menu>
            </ng-container>

            <!--Hyperlink text column value-->
            <ng-container *ngIf="column?.text==='Hyperlink'">
              <a *ngIf="tabStatus === Constants.ACTIVE_TAB" (click)="viewAuditRequest(audit?.amsAuditRecordId)" class='word pr-10' tabindex='0'>{{audit[column?.key]}}</a>
              <a *ngIf="tabStatus === Constants.DRAFT_TAB" (click)="editAuditRequest(audit?.amsAuditRecordId)" class='word pr-10' tabindex='0'>{{audit[column?.key]}}</a>
            </ng-container>
            <!--Normal text column value with date pipe-->
            <span *ngIf="column?.text == 'Normal'" class='pr-10' [attr.aria-label]="audit[column?.key]">{{audit[column?.key]}}</span>

            <!--Modal text column value-->
            <a *ngIf="column?.text == 'Modal'" (click)="openModalDialog(audit)" class='word pr-10' tabindex='0'>{{audit[column?.key]}}</a>

            <!--Status column value-->
            <ng-container *ngIf="column?.text == 'Status'">
              <ng-container *ngIf="audit?.notesExist">
                <button class="pds-icon-only-btn" (click)="viewStatusNotesDialog(audit?.auditId)"
                        (keydown.enter)="viewStatusNotesDialog(audit?.auditId)" [attr.aria-label]="'View ' + !audit?.isNotesViewed ? 'Unread' : '' + ' Audit Status Notes'">
                  <mat-icon svgIcon='file-check--s' class="envelope-icon" aria-hidden='false'></mat-icon>
                </button>
                <mat-icon *ngIf="!audit?.isNotesViewed" (click)="viewStatusNotesDialog(audit?.auditId)"
                          (keydown.enter)="viewStatusNotesDialog(audit?.auditId)" svgIcon='dot-indicator--s' class="unread-dot-icon unread-icon-alignment" aria-hidden='false'></mat-icon>
              </ng-container>
              <span [class]="(audit?.notesExist && !audit?.isNotesViewed) ? 'unread-status-text' : 'pr-10'"
                    [class.status-text]="!audit?.notesExist && showAuditStatusNotes"
                    [attr.aria-label]="getAuditStatus(audit[column?.key])">
                {{getAuditStatus(audit[column?.key])}}</span>
            </ng-container>
          </ng-container>
        </mat-cell>
      </ng-container>
      <!--Display error and spinner-->
      <ng-container matColumnDef="noResultsFound">
        <mat-footer-cell class="no-results" *matFooterCellDef [attr.colspan]="externalAuditDisplayedColumns.length">
          <!--Display error message if no results Found-->
          <ng-container *ngIf="dataSource.data.length == 0 && !loading">
            <div class="no-results-icon"><mat-icon svgIcon="error-f--s" aria-label="No results found"></mat-icon></div>
            <span><strong>No results found</strong></span>
          </ng-container>
          <!--Display spinner loader from ACL-->
          <ng-container *ngIf="loading">
            <pds-spinner
                    loadingHeader="Please Wait"
                    [loadingText]="'Loading Content...'" tabindex="0">
            </pds-spinner>
          </ng-container>
        </mat-footer-cell>
      </ng-container>
    </mat-table>
  </form>
  <!--Pagination from ACL-->
  <mat-paginator
          [ngClass]="{'hide':hidePaginator}"
          #filterTablePaginator="matPaginator"
          [length]="resultLength"
          [pageSizeOptions]="[10,20,30]"
          (page)="onPageChange($event)"
          aria-label="Select page">
  </mat-paginator>
</div>

<!--Date picker popover from ACL-->
<ng-template #popoverDateRange>
  <div class="popover-content">
    <cvs-date-range-form
            startDatePlaceholder="MM/DD/YYYY"
            endDatePlaceholder="MM/DD/YYYY"
            startDateLabel="Start Date"
            endDateLabel="End Date"
            [value]="defaultDateRange"
            (onStartDateChange)="applyStartDateChange($event)"
            (onEndDateChange)="applyEndDateChange($event)"
            [verticalInputs]="true"
            [appearance]="'outline'"
            [hintText]="''"
            class="cvs-compact-form-field">
    </cvs-date-range-form>
  </div>
</ng-template>

<!--Template for 'Assigned Member CVS' modal-->
<ng-template #contactModalTemplate>
  <h3 id="contact-modal-template" [cdkCopyToClipboard]="this.contactModalInfo.email">
    <span [ngStyle]="{'color': '#0b315e', 'text-decoration': 'underline'}" class="pointer-cursor pr-10">{{this.contactModalInfo.email}}</span>
    <span class="pointer-cursor">
      <mat-icon [cdkCopyToClipboard]="this.contactModalInfo.email" svgIcon="duplicate--s"></mat-icon>
    </span>
  </h3>
</ng-template>

