import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {
  AuditOfferingDetailModel,
  AuditTypeModel,
  CustomInformationModel,
  InformationDefinitionModel,
  TypesDefinitionModel
} from './audit-types.model';


@Injectable({
  providedIn: 'root'
})
export class AuditTypesService {

  constructor(private http: HttpClient) {

  }


  getCustomInformationListFromApi(auditId: number | null) {
    return this.http.get<CustomInformationModel[]>(environment.backendUrl+'/audit/custom/'+auditId);
  }

  getAuditTypeTemplate() {
    return this.http.get<AuditTypeModel[]>(environment.backendUrl+'/audit/types/claimAuditInformationType');
  }
  getAuditTypeDetail(auditId: number) {
    return this.http.get<AuditOfferingDetailModel[]>(environment.backendUrl+'/audit/types/claimAuditTypes/'+auditId);
  }

  getAuditTypeDefinitions() {
    return this.http.get<TypesDefinitionModel[]>(environment.backendUrl+'/audit/types/auditTypeDefinitions');
  }

  getAuditInformationDefinitions() {
    return this.http.get<InformationDefinitionModel[]>(environment.backendUrl+'/audit/types/auditInformationRequestDefinitions');
  }

  saveCustomInfoDetails(body: CustomInformationModel, addlInfoId?: number | null) {
    if(addlInfoId){
      return this.updateCustomInformation(body, addlInfoId);
    } else {
      return this.saveCustomInformation(body);
    }
  }

  saveCustomInformation(body: CustomInformationModel) {
    return this.http.post<CustomInformationModel>(environment.backendUrl+'/audit/custom/addInformation',body);
  }

  updateCustomInformation(body: CustomInformationModel, addlInfoId: number) {
    return this.http.put<CustomInformationModel>(environment.backendUrl+'/audit/custom/updateInformation/'+addlInfoId
      ,body);
  }

  deleteCustomInformation(addlInfoId: number, auditId: number) {
    return this.http.delete(environment.backendUrl+'/audit/custom/deleteInformation/'+addlInfoId+'/'+auditId,
      {responseType: 'text'});
  }

  saveAuditType(auditTypesRequestBody: any){
    return this.http.post(`${environment.backendUrl}/audit/types/addAuditTypes`,auditTypesRequestBody);
  }
}
