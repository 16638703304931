import { Pipe, type PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'formatDate',
  standalone: true,
})
export class FormatDate implements PipeTransform {

  transform(value: Date | moment.Moment | string, naOption: boolean = false, format: string = 'MM/DD/YYYY'): string {
    if (!value && naOption) {
      return 'N/A';
    }
    return value ? moment(value).format(format) : '-';
  }

}
