import {Component, Inject, OnInit,} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {NotesManagementService} from './notes-management.service';
import {NotesManagementModel, NotesViewObject, NotesViewRequest, ViewNotesModel} from './notes-management.model';
import {AuditStatusViewNotesEnum} from '../../../enums/audit-status-view-notes-enum';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BaseComponent} from '../base/base.component';
import {BannerService} from '../../services/banner.service';
import {InitialDeliverablesType, NotesCategory, NotesType} from './notes-management.constants';

@Component({
  selector: 'app-notes-management',
  templateUrl: './notes-management.component.html',
  styleUrls: ['./notes-management.component.scss']
})
export class NotesManagementComponent extends BaseComponent implements OnInit {

  notesViewData!: any[];
  auditId: any;
  notesType: string;
  detailViewNotesIds: number[] = [];
  isNotesViewed = false;
  timelineDetails: any;
  notesForm: FormGroup;
  notesData: NotesViewRequest[] = [];
  notesReceived = false;
  notesModalData!: NotesManagementModel;

  constructor(@Inject(MAT_DIALOG_DATA) data: NotesManagementModel,
              private dataService: NotesManagementService,
              public bs: BannerService) {
    super(bs);
    this.notesForm = new FormGroup({
      notes: new FormControl('', Validators.required),
      notesTag: new FormControl(null)
    });
    this.notesModalData = data;
    this.auditId = data?.auditId;
    this.notesType = data?.notesType;

  }

  ngOnInit(): void {
    if (this.notesType === NotesType.AUDIT_TIMELINE) {
      this.timelineDetails = this.notesModalData?.timelineDetails;
    }

    if (this.notesType === NotesType.DMS && this.notesModalData?.category === NotesCategory.INITIAL_DELIVERABLES) {
      this.notesForm.get('notesTag').setValidators(Validators.required);
      this.notesForm.updateValueAndValidity();
    }
    this.getAuditStatusNotesView();
  }

  getAuditStatusNotesView() {
    if (this.auditId) {
      const infoRequestId = this.getInitialDeliverableTag(InitialDeliverablesType.INFO_REQUEST);
      const detailAdditionalInfoReqId = this.getInitialDeliverableTag(InitialDeliverablesType.ADDITIONAL_INFO_REQUEST);
      const notesViewData: NotesViewObject = {
        auditId: this.auditId,
        notesType: this.notesType,
        keyAction: (this.notesType === NotesType.DMS ? 'DMS ' : '') + this.notesModalData?.category,
        timelineDetailId: this.timelineDetails?.timelineDetailId ?? null,
        infoRequestId,
        detailAdditionalInfoReqId
      };
      this.dataService.getNotesViewData(notesViewData).subscribe({
        next: (notesViewDataList: ViewNotesModel[]) => {
          if (notesViewDataList?.length) {
            this.notesViewData = notesViewDataList;
            this.updateNotesAsViewed(notesViewDataList);
          } else {
            this.notesViewData = [];
          }
          this.notesReceived = true;
        },
        error: () => {
          this.notesViewData = [];
        }
      });
    }
  }

  updateNotesAsViewed(notesViewDataList: ViewNotesModel[]) {
    if (this.notesModalData?.category === NotesCategory.AUDIT_STATUS_EXTERNAL) {
      this.detailViewNotesIds = notesViewDataList?.filter(notes => notes?.detailViewNotesId && !notes?.notesViewed)
        .map(map => map?.detailViewNotesId) ?? [];
      this.updateAuditStatusNotes(this.detailViewNotesIds);
    }
  }

  updateAuditStatusNotes(detailViewNotesIds: number[]) {
    if (detailViewNotesIds?.length) {
      this.dataService.updateAuditStatusNotes(detailViewNotesIds).subscribe((notes: any[]) => {
        this.isNotesViewed = !!notes?.length;
      });
    }
  }

  getStatusBoolean(val: any): boolean {
    return (val === AuditStatusViewNotesEnum.Return || val === AuditStatusViewNotesEnum.Cancel);
  }

  getNotesCreator(creatorEmail: string): string {
    return creatorEmail.includes('@cvshealth.com') ? 'CVS' : '';
  }

  getAuditStatus(val: any): string {
    const auditStatusMessage: {
      [key in AuditStatusViewNotesEnum]: string
    } = {
      [AuditStatusViewNotesEnum.Approve]: 'Audit Approved',
      [AuditStatusViewNotesEnum.Cancel]: 'Audit Cancelled',
      [AuditStatusViewNotesEnum.Return]: 'Audit Returned',
      [AuditStatusViewNotesEnum.Hold]: 'Audit On-Hold',
    };
    if (val in auditStatusMessage) {
      return auditStatusMessage[val as AuditStatusViewNotesEnum];
    }
  }

  get descriptionLength(): number {
    return Number(this.notesForm?.get('notes')?.value?.length) || 0;
  }

  addNotes() {
    const notesValue = this.notesForm.get('notes').value;
    if(this.notesForm.valid && notesValue?.trim()?.length) {
      this.notesData = [];
      if (this.notesType === NotesType.AUDIT_TIMELINE) {
        this.getTimelineNotesData(notesValue);
      } else if (this.notesType === NotesType.DMS) {
        this.getDmsNotesData(notesValue);
      }

      this.dataService.addNewNote(this.notesData).subscribe({
        next: (notes: any[]) => {
          this.showSuccessNotification(
            'Note successfully added',
            'Success!',
            '#bannerDiv',
            15000);
          this.notesForm.reset();
          if(this.notesType === NotesType.AUDIT_TIMELINE && notes?.length){
            this.timelineDetails.timelineDetailId = notes[0].detailTimelineDetailId;
          }
          this.getAuditStatusNotesView();
        }
      });
    } else {
      this.resetNotesForm();
    }
  }

  getTimelineNotesData(notesValue: string) {
    const notesDataForTimeline: NotesViewRequest = {
      auditId: this.auditId,
      notesType: this.notesType,
      keyAction: this.notesModalData?.category,
      timelineDetailId: this.timelineDetails?.timelineDetailId,
      notes: notesValue,
      timelineId: this.timelineDetails?.timelineId
    };
    this.notesData.push(notesDataForTimeline);
  }

  getDmsNotesData(notesValue: string) {
    let dmsNotesData: NotesViewRequest;
    if (this.notesModalData?.category === NotesCategory.INITIAL_DELIVERABLES) {
      this.notesForm?.get('notesTag')?.value?.forEach((option: any) => {
        const infoRequestId = option?.type === InitialDeliverablesType.INFO_REQUEST ? option?.id : null;
        const additionalInfoRequestId = option?.type === InitialDeliverablesType.ADDITIONAL_INFO_REQUEST ? option?.id : null;
        dmsNotesData = this.setDmsNotesData(notesValue, infoRequestId, additionalInfoRequestId);
        this.notesData.push(dmsNotesData);
      });
    } else {
      dmsNotesData = this.setDmsNotesData(notesValue);
      this.notesData.push(dmsNotesData);
    }
  }

  setDmsNotesData(notesValue: string, infoRequestId?: number, additionalInfoRequestId?: number) {
    return {
      auditId: this.auditId,
      notesType: this.notesType,
      keyAction: 'DMS ' + this.notesModalData?.category,
      notes: notesValue,
      infoRequestId: infoRequestId ?? null,
      detailAdditionalInfoReqId: additionalInfoRequestId ?? null,
      generalDocId: this.notesModalData?.generalDocId ?? null,
      detailAuditorGroupId: this.notesModalData?.detailAuditorGroupId ?? null
    } as NotesViewRequest;
  }

  resetNotesForm() {
    this.notesForm.get('notes').setValue(null);
    this.notesForm.get('notes').markAsTouched();
  }

  getInitialDeliverableTag(tagType: string) {
    return this.notesModalData?.tagDropdownList?.filter(
      tag => tag?.type === tagType).map( tag => tag?.id) ?? [];
  }

  tagAllSelection(event: any) {
    if (!event?.checked) {
      this.notesForm.controls['notesTag'].patchValue([]);
    } else {
      this.notesForm.controls['notesTag'].patchValue([
        ...this.notesModalData?.tagDropdownList
      ]);
    }
  }

  isIndeterminate(): boolean {
    return (
      this.notesForm?.controls['notesTag']?.value?.length > 0 &&
      this.notesForm?.controls['notesTag']?.value?.length <
      this.notesModalData?.tagDropdownList?.length
    );
  }

  protected readonly NotesType = NotesType;
  protected readonly NotesCategory = NotesCategory;
}


