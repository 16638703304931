import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-welcome-header',
  standalone: true,
  imports: [
    CommonModule,
  ],
  template: `
  <ng-container *ngIf="loggedInUser !== ''">
    Welcome, {{loggedInUser}}
  </ng-container>
  `,
  styleUrls: ['./welcome-header.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomeHeaderComponent {
  loggedInUser!: string;
  constructor(private userService: UserService) {
    this.loggedInUser = userService.getUserName();
  }
}
