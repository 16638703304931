import {AbstractControl, FormArray} from '@angular/forms';
import {AuditTypeModel} from '../../audit-request/audit-types/audit-types.model';

export class CustomValidator {

  static subcontractorLength(control: any, formArray1: any, formArray2: any, validatorField: { [key: string]: any }, optional = false) {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      const isControl = c.get(control)?.value;
      const arrayValue1 = c.get(formArray1)?.value;
      const arrayValue2 = c.get(formArray2)?.value;

      if(optional && (arrayValue1?.length === 0 || arrayValue2?.length === 0)) {
        return null;
      } else if(isControl === 'true' && (arrayValue1?.length === 0 && arrayValue2?.length === 0)) {
        return validatorField;
      }
      return null;
    };
  }

  static isPrimaryValidator(formArray: any, control: any, validatorField: { [key: string]: any }, optional = false) {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      const formArrControl = c.get(formArray)?.value;
      if (optional && formArrControl) {
        return null;
      } else if(formArrControl?.length > 0) {
        const isControl = formArrControl?.filter( (res: any) => control && res[control]);
        return isControl?.length === 1 ? null : validatorField;
      }
      return null;
    };
  }

  static auditOfferingValidator(auditTypes: AuditTypeModel[], validatorField: { [key: string]: any }, optional = false) {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      const offeringId = (c.get('offeringId') as FormArray)?.value;
      const infoRequestId = (c.get('infoRequestId') as FormArray)?.value;
      const customRequestId = (c.get('customRequestId') as FormArray)?.value;
      if(optional) {
        return null;
      } else if(offeringId?.length > 0) {
        let selectionCheck = true;
        offeringId?.forEach( (offer: any) => {
          const filterAuditType = auditTypes?.filter(
            (type: AuditTypeModel) => type?.offeringId === offer && infoRequestId.includes(type?.infoId));
          selectionCheck = filterAuditType?.length === 0 ? false : selectionCheck;
        });
        return !selectionCheck ? validatorField : null;
      }
      return null;
    };
  }

  static customOfferingValidatorWithNoSubContractors(
    auditTypes: AuditTypeModel[], validatorField: { [key: string]: any }, customSelectedCount) {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      const customRequestId = (c.get('customRequestId') as FormArray)?.value;
      return customSelectedCount !== customRequestId.length ? validatorField : null;
    };
  }

  static auditOfferingValidatorWithNoSubContractors(
    auditTypes: AuditTypeModel[], validatorField: { [key: string]: any }, claimAuditCount) {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      const offeringId = (c.get('offeringId') as FormArray)?.value;
      return claimAuditCount !== offeringId.length ? validatorField : null;
    };
  }

  static auditRoleValidator(selectedAuditType: AuditTypeModel[], validatorField: { [key: string]: any }, optional = false) {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      const primaryRole = (c.get('primaryAuditRole')?.get('offeringId') as FormArray)?.value;
      const subOneRole = (c.get('subcontractorOneAuditRole')?.get('offeringId') as FormArray)?.value;
      const subTwoRole = (c.get('subcontractorTwoAuditRole')?.get('offeringId') as FormArray)?.value;
      if(optional) {
        return null;
      } else if(selectedAuditType?.length) {
        const filterAuditType = selectedAuditType?.filter(
            (type: AuditTypeModel) => type?.offeringId && !(primaryRole.includes(type?.offeringId)
                || subOneRole.includes(type?.offeringId) || subTwoRole.includes(type?.offeringId)));
        return filterAuditType?.length > 0 ? validatorField : null;
      }
    };
  }

  static auditSubcontractorRole(validatorField: { [key: string]: any }, optional = false) {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      const divideAuditResponse = c.get('divideAuditResponsibility')?.value;
      const primaryOffering = (c.get('primaryAuditRole')?.get('offeringId') as FormArray)?.value;
      const primaryInfoReq = (c.get('primaryAuditRole')?.get('infoRequestId') as FormArray)?.value;
      const primaryCustomReq = (c.get('primaryAuditRole')?.get('customRequestId') as FormArray)?.value;
      if(optional) {
        return null;
      } else if(divideAuditResponse === 'false'
        && (primaryOffering?.length > 0 || primaryInfoReq?.length > 0 || primaryCustomReq?.length > 0)) {
        return validatorField;
      }
      return null;
    };
  }

  static AuditTypesValidator(formArray: any, validatorField: {[key: string]: any}, optional = false) {
    return(c: AbstractControl): {[key: string]: boolean} | null => {
      const formArrControl = (c.get(formArray) as FormArray)?.value ;
      const filteredValue = formArrControl.filter((f: any) => f);
      if(optional && formArrControl) {
        return null;
      } else if(filteredValue.length === 0 ) {
        return validatorField;
      }
      return null;
    };
  }

  static AuditInfoValidator(formControl: any, validatorField: {[key: string]: any}, optional = false){
    return (c: AbstractControl): {[key: string]: boolean} | null => {
      if(!c.get(formControl)?.value?.formattedSearchResult) {
        return validatorField;
      }
      return null;
    };
  }
}
