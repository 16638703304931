<div class="pds-table pds-table--tall">
  <div class="pds-table__header">
      <h1 style="font-size: 16px; font-weight: 400;">{{header}}</h1>
  </div>
  <div *ngIf="auditRequestMode !== 'read'"
       class="upload-file-row">
    <span>Select a file to begin.</span>
    <div>
      <button id="select-button"
              (click)="uploadFile()"
              class="pds-btn-primary button-search"
              [disabled]="auditRequestMode === 'read'"
              [attr.aria-label]="'Upload file for ' + header"
      >
        <mat-icon class="upload-button-icon" svgIcon="upload-arrow--s"></mat-icon>
        Upload
      </button>
      <input id="{{inputIdString}}"
             style="display: none"
             type="file" accept=".pdf, .doc, .xls, .docx, .xlsx, .txt, .csv, .zip, .pptx, .eml, .oft, .xlsb, .mdb, .accdb, .html, .tif"
             (change)="onFileSelected($event)">
    </div>
  </div>
  <mat-table [dataSource]="dataSource" matSort>
      <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
      <mat-row *matRowDef="let row; let i = index; columns: columns;"></mat-row>

      <!-- File Name Column -->
      <ng-container matColumnDef="fileName">
        <mat-header-cell
          *matHeaderCellDef
          [style.fontWeight]="'300'">
          File name
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <a (click)="downloadFile(element)" (keydown.enter)="downloadFile(element)" tabindex="0">{{element.fileName}}</a>
        </mat-cell>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <mat-header-cell
          *matHeaderCellDef
          [style.fontWeight]="'300'">
          Status
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.fileStatus}}</mat-cell>
      </ng-container>

      <!-- Upload date Column -->
      <ng-container matColumnDef="uploadDate">
          <mat-header-cell
              *matHeaderCellDef
              [style.fontWeight]="'300'">
              Upload date
          </mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.createdDatetime | appDateFormat}}</mat-cell>
      </ng-container>

      <!-- Uploaded by Column -->
      <ng-container matColumnDef="uploadedBy">
          <mat-header-cell
              *matHeaderCellDef
              [style.fontWeight]="'300'">
              Upload by
          </mat-header-cell>
          <mat-cell *matCellDef="let element" class="upload-by-text">{{element.createdBy}}</mat-cell>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
          <mat-header-cell
              *matHeaderCellDef
              [style.fontWeight]="'300'">
              Actions
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <ng-container *ngIf="(element.fileStatus !== 'File Not Selected' &&
             (userService.getUserEmail() === element.createdBy || userService.isAuditAdmin()))">
                <button id="delete-button"
                  (click)="deleteFile(element)"
                  class="pds-btn-text-only"
                  [disabled]="auditRequestMode === 'read'">
                  <mat-icon svgIcon="trash--s"></mat-icon>
                  Remove File
                </button>
              </ng-container>
          </mat-cell>
      </ng-container>
  </mat-table>
</div>
