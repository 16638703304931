import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, CanActivate, CanLoad, Route,
  Router, RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from '../services/user.service';
import {AuthenticationService} from 'angular-component-library';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {

  constructor(private router: Router,
              private userService: UserService,
              private authenticationService: AuthenticationService,) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this.userService.isExternalUser() && route?.data?.role === 'external-creator'){
      return true;
    } else {
      return this.userService.isInternalUser() && route?.data?.role === 'internal-creator'
        && !this.userService.isReadOnlyUser();
    }
  }
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isExternalModule = segments[0]?.path === 'external';
    const isInternalModule = segments[0]?.path === 'internal';
    if(this.userService.isExternalUser() && isExternalModule) {
      return true;
    } else if(this.userService.isInternalUser() && isInternalModule) {
      return true;
    } else if(!this.checkAuthorizedUser()){
      this.authenticationService.logout();
    } else {
      this.router.navigate(['/home']).then();
    }
    return false;
  }

  private checkAuthorizedUser(): boolean{
    return this.userService.isInternalUser() || this.userService.isExternalUser();
  }

}
