<div class="audit-contact-container pt-10" *ngIf="auditContactForm">
  <div id="audit-contact-alert-div"></div>
  <h2 class="pt-24">Audit Contacts</h2>
  <form [formGroup]="auditContactForm" (keydown.enter)="$event.preventDefault()">
    <div class="field-padding-top pageHint" *ngIf="(auditRequestMode === AUDIT_CONTACT.modes.request || auditRequestMode === AUDIT_CONTACT.modes.edit
    || auditRequestMode === AUDIT_CONTACT.modes.read) && auditRequestMode !== Constants.REVIEW">
      <label class="hint-text">{{AUDIT_CONTACT.hintMessage.pageHintLine}}</label>
    </div>
    <div class="field-padding-top pageHint"  *ngIf="auditRequestMode === Constants.REVIEW">
      <label class="hint-text">{{AUDIT_CONTACT.hintMessage.reviewPageHint}}</label>
    </div>
    <div class="field-padding-top">
      <h3>{{auditContactForm.get('firmId')!.value ? getFirmName(auditContactForm.get('firmId')!.value) : auditContactForm.get('clientName')!.value}}'s Contacts</h3>
      <div class="field-padding-top">
        <button class="pds-btn-ghost" [disabled]="auditRequestMode === AUDIT_CONTACT.modes.read || primaryContactForm!.value!.length === 5"
                (click)="addPrimaryContactForm(formRef)" (keydown.enter)="addPrimaryContactForm(formRef)">Add Contact</button>
      </div>
    </div>
    <div class="field-padding-top">
      <mat-form-field class="general-mail pb-20 pds-read-only-field"  [ngClass]="auditRequestMode === 'read' ? 'pds-read-only-field' : ''">
        <mat-label class="label-gray">General Email Mailbox (Optional)</mat-label>
        <input #mailbox matInput formControlName="generalMail" [readonly]="auditRequestMode === AUDIT_CONTACT.modes.read">
        <mat-error *ngIf="(auditContactForm.get('generalMail')?.touched) && auditContactForm.get('generalMail')?.invalid">
          <mat-icon svgIcon="error-f--xs" aria-hidden="false" class="custom-error-icon"></mat-icon>
          {{showFieldErrors(auditContactForm.get('generalMail'), AUDIT_CONTACT.formValidationMessages.generalMail)}}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="contact-view-flex-container">
      <div *ngFor="let contact of primaryContactForm!.controls; let i=index;" class="contact-view-div">
        <ams-core-contact-card-view [contactDetail]="contact?.value" [position]="i" [menuOption]="true"
                                    (emitContactDetail)="contactCardViewAction($event)"></ams-core-contact-card-view>
      </div>
    </div>
    <hr>
    <div class="field-padding-top">
      <h3>Audit Subcontractors Contacts</h3>
      <div class="field-padding-top">
        <label class="label-gray">Are you using an Audit Subcontractor? *</label>
        <mat-radio-group aria-label="select audit subcontractor" formControlName="hasAuditSubcontractor">
          <mat-radio-button value="true" tabindex="0" [disabled]="auditRequestMode === AUDIT_CONTACT.modes.read">Yes</mat-radio-button>
          <mat-radio-button value="false" tabindex="0" [disabled]="auditRequestMode === AUDIT_CONTACT.modes.read">No</mat-radio-button>
        </mat-radio-group>
      </div>
      <ng-container *ngIf="auditContactForm.get('hasAuditSubcontractor')?.value === 'true'">
        <div class="field-padding-top">
          <label>Please add your subcontracting firm's contacts that will be involved with this audit. One contact per
          subcontracting firm is required</label>
        </div>
        <div class="subcontractor-btn">
          <button class="pds-btn-ghost" [disabled]="auditRequestMode === AUDIT_CONTACT.modes.read || subcontractorOneForm!.value!.length === 5 && subcontractorTwoForm!.value!.length === 5"
                  (click)="addSubcontractorContactForm(formRef)" (keydown.enter)="addSubcontractorContactForm(formRef)">Add Subcontractor contact</button>
        </div>
      </ng-container>
    </div>
    <hr>

    <ng-container *ngIf="subcontractorOneForm!.value!.length > 0 && auditContactForm.get('hasAuditSubcontractor')?.value === 'true'">
      <div class="subcontractor-btn">
        <label>Audit Subcontracting Firm</label>
        <h3 class="mt-10">{{getFirmName(subcontractorOneForm!.value[0]!.firmId)}}</h3>
      </div>
      <div class="contact-view-flex-container">
        <div *ngFor="let subOneContact of subcontractorOneForm!.controls; let i = index;" class="contact-view-div">
          <ams-core-contact-card-view [contactDetail]="subOneContact?.value" [position]="" [menuOption]="true"
                                      (emitContactDetail)="contactCardViewAction($event)"></ams-core-contact-card-view>
        </div>
      </div>
      <hr>
    </ng-container>

    <ng-container *ngIf="subcontractorTwoForm!.value!.length > 0 && auditContactForm.get('hasAuditSubcontractor')?.value === 'true'">
      <div class="subcontractor-btn">
        <label>Audit Subcontracting Firm</label>
        <h3 class="mt-10">{{getFirmName(subcontractorTwoForm!.value[0]!.firmId)}}</h3>
      </div>
      <div class="contact-view-flex-container">
        <div *ngFor="let subOneContact of subcontractorTwoForm!.controls; let i = index;" class="contact-view-div">
          <ams-core-contact-card-view [contactDetail]="subOneContact?.value" [position]="i" [menuOption]="true"
                                      (emitContactDetail)="contactCardViewAction($event)"></ams-core-contact-card-view>
        </div>
      </div>
      <hr>
    </ng-container>

    <div class="btn-field" [hidden]="auditRequestMode === AUDIT_CONTACT.modes.read">
      <button type="button" class="pds-btn-ghost" (click)="cancelAuditContact()">Cancel</button>
      <button type="submit" [disabled]="primaryContactForm!.value!.length === 0 || (this.auditContactForm.get('hasAuditSubcontractor')!.value === 'true' && (subcontractorOneForm!.value!.length === 0 && subcontractorTwoForm!.value!.length === 0))"
              class="pds-btn-primary" (click)="addMainAuditContacts()" (keydown.enter)="addMainAuditContacts()">Save</button>
    </div>
  </form>

  <ng-template #formRef>
    <ams-core-contact-form [auditRequestMode]="auditContactMode" [contactForm]="contactForm" [firmList]="subcontractorFirmList" (contactActionDetail)="contactFormAction($event)"></ams-core-contact-form>
  </ng-template>

  <ng-template #deleteRef>
    <div class="model-container">
      <div class="contact-title">
        <h2>Delete Contact</h2>
        <button mat-icon-button class="content-align-right title-icon" (click)="closeContactDialogModel()" (keydown.enter)="closeContactDialogModel()"
                aria-label="Close Delete Contact dialog">
          <mat-icon svgIcon="close-btn--s" aria-hidden="false"></mat-icon>
        </button>
      </div>
      <div class="contact-content">
        <p *ngIf="auditRoleExist" class="pb-20">{{auditRoleExist}}</p>
        <p>Are you sure you want to delete this contact?</p>
      </div>
      <div class="content-align-right pt-15">
        <button class="pds-btn-ghost" (click)="closeContactDialogModel()" (keydown.enter)="closeContactDialogModel()">Cancel</button>
        <button class="pds-btn-primary" (click)="deleteAuditContact()" (keydown.enter)="deleteAuditContact()">Yes</button>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #customBannerContactTemplate>
  <cvs-banner [data]="bannerData">
    <h2>{{AUDIT_CONTACT.errorMessage.errorTitle}}</h2>

    <p class="pt-15">{{AUDIT_CONTACT.errorMessage.multiError}}</p>
    <ul>
      <li>{{AUDIT_CONTACT.formValidationMessages.primaryAuditContact.primaryAuditor}}</li>
      <li>{{AUDIT_CONTACT.formValidationMessages.hasAuditSubcontractor.required}}</li>
    </ul>
  </cvs-banner>
</ng-template>

