import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output, SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {AuditRequest} from '../audit-request.model';
import {AuditTypesService} from './audit-types.service';
import {
  AuditOfferingDetailModel,
  AuditTypeModel,
  CustomInformationModel,
  InformationDefinitionModel,
  TypesDefinitionModel
} from './audit-types.model';
import * as _ from 'underscore';
import * as AUDIT_TYPES from '../audit-types/audit-types.contants';
import {BaseComponent} from '../../shared/components/base/base.component';
import {BannerService} from '../../shared/services/banner.service';
import {Constants} from '../../constants/constants';

@Component({
  selector: 'app-audit-types',
  templateUrl: './audit-types.component.html',
  styleUrls: ['./audit-types.component.scss']
})
export class AuditTypesComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() auditTypeForm!: FormGroup;
  @Input() auditDetails!: AuditRequest;
  @Input() auditRequestMode!: any;
  @Input() auditRoleChange!: any;
  @Input() showBanner!: boolean;
  @Output() savedAuditTypeForm = new EventEmitter<any>();
  @Output() completedAuditTypeForm = new EventEmitter<number>();
  @Output() isAuditTypePricing = new EventEmitter<boolean>();
  @Output() typeWarning = new EventEmitter<boolean>();

  auditId!: number | null;
  mode!: string | null;
  showError = false;
  remainingCharacters = 300;
  customInformationGroup!: FormGroup;
  isReadOnly = false;
  auditOffering: AuditTypeModel[] = [];
  auditTypes: AuditTypeModel[] = [];
  selectedInfoRequest: AuditTypeModel[] = [];
  isInfoType: AuditTypeModel[] = [];
  informationTypes: AuditTypeModel[] = [];
  auditOfferingDetail: AuditOfferingDetailModel[] = [];
  isRoleExist = false;
  isCustomInfoDeleteExist = false;

  auditTypesDefinitions: TypesDefinitionModel[] = [];
  auditInformationDefinitions: InformationDefinitionModel[] = [];

  @ViewChild('formRef') dialogTemplate!: TemplateRef<any>;
  @ViewChild('viewRef') viewDialogTemplate!: TemplateRef<any>;
  @ViewChild('deleteRef') deleteDialogTemplate!: TemplateRef<any>;
  @ViewChild('typeDefRef') typeDialogTemplate!: TemplateRef<any>;
  @ViewChild('infoDefRef') infoDialogTemplate!: TemplateRef<any>;
  @ViewChild('confirmationRef', { read: TemplateRef }) confirmationTemplate!: TemplateRef<any>;


  constructor(private matDialog: MatDialog,
              private fb: FormBuilder,
              private service: AuditTypesService,
              public bs: BannerService) {
    super(bs);
  }

  ngOnInit(): void {
    this.getCustomInfoForm();
    this.getAuditTypesDefinition();
    this.getAuditInformationDefinition();

    if(this.auditRequestMode !== AUDIT_TYPES.modes.read) {
      this.auditTypeForm?.valueChanges.subscribe(() => {
        // Using pristine because touched does not work
        if(!this.auditTypeForm?.pristine) {
          this.typeWarning.emit(true);
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.showBanner?.currentValue !== true){
      this.auditId = this.auditDetails?.auditId;
      this.mode = this.auditDetails?.mode;
      if(this.auditId && this.auditRoleChange === undefined) {
        this.getAuditTypeTemplate();
        this.getCustomInfoList();
      }
      if(this.auditRoleChange) {
        this.getAuditTypeDetail();
      }
    }
  }

  /* custom info form set up */
  getCustomInfoForm() {
    this.customInformationGroup = this.fb.group({
      addlInfoId: new FormControl(),
      addlInfoDataTitle: new FormControl<string | null>(null, [Validators.required,Validators.maxLength(50)]),
      addlInfoDataNotes: new FormControl<string | null>(null, [Validators.required]),
      createdDateTime: new FormControl<string | null>(null),
      createdById: new FormControl<string | null>(null),
      modifiedById: new FormControl<string | null>(null),
      modifiedDatetime: new FormControl<string | null>(null),
    });
  }

  /* get audit offering form array */
  get auditOfferingForm() {
    return (this.auditTypeForm.get('auditOffering') as FormArray);
  }

  getAuditTypeTemplate() {
    this.service.getAuditTypeTemplate().subscribe({
      next: (types: AuditTypeModel[]) => {
        this.auditTypes = types;
        this.auditOffering = _.uniq(types, x => x.offeringName);
        this.informationTypes = _.uniq(this.auditTypes, x => x.infoName);
        this.createAuditTypeForm();
        this.getAuditTypeDetail();
      }
    });
  }

  getAuditTypeDetail() {
    this.service.getAuditTypeDetail(this.auditId).subscribe({
      next: (types: AuditOfferingDetailModel[]) => {
        this.auditOfferingDetail = types;
        if (!this.auditRoleChange) {
          types.forEach(t => {
            const index = this.auditOffering.findIndex(ao => ao.offeringId === t.offeringId);
            if (index !== -1 && this.auditOfferingForm?.length >= index + 1) {
              const event = {
                target: {
                  checked: true
                }
              };
              this.auditOfferingForm.get(index?.toString()).setValue(true);
              this.auditTypeValidation(index, event);
            }
          });
          const auditOfferingTypes = this.filterAuditOfferingType;
          if (auditOfferingTypes?.length) {
            this.completedAuditTypeForm.emit(1);
            this.setPricingForTimeline(auditOfferingTypes);
            this.savedAuditTypeForm.emit({
              auditTypes: this.auditTypes,
              auditTypeDetail: types
            });
            this.typeWarning.emit(false);
          }
        } else {
          this.auditRoleChange = false;
        }
        if(this.auditRequestMode === AUDIT_TYPES.modes.read){
          this.auditOfferingForm.disable();
        }
      }
    });

  }

  createAuditTypeForm() {
    this.auditOfferingForm.clear();
    this.auditOffering.forEach(() => {
      const formControl = new FormControl({
        value: false,
        disabled: false,

      });
      this.auditOfferingForm.push(formControl);
    });
  }

  onAuditTypeValueChanged(data?: any): void {
    const selectedOffering: number[] = this.getSelectedOffering(data);
    this.setSelectedInfoRequest(selectedOffering);
  }

  getSelectedOffering(data?: any) {
    const selectedOffering: (number | null)[] = [];
    _.forEach(data, (form, key) => {
      if(form?.value) {
        selectedOffering.push(this.auditOffering[key]?.offeringId);
      }
    });
    return selectedOffering;
  }


  auditTypeValidation(index: any, event: any) {
    const erisaIndex = this.auditOffering.findIndex(data => data.offeringId === AUDIT_TYPES.offeringsId.erisa5500);
    const accumulatorIndex = this.auditOffering.findIndex(data => data.offeringId === AUDIT_TYPES.offeringsId.accumulator);
    const preImpIndex = this.auditOffering.findIndex(data => data.offeringId === AUDIT_TYPES.offeringsId.preImplementation);
    const postImpIndex = this.auditOffering.findIndex(data => data.offeringId === AUDIT_TYPES.offeringsId.postImplementation);

    if(erisaIndex !== -1 &&  index === erisaIndex){
      this.erisaCheck(erisaIndex, event);
    } else if(accumulatorIndex !== -1 && index === accumulatorIndex){
     this.accumulatorCheck(accumulatorIndex, preImpIndex, postImpIndex, event);
    } else if(preImpIndex !== -1 && accumulatorIndex !== -1 && index === preImpIndex) {
        this.preImplCheck(accumulatorIndex, preImpIndex, postImpIndex, event);
    } else if(postImpIndex !== -1 && index === postImpIndex) {
        this.postImplCheck(accumulatorIndex, preImpIndex, postImpIndex, event);
    } else {
        this.otherOfferingsCheck(erisaIndex, accumulatorIndex, preImpIndex, postImpIndex, index, event);
    }
    this.onAuditTypeValueChanged(this.auditOfferingForm?.controls);
  }

  accumulatorCheck(accumulatorIndex: number, preImpIndex: number, postImpIndex: number, event: any) {
    const preImpValue = preImpIndex !== -1 && this.auditOfferingForm.at(preImpIndex)
      ? this.auditOfferingForm.at(preImpIndex)?.value : false;
    const postImpValue = postImpIndex !== -1 && this.auditOfferingForm.at(postImpIndex)
      ? this.auditOfferingForm.at(postImpIndex)?.value : false;
    this.auditOfferingForm?.controls?.map((form, index) => {
      const enableCheck = this.accumulatorEnableCheck(event.target.checked, index, preImpIndex, postImpIndex, preImpValue, postImpValue);
      const disableCheck = this.accumulatorDisableCheck(event.target.checked, index, preImpIndex, postImpIndex, preImpValue, postImpValue);
      if(index !== accumulatorIndex && index!== preImpIndex && index !== postImpIndex && event.target.checked === true){
        form.setValue(false);
        form.disable();
      } else if(disableCheck)  {
        form.disable();
    } else if(enableCheck) {
        form.enable();
      }
    });
  }

  accumulatorEnableCheck(checked: boolean, index: number, preImpIndex: number, postImpIndex: number,
                         preImpValue: boolean, postImpValue: boolean) {
      return (checked === false && !(preImpValue || postImpValue))
        || (checked === true && (index === postImpIndex || index === preImpIndex));
  }

  accumulatorDisableCheck(checked: boolean, index: number, preImpIndex: number, postImpIndex: number,
                          preImpValue: boolean, postImpValue: boolean) {
    return (checked === false && ((preImpValue && index === postImpIndex) || (postImpValue && index === preImpIndex)))
      || (checked === true && ((preImpValue && index === postImpIndex) || (postImpValue && index === preImpIndex)));
  }

  preImplCheck(accumulatorIndex: number, preImpIndex: number, postImpIndex: number, event: any){
    const accumulatorValue = accumulatorIndex!== -1 ? this.auditOfferingForm.at(accumulatorIndex).value : false;

    this.auditOfferingForm?.controls?.map((form, index) => {
      if( index!== preImpIndex && index !== accumulatorIndex && event.target.checked === true){
        form.setValue(false);
        form.disable();
      } else if(accumulatorValue && accumulatorIndex !== index && preImpIndex !== index
        && postImpIndex !== index && event.target.checked === false) {
        form.disable();
      } else {
        form.enable();
      }
    });
  }

  postImplCheck(accumulatorIndex: number, preImpIndex: number, postImpIndex: number, event: any) {
    const accumulatorValue = accumulatorIndex!== -1 ? this.auditOfferingForm.at(accumulatorIndex).value : false;

    this.auditOfferingForm?.controls?.map((form, index) => {
      if( index!== postImpIndex && index !== accumulatorIndex && event.target.checked === true){
        form.setValue(false);
        form.disable();
      }else if(accumulatorValue && accumulatorIndex !== index && preImpIndex !== index
        && postImpIndex !== index && event.target.checked === false) {
        form.disable();
      } else {
        form.enable();
      }
    });
  }

  erisaCheck(erisaIndex: number, event: any) {
    this.auditOfferingForm?.controls?.map((form, index) => {
      if(index !== erisaIndex && event.target.checked === true){
        form.setValue(false);
        form.disable();
      } else {
        form.enable();
      }
    });
  }

  otherOfferingsCheck(erisaIndex: number, accumulatorIndex: number, preImpIndex: number, postImpIndex: number, index: number, event: any) {
    const checkedValue =  this.auditOfferingForm?.controls?.filter((form , i) => form?.value && i !== index);
    if((checkedValue.length > 0 || event.target.checked  === true) &&
      erisaIndex !== -1 && accumulatorIndex !== -1 && preImpIndex !== -1  && postImpIndex !== -1) {
      this.auditOfferingForm.at(erisaIndex).setValue(false);
      this.auditOfferingForm.at(erisaIndex).disable();
      this.auditOfferingForm.at(accumulatorIndex).setValue(false);
      this.auditOfferingForm.at(accumulatorIndex).disable();
      this.auditOfferingForm.at(preImpIndex).setValue(false);
      this.auditOfferingForm.at(preImpIndex).disable();
      this.auditOfferingForm.at(postImpIndex).setValue(false);
      this.auditOfferingForm.at(postImpIndex).disable();
    } else {
      this.auditOfferingForm.at(erisaIndex).enable();
      this.auditOfferingForm.at(accumulatorIndex).enable();
      this.auditOfferingForm.at(preImpIndex).enable();
      this.auditOfferingForm.at(postImpIndex).enable();
    }
  }



  setSelectedInfoRequest(selectedOffering: any) {
    const selectedInfoRequest = _.filter(this.auditTypes, function(p){
      return _.includes(selectedOffering, p.offeringId);
    });
    this.selectedInfoRequest = _.uniq(selectedInfoRequest, x => x.infoName);
  }

  getAuditTypesDefinition() {
    this.service.getAuditTypeDefinitions().subscribe({
      next: (data: TypesDefinitionModel[]) => {
        this.auditTypesDefinitions = data;
    }
    });
  }

  getAuditInformationDefinition() {
    this.service.getAuditInformationDefinitions().subscribe({
      next: (data: InformationDefinitionModel[]) => {
        this.auditInformationDefinitions = _.uniq(data, x => x.infoRequestName);
      }
    });
  }

  openDialog() {
    this.isReadOnly = false;
    this.customInformationGroup.reset();
    this.remainingCharacters = 300;
    this.onInputChange();
    this.matDialog.open(this.dialogTemplate, {
      disableClose: true,
      panelClass: 'customInfoDialog'
    });
  }

  openModelDialog(templateRef?: any) {
    this.matDialog.open(templateRef, {
      disableClose: true,
      panelClass: 'audit-small-model'
    });
  }

  closeDialog() {
    this.matDialog.closeAll();
  }

  onInputChange() {
    if (this.customInformationGroup?.get('addlInfoDataNotes')?.value) {
      const remaining = 300 - this.customInformationGroup?.get('addlInfoDataNotes')?.value.length;
      this.remainingCharacters = remaining >= 0 ? remaining : 0;
    }
  }

  saveCustomInformation() {
    this.showError = true;
    const customInfo = this.customInformationGroup.getRawValue();
    customInfo.addlInfoDataTitle = customInfo.addlInfoDataTitle ? customInfo.addlInfoDataTitle.trim() : '';
    customInfo.addlInfoDataNotes = customInfo.addlInfoDataNotes ? customInfo.addlInfoDataNotes.trim() : '';
    this.validateTitle(customInfo.addlInfoDataTitle);
    if(this.isInfoType.length > 0){
      this.customInformationGroup.get('addlInfoDataTitle')?.setErrors({unique: true});
      return;
    }
    if (this.customInformationGroup.valid) {
      const customInfoDetails: CustomInformationModel = {
        ...customInfo,
        auditId: this.auditId,
        mode: customInfo.addlInfoId ? 'edit' : this.mode,
      };
      this.customInfoApiCall(customInfoDetails);
    }

  }

  validateTitle(title: string){
    if(title) {
      this.isInfoType = _.filter(this.informationTypes, function(p) {
        return _.isEqual(title.toLowerCase(), p.infoName?.toLowerCase());
      });
    }
  }

  deleteCustomInformation(infoId: number) {
    this.service.deleteCustomInformation(infoId, this.auditDetails?.auditId).subscribe({
      next:() => {
        this.customInformationArray.clear();
        this.getCustomInfoList();
      }
    });
    this.closeDialog();
  }

  getCustomInfoList() {
    this.isCustomInfoDeleteExist = this.auditDetails?.status === 'Open' || this.auditDetails?.status === 'Staging';

    this.service.getCustomInformationListFromApi(this.auditId).subscribe({
      next: (data: CustomInformationModel[]) => {
        if(data.length) {
          this.customInformationArray.clear();
          data.forEach((value) => {
              this.customInformationArray.push(this.fb.group({value}));
          });
        }
      }
    });
  }

  customInfoApiCall(customInfoDetails: CustomInformationModel) {
    this.service.saveCustomInfoDetails(customInfoDetails, customInfoDetails?.addlInfoId).subscribe({
      next: (data: CustomInformationModel) => {
        if (data?.addlInfoId) {
          this.customInformationArray.clear();
          this.customInformationGroup.reset();
          this.getCustomInfoList();
          this.closeDialog();
        } else {
          this.customInformationGroup.get('addlInfoDataTitle')?.setErrors({unique: true});
        }
      }
    });
  }

  get customInformationArray() {
    return this.auditTypeForm.get('customInformation') as FormArray;
  }

  editDialog(index: number) {
    this.isReadOnly = true;
    this.customInformationGroup.patchValue(this.customInformationArray.controls[index].value.value);
    this.onInputChange();
    this.matDialog.open(this.dialogTemplate, {
      disableClose: true,
      panelClass: 'customInfoDialog'
    });
  }

  viewDialog(index: number){
  this.customInformationGroup.patchValue(this.customInformationArray.controls[index].value.value);
    this.matDialog.open(this.viewDialogTemplate, {
      disableClose: true,
      panelClass: 'viewInfoDialog'
    });
  }

  openMenuDialog(){
    this.isCustomInfoDeleteExist = this.auditDetails?.status === 'Open' || this.auditDetails?.status === 'Staging';
  }

  deleteDialog(index: number){
    this.customInformationGroup.patchValue(this.customInformationArray.controls[index].value.value);
    this.matDialog.open(this.deleteDialogTemplate, {
      disableClose: true,
      panelClass: 'deleteInfoDialog'
    });
  }

  typeDefDialog() {
    this.matDialog.open(this.typeDialogTemplate, {
      disableClose: true,
      panelClass: 'typeDialog'
    });
  }

  InfoDefDialog() {
    this.matDialog.open(this.infoDialogTemplate, {
      disableClose: true,
      panelClass: 'infoDialog'
    });
  }

  confirmationModelForSave() {
    const auditOfferingTypes = this.filterAuditOfferingType;
    const checkRoleExist = this.auditOfferingDetail?.filter(
      (type: AuditOfferingDetailModel) => type.detailAuditorGroupId);
    const checkNewRole = auditOfferingTypes?.filter(
      (type: number) => {
        const exist = this.auditOfferingDetail?.filter( offer => offer.offeringId === type);
        return !exist?.length;
      });
    this.isRoleExist = checkRoleExist?.length !== 0 && checkNewRole?.length !== 0;

    const removedAuditRoleList = this.auditOfferingDetail.filter(
      (offering: AuditOfferingDetailModel) => !auditOfferingTypes.includes(offering.offeringId) && offering.detailAuditorGroupId);

    if(removedAuditRoleList?.length) {
      this.openModelDialog(this.confirmationTemplate);
    } else {
      this.saveAuditType();
    }
  }

  saveAuditType() {
    const auditOfferingTypes = this.filterAuditOfferingType;
    const auditTypeRequest ={
      auditId: this.auditId,
      offeringId:auditOfferingTypes,
      mode:this.mode
    };
    if(this.auditId && auditOfferingTypes?.length > 0) {
      this.saveAuditOfferings(auditTypeRequest, this.isRoleExist);
      this.setPricingForTimeline(auditOfferingTypes);
    } else {
      this.showErrorNotification(
        AUDIT_TYPES.errorMessage.auditType,
        AUDIT_TYPES.errorMessage.title,
        AUDIT_TYPES.banner.alertDiv,
        20000);
    }
  }

  private setPricingForTimeline(auditOfferingTypes: number[]) {
    const pricingID =  this.auditOffering.find(t => t.offeringName === 'Pricing');

    if (auditOfferingTypes.find(val => val === pricingID?.offeringId)) {
      this.isAuditTypePricing.emit(false);
    } else {
      this.isAuditTypePricing.emit(true);
    }
  }

  get filterAuditOfferingType() {
    return this.auditOfferingForm?.controls?.map(
      (checked: any,index: number)=> checked?.value && this.auditOffering[index] ? this.auditOffering[index].offeringId : null)
      .filter((value: any)  => value!=null);
  }

  cancelAuditType() {
    this.closeDialog();
    this.bs.close();
    this.resetAuditOffering();
    this.getAuditTypeDetail();
    this.isRoleExist = false;
  }

  resetAuditOffering() {
    this.auditOfferingForm?.controls?.map((form: any) => {
      form.setValue(false);
      form.enable();
    });
  }

  saveAuditOfferings(auditTypeRequest: any, roleExist: boolean){
    this.service.saveAuditType(auditTypeRequest).subscribe({
      next: (auditType: AuditOfferingDetailModel[])=> {
        if(auditType) {
          const saveSummary = !roleExist ? AUDIT_TYPES.successMessage.save : AUDIT_TYPES.successMessage.saveWithRoleExist;
          this.closeDialog();
          this.showSuccessNotification(
            saveSummary,
            AUDIT_TYPES.successMessage.saveTitle,
            AUDIT_TYPES.banner.alertDiv,
            15000);
          this.savedAuditTypeForm.emit({
            auditTypes: this.auditTypes,
            auditTypeDetail: auditType
          });
          this.typeWarning.emit(false);
          this.auditTypeForm.markAsPristine();
          this.auditOfferingDetail = auditType;
          this.isRoleExist = false;
        }
      },
      error: () => {
        this.closeDialog();
        this.isRoleExist = false;
        this.showErrorNotification(
          AUDIT_TYPES.errorMessage.save,
          AUDIT_TYPES.errorMessage.title,
          AUDIT_TYPES.banner.alertDiv,
          20000);
      }
    });
  }

  protected readonly AUDIT_TYPES = AUDIT_TYPES;
  protected readonly FormGroup = FormGroup;
  protected readonly Constants = Constants;

}
