import {Injectable} from '@angular/core';
import {BannerLink, CVSBannerComponentData, CVSBannerService} from 'angular-component-library';

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  data!: CVSBannerComponentData;
  hideX = false;
  isHorizontalBannerLinks = false;
  absolutePosition = false;

  constructor(private cvsBannerService: CVSBannerService) { }

  sendCustomAlert(data: CVSBannerComponentData) {
    this.cvsBannerService.sendAlert(data);
  }

  sendAlertToCvsNotification(config: CVSBannerComponentData) {
    this.data = {
      bannerType: config?.bannerType, // required
      hideX: config?.hideX ? config?.hideX : this.hideX,
      isHorizontalBannerLinks: config?.isHorizontalBannerLinks ? config?.isHorizontalBannerLinks : this.isHorizontalBannerLinks,
      absolutePosition: config?.absolutePosition ? config?.absolutePosition : this.absolutePosition,
      closeCallBack: this.close,
      headline: config?.headline ? config?.headline : 'This is custom headline',
      body: config?.body ? config?.body : 'This is custom body',
      bodyDetails: config?.bodyDetails ? config?.bodyDetails : null,
      outletId: config?.outletId ? config?.outletId : '#cvsNotification', // Triggers Banner to appear at specified selector
      bannerLinks: config?.bannerLinks ? config?.bannerLinks : this.createLinkCollection(),
      removedAfterMilliseconds: config?.removedAfterMilliseconds ? config?.removedAfterMilliseconds : 0
    };
    this.cvsBannerService.sendAlert(this.data);
  }

  close = () => {
    // provide product specific cleanup code and then call BannerService close method
    this.cvsBannerService.close();
  };

  createLinkCollection(): BannerLink[] {
    return [
      {
        linkText: 'callback-X',
        linkFunc: () => { return; }
      }, {
        linkText: 'callback-Y',
        linkFunc: () => { return; }
      },
      {
        linkText: 'callback-Z',
        linkFunc: () => { return; }
      }];
  }
}
