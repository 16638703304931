import { Injectable } from '@angular/core';
import {AuditRole, AuditRoleDetailModel} from './audit-role.model';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuditRoleService {

  backendUrl = environment.backendUrl + '/audit/role/';

  constructor(private http: HttpClient) { }

  saveAuditRole(mode: string, auditRole: AuditRole) {
    if(mode === 'add') {
      return this.addAuditRole(auditRole);
    } else if(mode === 'update') {
      return this.updateAuditRole(auditRole);
    }
  }

  addAuditRole(auditRole: AuditRole) {
    return this.http.post<AuditRole>(this.backendUrl + 'addAuditRole', auditRole);
  }

  updateAuditRole(auditRole: AuditRole) {
    return this.http.put<AuditRole>(this.backendUrl + 'updateAuditRole', auditRole);
  }

  getAuditRoleDetail(auditId: number) {
    return this.http.get<AuditRoleDetailModel>(this.backendUrl + 'getAuditRoleResponsibility/' + auditId);
  }

  saveAuditRoleResponsibility(auditId: number, auditRoleResponsibility: AuditRoleDetailModel) {
    return this.http.put<AuditRoleDetailModel>(this.backendUrl + 'updateAuditRoleResponsibility/' + auditId, auditRoleResponsibility);
  }

  getAuditRole(auditId: number, contactType: string) {
    return this.http.get<AuditRole>(this.backendUrl + 'getAuditRole/' + auditId + '?contactType=' + contactType);
  }
}
