<div class="start-up-process-container pt-10">
    <div id="start-up-process-alert-div">
    </div>
    <h2 class="pt-24">Complete Start Up Process</h2>
    <div class="pt-24 pageHint">
        <div class="hint-text">{{START_UP_PROCESS.hintMessage.pageHintLine}}</div>
    </div>
    <form [formGroup]="firmsVerificationNDAForm" (keydown.enter)="$event.preventDefault()">

        <div id="buttons-audit-actions">
            <button class="pds-btn-ghost" [disabled]="disableForCancelAuditor"
                    (click)="auditReviewModalOpen(formRef, 'CANCEL')">Cancel Audit</button>
            <button class="pds-btn-ghost" [disabled]="isClientAuditCase || !!auditDetail?.clientAuditRecordId || disableForReturnAuditor"
                    (click)="auditReviewModalOpen(formRef, 'RETURN')">Return to Auditor</button>
        </div>

        <div *ngIf="auditDetail && uploadedStartUpFiles$ | async as startUpFiles">
            <app-upload-docs
                    [header]="'Start Up Process*'"
                    [files]="startUpFiles"
                    [auditId]="auditDetail.auditId"
                    (uploadedFileGroup)="filesAddedOrRemoved()"
                    [outletId]="'#start-up-process-alert-div'">
            </app-upload-docs>
        </div>

        <div *ngIf="uploadedStartUpFilesLength" class="field-padding-top">
            <div class="sub-header">
                <h2>NDA Verification</h2>
            </div>

            <ng-container *ngFor="let groupType of auditorGroupTypeMap | keyvalue">
                <div class="field-padding-top flex-container">
                    <div class="audit-type-label" [ngSwitch]="groupType.value[0].auditorGroupTypeDesc">
                        <label *ngSwitchCase="'Client Auditor'" class="label-gray">Audit Client</label>
                        <label *ngSwitchCase="'Firm Auditor'" class="label-gray">Audit Firm</label>
                        <label *ngSwitchDefault class="label-gray">Audit Subcontracting Firm</label>
                        <h3>{{groupType.value[0].firmName}}</h3>
                    </div>
                    <div>
                        <label class="label-gray">NDA Verification</label>
                        <mat-radio-group role="radiogroup" [formControlName]='groupType.value[0].auditorGroupTypeDesc'
                                         [attr.aria-label]="'Select NDA Verification for ' + groupType.value[0].firmName">
                            <mat-radio-button role="radio" value="1" tabindex="0">Global NDA verified</mat-radio-button>
                            <mat-radio-button role="radio" value="2" tabindex="0">Single use NDA needed</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div>
                        <ng-container *ngIf="firmsVerificationNDAForm?.get(groupType.value[0].auditorGroupTypeDesc)?.value == '2'">
                            <mat-checkbox role="checkbox" [formControlName]='groupType.value[0].auditorGroupTypeDesc + " checkbox"'
                                          class="checkbox-row" [aria-label]="groupType.value[0].firmName + ' checkbox'">Yes, I have a fully executed single use NDA
                              <button class="pds-icon-only-btn" (click)="ndaInfoDefDialog()"
                                      aria-label="More information on Fully Executed Single Use NDA">
                                <mat-icon svgIcon="info-circle-f--xs" class="info-icon"></mat-icon>
                              </button>
                            </mat-checkbox>
                        </ng-container>
                    </div>
                </div>
            </ng-container>

            <div class="btn-field">
              <button type="button" class="pds-btn-ghost" (click)="cancelStartUpProcess()">Cancel</button>
                <button (click)="saveNdaVerification()"
                        [disabled]="!firmsVerificationNDAForm.dirty"
                        class="pds-btn-primary">Save
                </button>
            </div>

        </div>
    </form>
</div>

<ng-template #formRef>
  <ams-core-audit-review-modal
    [auditReviewForm]="auditReviewForm"
    [recordId]="auditDetail.recordId"
    [reviewType]="reviewType"
    [viewerEmailList]="viewerEmailList"
    (auditReviewActionDetail)="auditReviewAction($event)"></ams-core-audit-review-modal>
</ng-template>

<ng-template #ndaInfoTemplateRef>
  <div class="template-title">
    <h2>Fully Executed Single Use NDA</h2>
    <button class="pds-icon-only-btn center-close-x" mat-icon-button mat-dialog-close
            aria-label="Close Fully Executed Single Use NDA dialog">
      <mat-icon svgIcon="close-btn--s"></mat-icon>
    </button>
  </div>
  <div class="nda-info-modal-body">
    <div class="part-a">
      Check “Yes, I have a fully executed single use NDA”, ONLY if you have obtained and verified a signed NDA for the
      identified firm. You can skip this checkbox now until you have received the fully executed NDA. Select Save, and
      proceed to the Projected Timelines step in your Audit Review.
    </div>
    <div>
      Once you have a fully executed signed NDA for this firm, you can come back to this step in the Audit Review and
      select “Yes, I have a fully executed single use NDA” after audit approval. Checking this box will allow auditors
      access to the documents in document management, therefore it is VERY IMPORTANT you come back to check this box
      once you have obtained and verified the single use NDA for this firm.   
    </div>
  </div>
</ng-template>
