<div class="document-management">
  <div id="audit-documents-alert-div"></div>
  <button (keydown.enter)="auditInfoService.backClicked()" (click)="auditInfoService.backClicked()" class="previous-page" tabindex="0">
    <mat-icon svgIcon="caret-left--s" aria-hidden="false"></mat-icon>
    <span class="previous-page-name">Dashboard</span>
  </button>
  <section class="document-management__request">
    <h1 class="header-spacing">Document Management</h1>
    <div class="document-management-info">
      <div>
        <strong>{{clientCoalitionLabel}}:</strong> {{auditDetail?.auditInformation?.originalClientName}}
      </div>
      <div>
        <strong>Record ID:</strong> {{auditDetail?.recordId}}
      </div>
    </div>
    <div class="document-management-info pt-10 bottom-spacing" *ngIf="clientOrAccountName">
      <div>
        <strong>CVS {{clientCoalitionLabel}}:</strong> {{clientOrAccountName}}
      </div>
    </div>
  </section>
  <div class="document-management__large-file-text">If you are uploading a large file, please be patient as it could take 3-4 minutes to complete the upload!</div>
  <section class="document-management__NDA" *ngIf="ndaMetadata.length">
    <div class="document-management__result-listings">
      <app-document-management-table *ngFor="let result of ndaMetadata;"
                                     [amRecordId]="recordId"
                                     [auditId]="auditId"
                                     [detailAuditorGroupId]="result.detailAuditorGroupId"
                                     [header]="result.header"
                                     [files]="result.fileGroup"
                                     [claimAuditInfoSection]="false"
                                     [firmContactDropdownList]="firmContactDropdownList"
                                     [fileSharesList]="fileSharesList"
                                     [outletId]="'#audit-documents-alert-div'"
                                     [ndaVerificationMap]="ndaVerificationMap"
                                     [sectionNotesExists]="result.sectionNotesExists"
                                     (getFileSharesView)="fetchFileShareView()"
                                     (addViewNotes)="addViewNotesModal($event, result)">
      </app-document-management-table>
    </div>
  </section>
  <section class="document-management__results" *ngIf="claimAuditMetadata?.length">
    <div class="section-content">
      <h2 class="h2 document-management__h2">Initial Deliverables</h2>
      <div class="mt-18 ml-10">
        <span >
          <button class="pds-btn-text-only" (click)="addViewNotesModal(null, claimAuditMetadata)">
            <mat-icon [svgIcon]="claimDataNotesExists ? 'file-check--s' : 'pencil--s'" class="envelope-icon" aria-hidden='false'></mat-icon>
            {{claimDataNotesExists ? "View/" : ""}}Add Notes
          </button>
        </span>
      </div>
    </div>
    <div class="document-management__result-listings">
      <app-document-management-table *ngFor="let result of claimAuditMetadata;"
                                     [amRecordId]="recordId"
                                     [auditId]="auditId"
                                     [infoRequestId]="result.infoRequestId"
                                     [detailAddlInfoRequestId]="result.detailAddlInfoRequestId"
                                     [header]="result.header"
                                     [files]="result.fileGroup"
                                     [claimAuditInfoSection]="true"
                                     [outletId]="'#audit-documents-alert-div'">
      </app-document-management-table>
    </div>
  </section>
  <section class="document-management__samples" *ngIf="samplesAndReportingMetadata.length">
    <div class="document-management__result-listings">
      <app-document-management-table *ngFor="let result of samplesAndReportingMetadata;"
                                     [amRecordId]="recordId"
                                     [auditId]="auditId"
                                     [generalDocId]="result.generalDocId"
                                     [header]="result.header"
                                     [files]="result.fileGroup"
                                     [claimAuditInfoSection]="false"
                                     [firmContactDropdownList]="firmContactDropdownList"
                                     [fileSharesList]="fileSharesList"
                                     [outletId]="'#audit-documents-alert-div'"
                                     [sectionNotesExists]="result.sectionNotesExists"
                                     (getFileSharesView)="fetchFileShareView()"
                                     (addViewNotes)="addViewNotesModal($event, result)">
      </app-document-management-table>

    </div>
  </section>
</div>
