<div class="pds-table pds-table--tall" [class.thick-border]="checkThickBorder(header)">
  <div class="pds-table__header section-content">
    <h1 style="font-size: 16px; font-weight: 400;"
        class="bold-header">{{header}}</h1>
    <div class="notes-icon ml-10" *ngIf="!this.claimAuditInfoSection">
        <span>
          <button class="pds-btn-text-only" (click)="callAddViewNotes()">
            <mat-icon [svgIcon]="sectionNotesExists ? 'file-check--s' : 'pencil--s'" class="envelope-icon" aria-hidden='false'></mat-icon>
            {{sectionNotesExists ? "View/" : ""}}Add Notes
          </button>
        </span>
    </div>
  </div>
  <div *ngIf="auditRequestMode !== 'read'"
       class="upload-file-row upload-file-row-wide-gap">
    <span>Select a file to begin.</span>
    <div>
      <button id="select-button"
              (click)="uploadFile()"
              class="pds-btn-primary button-search"
              [disabled]="auditRequestMode === 'read'"
              [attr.aria-label]="'Upload file for ' + header"
      >
        <mat-icon class="upload-button-icon" svgIcon="upload-arrow--s"></mat-icon>
        Upload
      </button>
      <input id="{{inputIdString}}"
             style="display: none"
             type="file" accept=".pdf, .doc, .xls, .docx, .xlsx, .txt, .csv, .zip, .pptx, .eml, .oft, .xlsb, .mdb, .accdb, .html, .tif"
             (change)="onFileSelected($event)">
    </div>
  </div>
  <mat-table [dataSource]="dataSource" matSort>
    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row *matRowDef="let row; let i = index; columns: columns;"></mat-row>

    <!-- File Name Column -->
    <ng-container matColumnDef="fileName">
      <mat-header-cell
        *matHeaderCellDef
        [style.fontWeight]="'300'">
        File name
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <a (click)="downloadFile(element)" (keydown.enter)="downloadFile(element)" tabindex="0">{{element.fileName}}</a>
      </mat-cell>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <mat-header-cell
        *matHeaderCellDef
        [style.fontWeight]="'300'">
        Status
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.fileStatus}}</mat-cell>
    </ng-container>

    <!-- Share With Column -->
    <ng-container matColumnDef="shareWith">
      <mat-header-cell
        *matHeaderCellDef
        [style.fontWeight]="'300'">
        Share With
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element">
        <div
          tabindex="0"
          [cvsTooltip]="setTooltipText(element.detailFileId)"
          [showArrow]="true"
          [placement]="'bottom'"
          class="share-with-cell"
          #shareWithCell>
          <mat-icon svgIcon="user--s"></mat-icon>
          {{setMemberAmount(element.detailFileId)}}
        </div>
      </mat-cell>
    </ng-container>

    <!-- Upload date Column -->
    <ng-container matColumnDef="uploadDate">
      <mat-header-cell
        *matHeaderCellDef
        [style.fontWeight]="'300'">
        Upload date
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.createdDatetime | appDateFormat}}</mat-cell>
    </ng-container>

    <!-- Uploaded by Column -->
    <ng-container matColumnDef="uploadedBy">
      <mat-header-cell
        *matHeaderCellDef
        [style.fontWeight]="'300'">
        Upload by
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.createdBy}}</mat-cell>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="action">
      <mat-header-cell
        *matHeaderCellDef
        [style.fontWeight]="'300'">
        Actions
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <ng-container *ngIf="(element.fileStatus !== 'File Not Selected' &&
             (userService.getUserEmail() === element.createdBy || userService.isAuditAdmin() || userService.isAuditAnalyst()))">
          <div *ngIf="!claimAuditInfoSection && (userService.isAuditAdmin() || userService.isAuditAnalyst())">
            <button type="button" class="pds-btn-text-only"
                    (click)="openShareFileModal(shareWithTemplateRef, element)">
              <mat-icon svgIcon="user-add--s"></mat-icon>
              Share With
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="element.fileStatus !== 'File Not Selected' &&
          (userService.getUserEmail() === element.createdBy || userService.isAuditAdmin() || userService.isAuditAnalyst())">
          <button mat-icon-button [matMenuTriggerFor]="more" [cvsTooltip]="'More'" [showArrow]="true" [smallTooltip]="true" [placement]="'top'" >
            <mat-icon svgIcon="more-horz-f--s" aria-hidden="false"></mat-icon>

            <mat-menu #more="matMenu">
              <button mat-menu-item class="table-action-button"
                      *ngIf="userService.isAuditAnalyst() && isShowApproveFile">Approve</button>
              <button mat-menu-item id="delete-menu-button" class="table-action-button"
                      (click)="deleteFile(element)">Delete</button>
              <button mat-menu-item class="table-action-button"
                      *ngIf="userService.isAuditAnalyst() && header === 'Non-disclosure Agreement'">Fully Executed</button>
            </mat-menu>
          </button>
        </ng-container>
      </mat-cell>
    </ng-container>
  </mat-table>
</div>

<ng-template #shareWithTemplateRef>
  <div [ngClass]="showLoader ? 'loader' : 'share-with-modal'">
    <div *ngIf="showLoader">
      <pds-spinner [loadingText]="'Loading Data...'" [mode]="'indeterminate'"></pds-spinner>
    </div>
    <div *ngIf="!showLoader">
      <div class="share-with-title">
        <h2>Share File</h2>
        <button class="content-align-right" mat-icon-button mat-dialog-close (click)="cancelAction()"
                [aria-label]="'Close Share File dialog'">
          <mat-icon svgIcon="close-btn--s" aria-hidden="false"></mat-icon>
        </button>
      </div>
      <div class="share-with-content">
        <span class="share-with-text">Please select from the list of contacts for this audit to share this file with.</span>
        <form [formGroup]="shareWithForm">
          <mat-form-field appearance="outline" class="share-with-dropdown cvs-compact-form-field">
            <mat-select [formControl]="selectedOptions"
                        (selectionChange)="changedSelection($event)"
                        multiple>
              <mat-option *ngFor="let contact of firmContactDropdownList; let i = index;"
                          [disabled]="contact.value.contactEmail === createdBy ||
                          (contact.firm === true && disableFirmNameSelector(i)) ||
                          checkNdaVerification(contact)"
                          [value]="i"
                          [ngClass]="contact.firm ? '' : 'indent-selection'">
                {{contact.firm ? contact.value : contact.value.firstName + ' ' + contact.value.lastName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
      <div class="share-with-buttons">
        <button class="pds-btn-white" type="button" (click)="cancelAction()">Cancel</button>
        <button class="pds-btn-primary" type="button" [disabled]="selectedOptions.value.length === 0" (click)="shareFile()">Share</button>
      </div>
    </div>
  </div>
</ng-template>

