import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AuditRequestRoutingModule} from './audit-request-routing.module';
import {MatRadioModule} from '@angular/material/radio';
import {
    CVSAutocompleteSelectModule,
    CVSBannerModule,
    PDSAutocompleteMultiSelectModule, PDSSpinnerModule
} from 'angular-component-library';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuditInformationComponent} from './audit-information/audit-information.component';
import {AuditContactComponent} from './audit-contact/audit-contact.component';
import {CoreModule} from '@core/core';

import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { NotesComponent } from './time-line/notes/notes.component';
import { UploadDocumentsComponent } from './upload-documents/upload-documents.component';

import {MatMenuModule} from '@angular/material/menu';
import {MatDialogModule} from '@angular/material/dialog';
import {SharedModule} from '../shared/shared.module';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {AppEnvResolverService} from '../app.env.resolver';
import {AuditTypesComponent} from './audit-types/audit-types.component';
import {FormatDate} from './time-line/format-date.pipe';
import { ActualTimeLineComponent } from './time-line/actual-time-line/actual-time-line.component';
import { AuditRoleComponent } from './audit-role/audit-role.component';
import { StartUpProcessComponent } from './start-up-process/start-up-process.component';
import { RequestTimeLineComponent } from './time-line/req-time-line/request-time-line.component';
import { ReviewTimeLineComponent } from './time-line/review-time-line/review-time-line.component';


@NgModule({
  declarations: [
    AuditInformationComponent,
    UploadDocumentsComponent,
    AuditContactComponent,
    AuditTypesComponent,
    NotesComponent,
    AuditRoleComponent,
    ActualTimeLineComponent,
    RequestTimeLineComponent,
    ReviewTimeLineComponent,
    StartUpProcessComponent,
    ],
    imports: [
        CommonModule,
        AuditRequestRoutingModule,
        MatRadioModule,
        CVSAutocompleteSelectModule,
        PDSAutocompleteMultiSelectModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatIconModule,
        MatDatepickerModule,
        MatNativeDateModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        SharedModule,
        CoreModule,
        MatMenuModule,
        MatButtonModule,
        MatCardModule,
        CVSBannerModule,
        MatTableModule,
        MatDialogModule,
        FormsModule,
        FormatDate,
        PDSSpinnerModule
    ],
  providers: [AppEnvResolverService],
  exports: [
    AuditInformationComponent,
    UploadDocumentsComponent,
    AuditContactComponent,
    AuditTypesComponent,
    AuditRoleComponent,
    StartUpProcessComponent,
    RequestTimeLineComponent,
    ReviewTimeLineComponent
  ]
})
export class AuditRequestModule { }
