import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {BannerService} from '../../shared/services/banner.service';
import {AuditRequest} from '../audit-request.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import * as START_UP_PROCESS from '../start-up-process/start-up-process.constants';
import {DocumentService} from '../../services/document.service';
import {forkJoin, Observable} from 'rxjs';
import {StartUpSaveRequest} from '../../models/start-up-file-save-request.model';
import {TimeLineService} from '../time-line/time-line.service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {Constants} from '../../constants/constants';
import {AuditContactDetailModel} from '../audit-contact/audit-contact.model';
import {AuditRequestUtil} from '../../utilities/audit-request.util';


@Component({
  selector: 'app-start-up-process',
  templateUrl: './start-up-process.component.html',
  styleUrls: ['./start-up-process.component.scss']
})
export class StartUpProcessComponent extends BaseComponent implements OnChanges, OnInit {

  @Input() auditDetail!: AuditRequest;
  @Input() startUpProcessForm!: FormGroup;
  @Input() showBanner!: boolean;
  @Input() auditRequestMode!: any;
  @Input() isClientAuditCase!: boolean;
  @Input() auditContactDetail!: AuditContactDetailModel;
  @Output() inProgressStartUpProcessForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() completedStartUpProcessForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() processWarning = new EventEmitter<boolean>();
  @ViewChild('ndaInfoTemplateRef') ndaInfoTemplateRef!: TemplateRef<any>;

  uploadedStartUpFilesLength: number;
  uploadedStartUpFiles$!: Observable<any>;
  ndaVerificationList$!: Observable<any>;
  auditorGroupTypeMap = new Map();
  initialNdaValues: any;
  auditReviewForm!: FormGroup;
  reviewType = '';
  savedNdaResponse: any;
  disableForCancelAuditor: boolean;
  disableForReturnAuditor: boolean;
  viewerEmailList: string[] = [];

  get firmsVerificationNDAForm() {
    return (this.startUpProcessForm.get('firmsVerificationNDA') as FormGroup);
  }

  constructor(
    private documentService: DocumentService,
    public bs: BannerService,
    private fb: FormBuilder,
    private timeLineService: TimeLineService,
    private router: Router,
    private matDialog: MatDialog) {
    super(bs);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.auditDetail?.currentValue) {
      this.uploadedStartUpFiles$ = this.documentService.getStartUpFiles(this.auditDetail.auditId);
      this.ndaVerificationList$ = this.documentService.getNdaVerificationList(this.auditDetail.auditId);
      this.disableForCancelAuditor = AuditRequestUtil.getStatusOfAuditForCancelAuditor(this.auditDetail.status);
      this.disableForReturnAuditor = AuditRequestUtil.getStatusOfAuditForReturnAuditor(this.auditDetail.status);
      this.setupFormData();
    }
  }

  checkFormValidity() {
    this.startUpProcessForm.updateValueAndValidity();
    if (this.startUpProcessForm.valid) {
      this.completedStartUpProcessForm.emit(5);
      this.processWarning.emit(false);
    }
  }

  setupFormData(){
    forkJoin([this.uploadedStartUpFiles$, this.ndaVerificationList$]).subscribe(results => {
      this.uploadedStartUpFilesLength = results[0].length;
      this.initialNdaValues = results[1];

      this.createFormGroup(this.initialNdaValues);
      this.checkFormValidity();
    });
  }

  filesAddedOrRemoved() {
    this.uploadedStartUpFiles$.subscribe(files => {
      this.uploadedStartUpFilesLength = files.length;
      if (this.uploadedStartUpFilesLength > 0) {
        this.firmsVerificationNDAForm.get('isFileUploaded').setValue(true);
        this.firmsVerificationNDAForm.updateValueAndValidity();
        this.startUpProcessForm.updateValueAndValidity();
      } else {
        this.firmsVerificationNDAForm.get('isFileUploaded').setValue(null);
        this.firmsVerificationNDAForm.updateValueAndValidity();
        this.startUpProcessForm.updateValueAndValidity();
        this.inProgressStartUpProcessForm.emit();
      }
      this.checkFormValidity();
    });
  }

  createFormGroup(ndaVerificationViewList: any) {
    this.firmsVerificationNDAForm.reset();

    ndaVerificationViewList.forEach((listItem: any) => {
      if (!this.auditorGroupTypeMap.has(listItem.auditorGroupTypeDesc)) {
        this.auditorGroupTypeMap.set(listItem.auditorGroupTypeDesc, [listItem]);
      } else {
        this.auditorGroupTypeMap.get(listItem.auditorGroupTypeDesc).push(listItem);
      }
    });

    this.auditorGroupTypeMap.forEach(auditorGroupType => {
      const controlName = auditorGroupType[0].auditorGroupTypeDesc;

      this.firmsVerificationNDAForm.addControl(controlName, new FormControl({value: null, disabled: false}, [Validators.required]));
      this.firmsVerificationNDAForm.addControl(controlName + ' checkbox', new FormControl(null));

      if (auditorGroupType[0].selectedNdaOptionId === 1) {
        this.firmsVerificationNDAForm.get(controlName).setValue('1');
        this.firmsVerificationNDAForm.get(controlName + ' checkbox').setValue(true);
      }

      if (auditorGroupType[0].selectedNdaOptionId === 2 && auditorGroupType[0].ndaVerified) {
        this.firmsVerificationNDAForm.get(controlName).setValue('2');
        this.firmsVerificationNDAForm.get(controlName + ' checkbox').setValue(true);
      }

      if (auditorGroupType[0].selectedNdaOptionId === 2 && !auditorGroupType[0].ndaVerified) {
        this.firmsVerificationNDAForm.get(controlName).setValue('2');
        this.firmsVerificationNDAForm.get(controlName + ' checkbox').setValue(false);
      }
    });
  }

  saveNdaVerification() {
    if (this.firmsVerificationNDAForm.valid) {
      this.documentService.saveNdaOptions(this.auditDetail.auditId, this.buildSaveRequestData()).subscribe({
        next: (res) => {
          this.showSuccessNotification(
              'Start Up Process was successfully saved!',
              'Successful Save',
              '#start-up-process-alert-div',
              15000);
          this.firmsVerificationNDAForm.markAsPristine();
          this.completedStartUpProcessForm.emit(5);
          this.processWarning.emit(false);
          this.savedNdaResponse = res;
        },
        error: () => {
          this.showErrorNotification(
              'You have failed to Save Start Up Process.',
              'Unsuccessful save',
              '#start-up-process-alert-div',
              20000);
        }
      });
    } else {
      const errorDetail = 'You must select an appropriate NDA option for each firm in order to proceed to the next step! ';
      this.showErrorNotification(
          errorDetail,
          'Error',
          '#start-up-process-alert-div',
          20000);
    }
  }

  buildSaveRequestData() {
    const saveArray: StartUpSaveRequest[] = [];

    Object.keys(this.firmsVerificationNDAForm.controls).forEach(key => {
      if (this.auditorGroupTypeMap.has(key)) {

        const saveObj: StartUpSaveRequest = {
          auditorGroupTypeId: this.auditorGroupTypeMap.get(key)[0].auditorGroupTypeId,
          ndaOptionId: this.firmsVerificationNDAForm.get(key).value,
          ndaVerified: false
        };

        if (saveObj.ndaOptionId === '1') {
          saveObj.ndaVerified = true;
        } else if (saveObj.ndaOptionId === '2') {
          if (this.firmsVerificationNDAForm.get(key + ' checkbox').value === true) {
            saveObj.ndaVerified = true;
          }
        }
        saveArray.push(saveObj);
      }
    });
    return saveArray;
  }

  ngOnInit(): void {
    this.auditReviewForm = this.fb.group({
      auditReviewNotes: [{ value: '', disabled: false }, [Validators.required, Validators.maxLength(500)]],
    });

    this.startUpProcessForm.valueChanges.subscribe( ()=> {
      if(this.firmsVerificationNDAForm.dirty){
        this.processWarning.emit(true);
      }
    });
  }

  auditReviewAction(event) {
    this.matDialog.closeAll();
    if(event?.mode !== 'cancel') {
      this.router.navigate(['/internal']).then();
    }
  }

  auditReviewModalOpen(templateRef: TemplateRef<any>, reviewType: string) {
    this.reviewType = reviewType;
    this.viewerEmailList = AuditRequestUtil.getNotesViewerEmailList(this.auditDetail, this.auditContactDetail);
    this.matDialog.open(templateRef, {
      disableClose: true
    });
  }

  ndaInfoDefDialog() {
    this.matDialog.open(this.ndaInfoTemplateRef, {
      disableClose: true,
      panelClass: 'ndaInfoDialog'
    });
  }

  /**
   * Handles the Cancel button functionality for the Start Up Process form.
   * Sets the Start Up Process form back to what was in the savedNdaResponse or
   * initialNdaValues variable (based on if the user saved the form),
   * then checks the validity of the form.
   */
  cancelStartUpProcess() {
    this.bs.close();
    if(this.savedNdaResponse === undefined) {
      this.createFormGroup(this.initialNdaValues);
      this.filesAddedOrRemoved();
    } else {
      this.setNdaFormValuesToSavedValues();
    }
    if(this.firmsVerificationNDAForm.valid) {
        this.firmsVerificationNDAForm.markAsPristine();
        this.completedStartUpProcessForm.emit(5);
        this.processWarning.emit(false);
      }
  }

  /**
   * Takes the savedNdaResponse object from the documentService.saveNdaOptions() call
   * and sets the firmsVerificationNDAForm to what those values were.
   */
  setNdaFormValuesToSavedValues() {
    this.savedNdaResponse.forEach((ndaVerification, i) => {
      switch(i) {
        case 0:
          this.firmsVerificationNDAForm.get('Firm Auditor').setValue(ndaVerification.ndaOptionId);
          this.firmsVerificationNDAForm.get('Firm Auditor checkbox').setValue(ndaVerification.ndaVerified);
          break;
        case 1:
          this.firmsVerificationNDAForm.get('Subcontractor 1').setValue(ndaVerification.ndaOptionId);
          this.firmsVerificationNDAForm.get('Subcontractor 1 checkbox').setValue(ndaVerification.ndaVerified);
          break;
        case 2:
          this.firmsVerificationNDAForm.get('Subcontractor 2').setValue(ndaVerification.ndaOptionId);
          this.firmsVerificationNDAForm.get('Subcontractor 2 checkbox').setValue(ndaVerification.ndaVerified);
          break;
        default:
          break;
      }
    });
  }

  protected readonly START_UP_PROCESS = START_UP_PROCESS;

}
