import {
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges, OnInit,
  Output, SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuditContactDetailModel, AuditContactModel} from './audit-contact.model';
import {AuditRequest} from '../audit-request.model';
import {auditInitiationType} from '../audit-information/audit-information.constants';
import {MatDialog} from '@angular/material/dialog';
import {AuditContactService} from './audit-contact.service';
import {FirmModel} from '../../models/firm.model';
import {BannerService} from '../../shared/services/banner.service';
import {BaseComponent} from '../../shared/components/base/base.component';
import * as AUDIT_CONTACT from './audit-contact.constants';
import {CVSBannerComponentData, CVSBannerType} from 'angular-component-library';
import {AuditRoleService} from '../audit-role/audit-role.service';
import {AuditRole} from '../audit-role/audit-role.model';
import {Constants} from '../../constants/constants';

@Component({
  selector: 'app-audit-contact',
  templateUrl: './audit-contact.component.html',
  styleUrls: ['./audit-contact.component.scss']
})
export class AuditContactComponent extends BaseComponent implements OnChanges, OnInit {

  @Input() auditRequestMode!: string;
  @Input() auditDetail!: AuditRequest;
  @Input() auditContactForm!: FormGroup;
  @Input() firmList: FirmModel[] = [];
  @Input() showBanner!: boolean;
  @Output() savedAuditContactForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() completedAuditContactForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() inProgressAuditContactForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() contactWarning = new EventEmitter<boolean>();

  @ViewChild('formRef') formTemplateRef!: TemplateRef<any>;
  @ViewChild('deleteRef', { read: TemplateRef }) deleteTemplateRef!: TemplateRef<any>;
  @ViewChild('customBannerContactTemplate') customBannerContactTemplate: TemplateRef<any>;
  @ViewChild('mailbox') mailbox!: ElementRef;
  bannerData!: CVSBannerComponentData;
  initiationType = '';
  contactForm!: FormGroup;
  auditId!: number | null;
  subcontractorFirmList: FirmModel[] = [];
  auditDetailContactId!: number | null;
  contactAddAction = false;
  auditRoleExist = '';
  auditContactMode = this.auditRequestMode;
  savedContactDetail: any;

  get primaryContactForm() {
    return (this.auditContactForm.get('primaryAuditContact') as FormArray);
  }
  get subcontractorOneForm() {
    return (this.auditContactForm.get('auditSubcontractorOne') as FormArray);
  }
  get subcontractorTwoForm() {
    return (this.auditContactForm.get('auditSubcontractorTwo') as FormArray);
  }

  constructor(public formBuilder: FormBuilder, private matDialog: MatDialog,
              public bs: BannerService, public viewContainerRef: ViewContainerRef,
              private auditContactService: AuditContactService,
              private auditRoleService: AuditRoleService) {
    super(bs);
  }

  ngOnInit() {
    this.auditContactForm?.valueChanges.subscribe(() => {
      // Using pristine because touched does not work
      if(!this.auditContactForm?.pristine) {
        this.contactWarning.emit(true);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.auditDetail) {
      this.auditId = this.auditDetail?.auditId;
      this.getAuditContactDetail();
      this.filterSubcontractorFirmList();
    }
  }

  /** Get Audit contact form with and without values**/
  getContactForm(data?: AuditContactModel) {
    return this.formBuilder.group({
      detailContactId: [this.getNullValueCheck(data?.detailContactId)],
      auditId: [(data?.auditId ? data.auditId : this.auditDetail?.auditId), [Validators.required]],
      contactId: [this.getNullValueCheck(data?.contactId)],
      contactType: [this.getNullValueCheck(data?.contactType), [Validators.required]],
      isPrimaryContact: [this.getNullValueCheck(data?.isPrimaryContact)],
      title: [this.getNullValueCheck(data?.title), [Validators.required, Validators.maxLength(50)]],
      firstName: [this.getNullValueCheck(data?.firstName), [Validators.required, Validators.maxLength(50)]],
      lastName: [this.getNullValueCheck(data?.lastName), [Validators.required, Validators.maxLength(50)]],
      contactEmail: [this.getNullValueCheck(data?.contactEmail), [
        Validators.required, Validators.email, Validators.maxLength(100)]],
      phoneNumber: [this.getNullValueCheck(data?.phoneNumber), [
        Validators.required, Validators.pattern('[- +()0-9]+'), Validators.maxLength(15)]],
      notes: [this.getNullValueCheck(data?.notes), [Validators.maxLength(300)]],
      mode: this.auditRequestMode,
      firmId: [this.getNullValueCheck(data?.firmId)],
      clientId: [this.getNullValueCheck(data?.clientId)],
      contactSource: [this.getNullValueCheck(data?.contactSource)]
    });
  }
  getNullValueCheck(data: any) {
    return data ?? null;
  }

  openAuditContactModel(templateRef: TemplateRef<any>, modelType?: string) {
   this.matDialog.open(templateRef, {
      disableClose: true,
      panelClass: modelType === AUDIT_CONTACT.contactMode.delete ? 'audit-small-model' : 'audit-contact-custom-model'
    });
  }

  closeContactDialogModel() {
    this.auditContactMode = this.auditRequestMode;
    this.matDialog.closeAll();
  }
  /** Get Firm details from user and firm list from api**/
  setFirmDetail(contactDetail?: AuditContactDetailModel) {
    this.initiationType = sessionStorage.getItem('auditInitiationType') ?? '';
    let firmId: any = sessionStorage.getItem('firmId');
    firmId = firmId ? Number(firmId) : null;
    const clientId = sessionStorage.getItem('clientId') ?? null;
    const clientName = sessionStorage.getItem('clientName') ?? null;

    if(contactDetail?.firmId || contactDetail?.clientId) {
      this.auditContactForm.get('firmId')?.setValue(contactDetail?.firmId ? contactDetail.firmId : null);
      this.auditContactForm.get('clientId')?.setValue(contactDetail?.clientId ? contactDetail.clientId : null);
      this.auditContactForm.get('clientName')?.setValue(contactDetail?.clientName ? contactDetail.clientName : null);
    } else {
      this.auditContactForm.get('firmId')?.setValue(firmId);
      this.auditContactForm.get('clientId')?.setValue(clientId);
      this.auditContactForm.get('clientName')?.setValue(clientName);
    }
  }

  filterSubcontractorFirmList(firmId?: any) {
    this.subcontractorFirmList = this.firmList;
    if(this.auditContactForm.get('firmId')?.value) {
      this.subcontractorFirmList = this.firmList.filter( fList => fList.firmId !== this.auditContactForm.get('firmId')?.value);
    }
    /* Filter already selected subcontractor firm*/
    const subOneList = this.subcontractorOneForm?.getRawValue();
    const subTwoList = this.subcontractorTwoForm?.getRawValue();
    const subOneLength = subOneList?.length;
    const subTwoLength = subTwoList?.length;

    if(subOneLength === 5 && subTwoLength === 5 && firmId) {
      this.subcontractorFirmList = this.subcontractorFirmList.filter(fList => fList.firmId === firmId );
    } else if(this.filterByBothFirm(subOneList, subTwoList, firmId)) {
      this.subcontractorFirmList = this.subcontractorFirmList.filter(
        fList => fList.firmId === subOneList[0].firmId || fList.firmId === subTwoList[0].firmId);
    } else if(subOneLength === 5 && subTwoLength === 0 && !firmId) {
      this.subcontractorFirmList = this.subcontractorFirmList.filter(fList => fList.firmId !== subOneList[0].firmId );
    } else if(subTwoLength === 5 && subOneLength === 0 && !firmId) {
      this.subcontractorFirmList = this.subcontractorFirmList.filter(fList => fList.firmId !== subTwoList[0].firmId );
    } else if(subOneLength === 5 && subTwoLength > 0 && subTwoLength < 5) {
      this.subcontractorFirmList = this.subcontractorFirmList.filter(fList => fList.firmId === subTwoList[0].firmId );
    } else if(subTwoLength === 5 && subOneLength > 0 && subOneLength < 5) {
      this.subcontractorFirmList = this.subcontractorFirmList.filter(fList => fList.firmId === subOneList[0].firmId );
    }
  }

  filterByBothFirm(subOneList: any[], subTwoList: any[], firmId: any) {
    return (subOneList?.length === 5 && subTwoList?.length > 0 && firmId && firmId === subOneList[0].firmId)
      || (subOneList?.length > 0 && subTwoList?.length === 5 && firmId && firmId === subTwoList[0].firmId)
      || (subOneList?.length > 0 && subOneList?.length < 5 && subTwoList?.length > 0 && subTwoList?.length < 5)
      || (subOneList?.length === 5 && subTwoList?.length === 5 && !firmId);
  }

  getAuditContactDetail() {
    if(this.auditId) {
      this.auditContactService.getAuditContactDetail(this.auditId).subscribe({
        next: (contact: AuditContactDetailModel) => {
          this.setAuditContactForm(contact);
        }
      });
    } else {
      this.setFirmDetail();
    }
  }

  setAuditContactForm(contactDetail: AuditContactDetailModel) {
    this.savedContactDetail = contactDetail;
    if(!this.contactAddAction) {
      this.auditContactForm.get('generalMail')?.setValue(contactDetail?.generalMail ? contactDetail.generalMail : null);
      this.auditContactForm.get('hasAuditSubcontractor')?.setValue(contactDetail?.hasAuditSubcontractor !== null
        ? contactDetail?.hasAuditSubcontractor?.toString()
        : (this.auditDetail.auditInformation.createdForPrimaryFirm ? 'true' : null));
    }
    this.setAuditPrimaryContact(contactDetail?.primaryAuditContact);
    this.setAuditSubcontractorOne(contactDetail?.auditSubcontractorOne);
    this.setAuditSubcontractorTwo(contactDetail?.auditSubcontractorTwo);
    this.setFirmDetail(contactDetail);
    this.filterSubcontractorFirmList();

    if(this.auditContactForm.valid && this.auditContactForm.pristine && contactDetail?.hasAuditSubcontractor !== null) {
      this.completedAuditContactForm.emit(2);
      this.savedAuditContactForm.emit({
        auditContactDetail: contactDetail,
      });
      this.contactWarning.emit(false);
    } else if(this.auditContactForm.invalid && this.contactAddAction) {
      this.inProgressAuditContactForm.emit();
    }
    this.contactAddAction = false;

    if(this.auditRequestMode === 'read') {
      this.auditContactForm.get('generalMail').disable();
      this.completedAuditContactForm.emit(2);
      this.contactWarning.emit(false);
    }
  }

  setAuditPrimaryContact(primaryContactList: AuditContactModel[]) {
    this.primaryContactForm?.clear();
    primaryContactList?.forEach( (primary: AuditContactModel) => {
      this.primaryContactForm.push(this.getContactForm(primary));
    });
  }

  setAuditSubcontractorOne(subcontractorOneList: AuditContactModel[]) {
    this.subcontractorOneForm?.clear();
    subcontractorOneList?.forEach( (subOne: AuditContactModel) => {
      this.subcontractorOneForm.push(this.getContactForm(subOne));
    });
  }

  setAuditSubcontractorTwo(subcontractorTwoList: AuditContactModel[]) {
    this.subcontractorTwoForm?.clear();
    subcontractorTwoList?.forEach( (subTwo: AuditContactModel) => {
      this.subcontractorTwoForm.push(this.getContactForm(subTwo));
    });
  }

  /** Add primary audit contact and open contact form model with templateRef**/
  addPrimaryContactForm(template: TemplateRef<any>) {
    this.contactForm = this.getContactForm();
    const contactType = this.auditContactForm.get('firmId')?.value
      ? AUDIT_CONTACT.contactType.auditor : AUDIT_CONTACT.contactType.clientAuditor;
    this.contactForm.get('contactType')?.setValue(contactType);
    this.contactForm.get('isPrimaryContact')?.setValue(false);
    this.contactForm.get('firmId')?.setValue(this.auditContactForm.get('firmId')?.value);
    this.openAuditContactModel(template);
  }
  /** Add subcontractor audit contact form and open contact form model with templateRef**/
  addSubcontractorContactForm(template: TemplateRef<any>) {
    this.contactForm = this.getContactForm();
    this.contactForm.get('contactType')?.setValue(AUDIT_CONTACT.contactType.subcontractorOne);
    this.contactForm.get('firmId')?.setValue({disabled: false});
    this.contactForm.get('firmId')?.setValidators(Validators.required);
    this.contactForm.get('firmId')?.updateValueAndValidity();
    this.filterSubcontractorFirmList();
    this.openAuditContactModel(template);
  }
  /** Add or update Audit contact based on detailContactId for primary/subcontractor contact **/
  addAuditContact() {
    this.checkEmailExists();
    if(this.contactForm.get('contactType')?.value === AUDIT_CONTACT.contactType.subcontractorOne
      || this.contactForm.get('contactType')?.value === AUDIT_CONTACT.contactType.subcontractorTwo) {
      const firm = this.contactForm.get('firmId')?.value;
      if(typeof firm === 'string' && firm) {
        this.checkSubcontractorFirm(firm);
        if(firm === this.contactForm.get('firmId')?.value) {
          return;
        }
      }
      this.setSubcontractorType();
    } else {
      this.setPrimaryContactType();
    }

    if(this.contactForm.valid) {
      const contactData: AuditContactModel = this.contactForm?.getRawValue();
      contactData.contactSource = 'External';
      contactData.contactEmail = contactData.contactEmail?.toLowerCase();
      contactData.firmId = contactData.firmId?.hasOwnProperty('formattedSearchResult')
        ? Number(contactData.firmId?.formattedSearchResult) : contactData?.firmId;
      this.updateAuditContactApi(contactData);
    }
  }

  checkEmailExists() {
    const email = this.contactForm.get('contactEmail')?.value;
    const primary = this.primaryContactForm?.value?.filter(
      (form: any) => form.contactEmail === email && form.detailContactId !== this.contactForm.get('detailContactId')?.value);
    const subOne = this.subcontractorOneForm?.value?.filter(
      (form: any) => form.contactEmail === email && form.detailContactId !== this.contactForm.get('detailContactId')?.value);
    const subTwo = this.subcontractorTwoForm?.value?.filter(
      (form: any) => form.contactEmail === email && form.detailContactId !== this.contactForm.get('detailContactId')?.value);
    if(primary?.length > 0 || subOne?.length > 0 || subTwo?.length > 0
      || this.contactForm.get('contactEmail')?.value === this.auditContactForm.get('generalMail')?.value) {
      this.contactForm.get('contactEmail')?.setErrors({emailExists: true});
    }
  }

  checkGeneralEmailExists() {
    const email = this.auditContactForm.get('generalMail')?.value ? this.auditContactForm.get('generalMail')?.value?.trim() : null;
    this.auditContactForm.get('generalMail')?.setValue(email);
    const primary = this.primaryContactForm?.value?.filter(
      (form: any) => form.contactEmail === email);
    const subOne = this.subcontractorOneForm?.value?.filter(
      (form: any) => form.contactEmail === email);
    const subTwo = this.subcontractorTwoForm?.value?.filter(
      (form: any) => form.contactEmail === email);
    if(primary?.length > 0 || subOne?.length > 0 || subTwo?.length > 0) {
      this.auditContactForm.get('generalMail')?.setErrors({emailExists: true});
    } else if(this.auditContactForm.get('generalMail')?.hasError('emailExists')){
      this.auditContactForm.get('generalMail')?.setErrors(null);
    }
  }

  setSubcontractorType() {
    const subFirmId = this.contactForm.get('firmId')?.value;
    const subOneList = this.subcontractorOneForm.getRawValue();
    const subTwoList = this.subcontractorTwoForm.getRawValue();
    const subOneFirm = subOneList?.filter( f => f?.firmId === Number(subFirmId?.formattedSearchResult));
    const subTwoFirm = subTwoList?.filter( f => f?.firmId === Number(subFirmId?.formattedSearchResult));

    if(!this.contactForm.get('detailContactId')?.value && subFirmId?.formattedSearchResult) {
      if(this.subContactType(subOneList, subTwoList, subOneFirm, subTwoFirm, AUDIT_CONTACT.contactType.subcontractorOne))   {
        this.contactForm.get('contactType')?.setValue(AUDIT_CONTACT.contactType.subcontractorOne);
      } else if(this.subContactType(subOneList, subTwoList, subOneFirm, subTwoFirm, AUDIT_CONTACT.contactType.subcontractorTwo)) {
        this.contactForm.get('contactType')?.setValue(AUDIT_CONTACT.contactType.subcontractorTwo);
      }
    } else if(this.contactForm.get('detailContactId')?.value && subFirmId?.formattedSearchResult) {
      const contactType = this.contactForm.get('contactType')?.value;
      if(this.subcontractorSingleContactCheck(contactType, subOneList, subTwoList)) {
        this.contactForm.get('contactType')?.setValue(contactType);
      } else if(contactType === AUDIT_CONTACT.contactType.subcontractorOne
        && subOneList?.length > 0 && subOneList?.length !== subOneFirm?.length
        && subTwoList?.length === subTwoFirm?.length) {
        this.contactForm.get('contactType')?.setValue(AUDIT_CONTACT.contactType.subcontractorTwo);
      } else if(contactType === AUDIT_CONTACT.contactType.subcontractorTwo
        && subTwoList?.length > 0 && subTwoList?.length !== subTwoFirm?.length
        && subOneList?.length === subOneFirm?.length) {
        this.contactForm.get('contactType')?.setValue(AUDIT_CONTACT.contactType.subcontractorOne);
      }
    }
  }
  subContactType(subOneList: any[], subTwoList: any[], subOneFirm: any[], subTwoFirm: any[], contactType: string) {
    if(contactType === AUDIT_CONTACT.contactType.subcontractorOne) {
      return (subOneList?.length === 0 && subTwoList?.length === 0)
        || (subOneList?.length < 5 && subTwoList?.length === 5)
        || (subOneList?.length > 0 && subOneList?.length < 5 && subOneFirm?.length > 0)
        || (subTwoList?.length > 0 && subTwoFirm?.length === 0 && subOneList?.length >= 0 && subOneFirm?.length === 0);
    } else if(contactType === AUDIT_CONTACT.contactType.subcontractorTwo) {
      return (subOneList?.length === 5 && subTwoList?.length < 5)
        || (subTwoList?.length > 0 && subTwoList?.length < 5 && subTwoFirm?.length > 0)
        || (subOneList?.length > 0 && subOneFirm?.length === 0 && subTwoList?.length >= 0 && subTwoFirm?.length === 0);
    }
    return false;
  }

  subcontractorSingleContactCheck(contactType: string, subOneList: any[], subTwoList: any[]) {
    return (contactType === AUDIT_CONTACT.contactType.subcontractorOne && subOneList?.length === 1 && subTwoList?.length === 0)
    || (contactType === AUDIT_CONTACT.contactType.subcontractorTwo && subOneList?.length === 0 && subTwoList?.length === 1);
  }

  setPrimaryContactType() {
    if(!this.contactForm.get('detailContactId')?.value) {
      this.contactForm.get('contactType')?.setValue(this.auditContactForm.get('firmId')?.value ?
        AUDIT_CONTACT.contactType.auditor : AUDIT_CONTACT.contactType.clientAuditor);
    }
    if(!this.contactForm.get('firmId')?.value && this.auditContactForm.get('firmId')?.value) {
      this.contactForm.get('firmId')?.setValue(this.auditContactForm.get('firmId')?.value);
    }

    const primaryContactValue = this.primaryContactForm?.getRawValue();
    const filterPrimaryContact = primaryContactValue?.filter(
      contact => contact.isPrimaryContact === true && contact.detailContactId !== this.contactForm?.get('detailContactId')?.value);
    if(filterPrimaryContact?.length > 0 && this.contactForm?.get('isPrimaryContact')?.value) {
      this.contactForm.get('isPrimaryContact')?.setErrors({primaryExists: true});
    }

  }

  updateAuditContactApi(contactData: AuditContactModel) {
    this.auditContactService.updateAuditContactApiCall(this.contactForm.get('detailContactId')?.value, contactData).subscribe({
      next: () => {
        this.getAuditContactAndCloseModel();
      }
    });
  }

  getFirmName(data: number) {
    const firm: FirmModel[] = this.firmList.filter( fList => fList.firmId === data);
    return firm?.length > 0 ? firm[0].firmName : null;
  }

  /** Audit contact view edit and delete action**/
  contactCardViewAction(event: any) {
    if(event?.mode === AUDIT_CONTACT.contactMode.edit && event?.contactDetail) {
      this.contactForm = this.getContactForm(event?.contactDetail);
      if(event?.contactDetail?.contactType === AUDIT_CONTACT.contactType.subcontractorOne
        || event?.contactDetail?.contactType === AUDIT_CONTACT.contactType.subcontractorTwo) {
        this.contactForm.get('firmId')?.setValidators(Validators.required);
      }
      this.auditContactMode = 'edit';
      this.checkSubFirmList();
      this.openAuditContactModel(this.formTemplateRef);
    } else if(event?.mode === AUDIT_CONTACT.contactMode.delete && event?.contactDetail) {
      this.auditContactMode = 'delete';
      this.openDeleteContactModel(event);
    } else if(event?.mode === AUDIT_CONTACT.contactMode.view && event?.contactDetail) {
      this.contactForm = this.getContactForm(event?.contactDetail);
      this.auditContactMode = 'read';
      this.checkSubFirmList();
      this.openAuditContactModel(this.formTemplateRef);
      this.contactForm.disable();
    }
  }
  checkSubFirmList() {
    if((this.contactForm.get('contactType')?.value === AUDIT_CONTACT.contactType.subcontractorOne)
        || (this.contactForm.get('contactType')?.value === AUDIT_CONTACT.contactType.subcontractorTwo)) {
      this.filterSubcontractorFirmList(this.contactForm.get('firmId')?.value);
    }
  }
  contactFormAction(event: any) {
    if(event?.mode === AUDIT_CONTACT.contactMode.cancel) {
      this.closeContactDialogModel();
    } else if(event?.mode === AUDIT_CONTACT.contactMode.add) {
      this.addAuditContact();
    }
  }

  openDeleteContactModel(event: any) {
    if(event?.contactDetail?.isPrimaryContact && (event?.contactDetail?.contactType === AUDIT_CONTACT.contactType.auditor
      || event?.contactDetail?.contactType === AUDIT_CONTACT.contactType.clientAuditor)) {
      this.showErrorNotification(
        AUDIT_CONTACT.errorMessage.primaryDelete,
        AUDIT_CONTACT.errorMessage.deleteTitle,
        AUDIT_CONTACT.banner.alertDiv,
        20000);
      return;
    }
    this.auditDetailContactId = event?.contactDetail?.detailContactId;
    this.checkAuditRoleExist(event?.contactDetail?.contactType);
  }

  deleteAuditContact() {
    if(this.auditDetailContactId) {
      this.auditContactService.deleteAuditContact(this.auditDetailContactId).subscribe({
        next: () => {
          this.getAuditContactAndCloseModel();
          this.showSuccessNotification(
            AUDIT_CONTACT.successMessages.delete,
            AUDIT_CONTACT.successMessages.deleteTitle,
            AUDIT_CONTACT.banner.alertDiv,
            15000);
        }, error: () => {
          this.closeContactDialogModel();
        }
      });
    }
  }

  checkAuditRoleExist(contactType: string) {
    this.auditRoleExist = '';
    this.auditRoleService.getAuditRole(this.auditId, contactType).subscribe({
      next: (auditRole: AuditRole) => {
        if(auditRole?.offeringId?.length || auditRole?.infoRequestId?.length || auditRole?.customRequestId?.length) {
          this.auditRoleExist = AUDIT_CONTACT.hintMessage.auditRoleExist;
        }
        this.openAuditContactModel(this.deleteTemplateRef, AUDIT_CONTACT.contactMode.delete);
      }, error: () => {
        this.openAuditContactModel(this.deleteTemplateRef, AUDIT_CONTACT.contactMode.delete);
      }
    });

  }

  getAuditContactAndCloseModel() {
    this.contactAddAction = true;
    this.closeContactDialogModel();
    this.getAuditContactDetail();
  }

  addMainAuditContacts() {
    this.checkGeneralEmailExists();

    if(this.auditContactForm.valid && this.auditId) {
      const auditContactValue = this.auditContactForm.getRawValue();
      const auditContactDetail: AuditContactDetailModel = {
        ...auditContactValue,
        auditId: this.auditDetail?.auditId,
        recordId: this.auditDetail?.recordId,
        status: this.auditDetail?.status,
        mode: 'request',
        generalMail: auditContactValue?.generalMail ? auditContactValue?.generalMail?.toLowerCase() : null,
        contactSource: 'External'
      };
      this.auditContactService.updateAuditContactDetail(this.auditId, auditContactDetail).subscribe({
        next: (auditContact: AuditContactDetailModel) => {
          this.showSuccessNotification(
            AUDIT_CONTACT.successMessages.add,
            AUDIT_CONTACT.successMessages.successTitle,
            AUDIT_CONTACT.banner.alertDiv,
            15000);
          this.completedAuditContactForm.emit(2);
          this.savedAuditContactForm.emit({
            auditContactDetail: auditContact,
          });
          this.auditContactForm.markAsPristine();
          this.contactWarning.emit(false);
          this.savedContactDetail = auditContact;
        },
        error: () => {
          this.showErrorNotification(
            AUDIT_CONTACT.errorMessage.add,
            AUDIT_CONTACT.errorMessage.errorTitle,
            AUDIT_CONTACT.banner.alertDiv,
            20000);
        }
      });
    } else if(this.auditContactForm.invalid) {
      this.showErrorMessage();
    }
  }

  showErrorMessage() {
    if(this.auditContactForm?.hasError('primaryAuditContact') && this.auditContactForm?.get('hasAuditSubcontractor')?.invalid) {
      this.bannerData = {
        bannerType: CVSBannerType.Error,
        outletId: AUDIT_CONTACT.banner.alertDiv,
        template: this.customBannerContactTemplate,
        viewContainerRef: this.viewContainerRef,
        removedAfterMilliseconds: 15000
      };
      this.showCustomNotification(this.bannerData);
    } else {
      let errorMessage = AUDIT_CONTACT.errorMessage.add;
      if(this.auditContactForm?.get('primaryAuditContact').invalid || this.auditContactForm?.hasError('primaryAuditContact')) {
        errorMessage = AUDIT_CONTACT.formValidationMessages.primaryAuditContact.primaryAuditor;
      } else if (this.auditContactForm?.get('hasAuditSubcontractor')?.invalid) {
        errorMessage = AUDIT_CONTACT.formValidationMessages.hasAuditSubcontractor.required;
      } else if (this.auditContactForm?.hasError('hasAuditSubcontractor')) {
        errorMessage = AUDIT_CONTACT.formValidationMessages.hasAuditSubcontractor.length;
      } else if(this.auditContactForm.get('generalMail')?.invalid) {
        this.mailbox.nativeElement?.focus();
        return;
      }
      this.showErrorNotification(
        errorMessage,
        AUDIT_CONTACT.errorMessage.errorTitle,
        AUDIT_CONTACT.banner.alertDiv,
        20000);
    }

  }

  checkSubcontractorFirm(firm: string) {
      const filterFirm = this.subcontractorFirmList
        .filter( data => data?.firmName?.toLowerCase() === firm?.toLowerCase())
        .map(map => { return {formattedSearchResult: map?.firmId?.toString(), altSearchParam: map?.firmName}; });
      this.contactForm.get('firmId')?.setValue(filterFirm?.length ? filterFirm[0] : firm);
  }

  /**
   * Handles the Cancel button functionality for the Audit Contact form.
   * Sets the Contact form back to what was in the savedContactDetail variable,
   * then checks the validity of the form.
   */
  cancelAuditContact() {
    this.bs.close();
    this.setAuditContactForm(this.savedContactDetail);
    if(this.auditContactForm.valid) {
      this.completedAuditContactForm.emit(2);
      this.auditContactForm.markAsPristine();
      this.contactWarning.emit(false);
    }
  }

  protected readonly auditInitiationType = auditInitiationType;
  protected readonly AUDIT_CONTACT = AUDIT_CONTACT;
  protected readonly Constants = Constants;
}
