import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import { FormControl } from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import { CVSConfirmationDialogContentComponent, CVSConfirmationDialogData } from 'angular-component-library';
import { take } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { BannerService } from 'src/app/shared/services/banner.service';
import { NotesService } from './notes.service';
export enum NOTE_ACTION {
  ADD,
  VIEW,
  EDIT,
  DELETE
}

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent  extends BaseComponent implements OnChanges{
  @ViewChild('notesModalRef') modalRef!: TemplateRef<any>;
  @Input() timeLine!: FormControl | any;
  @Input() disableNotes!: boolean;
  @Input() savedNote!: boolean;
  @Input() viewNote!: boolean;
  @Input() disableDelete!: boolean;
  @Input() deleteNoteExtAdmin!: boolean;
  noteAction = NOTE_ACTION;
  confirmationDialog: any;

  constructor(private matDialog: MatDialog,
              private bs: BannerService,
              private notesService: NotesService) {
    super(bs);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.disableNotes) {
      this.timeLine.patchValue({ notes: '' }, { emitEvent: false });
      this.savedNote = false;
    }

    if (this.timeLine.value.notes.length === 1) {
      this.savedNote = false;
    }
  }

  showNotesManagementModal(action: NOTE_ACTION): void {
    if (action === NOTE_ACTION.ADD || action === NOTE_ACTION.EDIT) {
      const existingNote = this.timeLine.value.notes;
      const dialogData = {
        headline: action === NOTE_ACTION.ADD ? 'Add Notes' : 'Edit Notes',
        actionLabel: action === NOTE_ACTION.ADD ? 'Add' : 'Update',
        cancelLabel: 'Cancel',
        templatePortalContent: this.modalRef,
      } as CVSConfirmationDialogData;

      const onConfirm = () => {
        if (this.timeLine.value.notes.length > 0) {
          this.savedNote = true;
        } else if (this.timeLine.value.notes.length === 0) {
          this.timeLine.patchValue({ notes: '' }, { emitEvent: false });
          this.savedNote = false;
        }
        this.updateNotes(action, existingNote);
        this.confirmationDialog.close();
      };

      const onClose = () => {
        if (action === NOTE_ACTION.EDIT) {
          this.timeLine.patchValue({ notes: existingNote }, { emitEvent: true });
        } else if (action === NOTE_ACTION.ADD) {
          this.timeLine.patchValue({ notes: '' }, { emitEvent: true });
          this.savedNote = false;
        }
        this.timeLine.markAsPristine();
        this.confirmationDialog.close();
      };
      this.displayDialog(dialogData, onConfirm, onClose);
    } else if (action === NOTE_ACTION.DELETE) {
      const dialogData = {
        headline: 'Delete Note?',
        body: 'Are you sure you wish to delete the note associated to this timeline?',
        actionLabel: 'Yes',
        cancelLabel: 'Cancel',
      } as CVSConfirmationDialogData;

      const onConfirm = () => {
        this.timeLine.patchValue({ notes: '' }, { emitEvent: true });
        this.savedNote = false;
        this.confirmationDialog.close();
        this.updateNotes(action, '');
      };
      const onClose = () => this.confirmationDialog.close();
      this.displayDialog(dialogData, onConfirm, onClose);

    } else if(action === NOTE_ACTION.VIEW) {
      this.confirmationDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
        panelClass: 'help-body',
        disableClose: true,
        data: {
          headline: this.timeLine?.value.title + ' - Notes',
          body: this.timeLine?.value.notes
        },
        ariaLabelledBy: 'headlineValue'
      });
    }
  }

  displayDialog(dialogData: CVSConfirmationDialogData, confirmCallBackFn?: any, closeCallBackFn?: any) {
    this.confirmationDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
      disableClose: true,
      data: dialogData
    });
    this.confirmationDialog.componentInstance.onConfirmClick.pipe(take(1)).subscribe(confirmCallBackFn);
    this.confirmationDialog.componentInstance.onCancelClick.pipe(take(1)).subscribe(closeCallBackFn);
  }

  updateNotes(action: NOTE_ACTION, existingNote: string) {

    this.notesService.updateOrDeleteNotesRequest(this.timeLine.value)
      .subscribe({
        next: (response) => {
          this.showSuccessNotification(
            action === NOTE_ACTION.DELETE ? 'Audit Timeline Note Deleted' : 'Audit Timeline Note Saved',
            action === NOTE_ACTION.DELETE ?
              this.timeLine.value.title + ' Note deleted successfully' :
              this.timeLine.value.title + ' Note Saved successfully',
            '#audit-timeline-alert-div',
            15000);
        },
        error: (error) => {
          this.timeLine.patchValue({ notes: existingNote }, { emitEvent: true });
          this.savedNote = false;
          this.showErrorNotification(
            'Please try again',
            'Unable to establish server connection',
            '#audit-timeline-alert-div',
            20000);
        }
      });
  }

}
