import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Injectable} from '@angular/core';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppEnvResolverService implements Resolve<any> {

  resolve(route: ActivatedRouteSnapshot): any {
    return environment;
  }

}
