import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {map, Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable()
export class AuthHttpInterceptorService implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const idToken = sessionStorage.getItem('id_token');
        const createdByHeaders = sessionStorage.getItem('email') || '';
        const appRoleData = sessionStorage.getItem('appRole') ? JSON.parse(sessionStorage.getItem('appRole')) : [];
        const firmData = sessionStorage.getItem('firmId') || '';

        if (req.url.toString().includes(environment.backendUrl)) {
            // return next.handle(req.clone({setHeaders: {backendToken: '' + idToken}}));
            return next.handle(req.clone({setHeaders: {
              Authorization: 'Bearer  ' + idToken,
              createdById: createdByHeaders,
              appRole: appRoleData,
              firmId: firmData
            }}));
        } else {
            return next.handle(req).pipe(
                map((event: HttpEvent<any>) => {
                    return event;
                })
            );
        }

    }
}

