<button (keydown.enter)="auditInfoService.backClicked()" (click)="auditInfoService.backClicked()" class="previous-page" tabindex="0">
  <mat-icon svgIcon="caret-left--s" aria-hidden="false"></mat-icon>
  <span class="previous-page-name">Dashboard</span>
</button>
<form class="audit-timeline-form pt-10" [formGroup]="auditTimelineForm" autocomplete="off">
  <div id="audit-timeline-alert-div">
  </div>
  <ng-container>
    <h1 class="header-spacing">Actual Timeline</h1>
    <div class="actual-timeline-info">
      <div>
        <strong>{{clientOrCoalitionLabelName}}:</strong> {{originalClientName}}
      </div>
      <div>
        <strong>Record ID:</strong> {{amsAuditRecordID}}
      </div>
    </div>
    <div class="actual-timeline-info pt-10" *ngIf="clientName">
      <div>
        <strong>CVS {{clientOrCoalitionLabelName}}:</strong> {{clientName}}
      </div>
    </div>
    <p class="paragraph-style actual-timeline-paragraph">
      Reference the Revised Timeline for expected due dates.
      When actual timelines are entered if the date is past the expected due date the subsequent revised timeline dates
      will be adjusted to support the needed working business days(general timeline).
    </p>
  </ng-container>

  <ng-container>
    <mat-table [dataSource]="timeLineDataSource" class="table-styling pds-table pds-card pds-table--dense">
      <mat-header-row *matHeaderRowDef="menuColumns"></mat-header-row>
      <mat-row class="table-row-style" *matRowDef="let row; let i = index; columns: menuColumns;"></mat-row>

      <!-- Projected Timeline Column -->
      <ng-container matColumnDef="projectedTimeline">
        <mat-header-cell class="actual-pt-column-style mat-header-cell-style" *matHeaderCellDef>
          Audit Start Agreed Timeline</mat-header-cell>
        <mat-cell class="actual-pt-cell-style actual-pt-column-style" *matCellDef="let element; let i = index;" [formGroup]="element">
          <div>
            <div *ngIf="element.value.notApplicable || (isAuditTypePricing && i === 2)">
              <mat-form-field class="projected-timeline-datepicker-form pds-read-only-field cvs-compact-form-field">
                <mat-label>{{element.value?.title}}</mat-label>
                <input matInput disabled [value]="'N/A'">
              </mat-form-field>
            </div>
            <div *ngIf="!element.value.notApplicable">
              <ng-container  *ngIf="!(element.value.notApplicable || (isAuditTypePricing && i === 2))">
                <mat-form-field class="projected-timeline-datepicker-form pds-read-only-field cvs-compact-form-field" appearance="outline">
                  <mat-label>{{element.value?.title}}</mat-label>
                  <input matInput disabled [value]="element.value?.date | formatDate">
                </mat-form-field>
              </ng-container>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <!-- General Timeline Column -->
      <ng-container matColumnDef="generalTimeline">
        <mat-header-cell class="mat-header-cell-style actual-gt-column-style" *matHeaderCellDef>General Timeline</mat-header-cell>
        <mat-cell class="table-text-style actual-gt-column-style" *matCellDef="let element">
          <ng-container *ngIf="element.value?.duration > 0">
            {{ element.value?.duration }} business days
          </ng-container>
          <ng-container *ngIf="element.value?.duration === 0">
            -
          </ng-container>
        </mat-cell>
      </ng-container>

      <!-- Responsibility Column -->
      <ng-container matColumnDef="responsibility">
        <mat-header-cell class="mat-header-cell-style actual-r-column-style" *matHeaderCellDef>Responsibility</mat-header-cell>
        <mat-cell class="table-text-style actual-r-column-style" *matCellDef="let element"> {{ element.value?.responsibility }} </mat-cell>
      </ng-container>

      <!-- Actual Date Column -->
      <ng-container>
        <ng-container matColumnDef="actualDate">
          <mat-header-cell class="mat-header-cell-style" *matHeaderCellDef>Actual Date</mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
            <mat-form-field class="datepicker-form cvs-compact-form-field" appearance="outline" *ngIf="!element.value.notApplicable && !(isAuditTypePricing && i === 2) && !externalUser && auditRequestMode !== AUDIT_INFO.modes.read">
              <input (keydown)="onKeyDown($event)" (dateChange)="handleValueChange(i)" matInput [matDatepicker]="pickerClosedFilled" formControlName="actualDate">
              <mat-icon class="icon" svgIcon="calendar--s" matSuffix (click)="pickerClosedFilled.open()">
              </mat-icon>
              <mat-datepicker #pickerClosedFilled [startAt]="element.value?.date"></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="pds-read-only-field cvs-compact-form-field" appearance="outline" *ngIf="externalUser">
              <input matInput disabled [value]="element.value?.actualDate | formatDate">
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <!-- Revised Date Column -->
        <ng-container matColumnDef="revisedDate">
          <mat-header-cell class="mat-header-cell-style" *matHeaderCellDef>
            Revised Timeline</mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
            <div>
              <div *ngIf="!element.value.notApplicable && !(isAuditTypePricing && i === 2)">
                <mat-form-field class="pds-read-only-field cvs-compact-form-field" appearance="outline">
                  <input matInput disabled [value]="element.value?.revisedDate | formatDate">
                </mat-form-field>
              </div>
              <div *ngIf="element.value.notApplicable || (isAuditTypePricing && i === 2)">
                <mat-form-field class="datepicker-form pds-read-only-field cvs-compact-form-field">
                  <input matInput disabled [value]="'N/A'">
                </mat-form-field>
              </div>
            </div>
          </mat-cell>
        </ng-container>
      </ng-container>

      <!-- Notes Column -->
      <ng-container matColumnDef="notes">
        <mat-header-cell class="mat-header-cell-style" *matHeaderCellDef>Notes</mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
          <div>
            <div *ngIf="element.value?.notesExists === false">
              <button class="pds-btn-text-only"
                      [disabled]="element.value.notApplicable ||
                      (i === 2 && isAuditTypePricing) ||
                      (element.value.date === '' && auditRequestMode === AUDIT_INFO.modes.read) ||
                      (auditRequestMode === AUDIT_INFO.modes.read)"
                      (click)="notesModal(element, 'add')">
                <mat-icon class="icon" svgIcon="pencil--s"> </mat-icon>
                Add Notes
              </button>
            </div>
            <div *ngIf="element.value?.notesExists === true">
              <button  class="pds-btn-text-only"
                       [disabled]="element.value.notApplicable ||
                       (i === 2 && isAuditTypePricing) ||
                       (element.value.date === '' && auditRequestMode === AUDIT_INFO.modes.read)"
                       (click)="notesModal(element, auditRequestMode === AUDIT_INFO.modes.read ? 'read' : 'view')">

                <mat-icon class="icon" svgIcon="file-check--s"> </mat-icon>
                {{auditRequestMode === AUDIT_INFO.modes.read ? 'View ' : 'View/Add '}}Notes
              </button>
            </div>
          </div>
        </mat-cell>
      </ng-container>
    </mat-table>
  </ng-container>
  <div class="mt-10" style="display: flex; justify-content: space-between; width: 58vw;">
    <div>
      <button type="button" class="pds-btn-ghost" (click)="saveAuditTimeLines()" [disabled]="!enableCancelAndSave || externalUser" *ngIf="!userService.isReadOnlyUser()">Save</button>
      <button type="button" class="pds-btn-ghost" (click)="cancelAuditTimeLines()" [disabled]="!enableCancelAndSave || externalUser" *ngIf="!userService.isReadOnlyUser()">Cancel</button>
    </div>
  </div>
</form>

