import { Injectable } from '@angular/core';
import {TimeLine } from '../../models/time-line.model';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import { Observable, delay, retryWhen} from 'rxjs';
import {AuditSubmitModel} from './AuditSubmitModel';


@Injectable({
  providedIn: 'root'
})
export class TimeLineService {

  constructor(private _http: HttpClient) {}


  headers!: HttpHeaders;
  respe !: HttpResponse<string>;

  getAll(auditId: number) {
    const url = environment.backendUrl +'/audit/timeline/' + auditId;
    return this._http.get<TimeLine[]>(url).pipe(retryWhen(errors => errors.pipe(delay(1000))));
  }

  saveUpdateAuditTimelineRequest(body: Array<TimeLine>, formType: string): Observable<any>{
    this.headers = new HttpHeaders({
      createdById: sessionStorage.getItem('email') || '',
      createdByName: sessionStorage.getItem('name') || ''
    });

    const queryParams = new HttpParams().set('timeLineForm', formType);

    return this._http.post<any>(environment.backendUrl+'/audit/timeline/draftList', body,
    {headers: this.headers,
    params: queryParams});
  }

  auditFormSubmit(recordId: string) {
    return this._http.get<AuditSubmitModel>(environment.backendUrl+'/audit/submit/'+recordId);
  }

  cancelAudit(recordId: string, notes: string): Observable<string> {
    return this._http.put<string>(environment.backendUrl + '/audit/cancelAuditRequest/'+recordId, {notes});
  }

  returnAudit(recordId: string, notes: string): Observable<string> {
    return this._http.put<string>(environment.backendUrl + '/audit/returnAuditRequest/'+recordId, {notes});
  }

  approveAudit(recordId: string, notes?: string){
    const notesObject = {
      recordId, notes
    };
    return this._http.put<AuditSubmitModel>(environment.backendUrl+'/audit/submit/approveAuditRequest', notesObject);
  }
}
