import { Component } from '@angular/core';
import {
  AuthenticationService,
  AuthorizationService,
  BrandTypes,
  CVSAuthConfig,
  CVSBaseConfig,
  MenuState,
  SideNavConfig
} from 'angular-component-library';
import {environment} from '../environments/environment';
import {UserService} from './services/user.service';
import {Router} from '@angular/router';
import { WelcomeHeaderComponent } from './welcome-header/welcome-header.component';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  year: any = new Date().getFullYear();
  baseConfig: CVSBaseConfig;
  authConfig: CVSAuthConfig;
  sideNavConfig: SideNavConfig;

  constructor(
    private authorizationService: AuthorizationService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router
  ) {

    this.baseConfig = {
      homeRoute: '/',
      unbranded: false,
      brand: BrandTypes.CAREMARK,
      appName: 'Audit Management'+environment.region,
      footerText: `© Copyright ${this.year} CVS Caremark`,
      headerPortal: WelcomeHeaderComponent,
      logoutCallback: this.logout
    } as CVSBaseConfig;

    this.authConfig = {
      authenticationEnabled: environment.authenticationEnabled,
      ssoBaseUrl: environment.ssoBaseUrl,
      authorizationUrl: environment.authorizationUrl,
      clientId: environment.clientId,
      idle: environment.idle,
      timeOut: environment.timeOut,
      tokenUrl: environment.tokenUrl,
      useAccessToken: environment.useAccessToken,
      discoveryDocumentUrl: environment.discoveryDocumentUrl,
      loginUrl: environment.loginUrl,
      logoutUrl: environment.logoutUrl,
      issuer: environment.issuer,
      accessDeniedMessage: environment.accessDeniedMessage,
      scope: environment.scope
    } as CVSAuthConfig;

    this.sideNavConfig = {
      sideNavItems: [
        {
          name: 'Dashboard',
          icon: 'laptop--s',
          routerLink: '/home'
        },
        {
          name: 'myPBM',
          icon: 'app--s',
          routerLink: '/myPBM'
        }
      ],
      iconMenu: true,
      menuState: MenuState.Closed
    } as SideNavConfig;

    if(!this.authenticationService.isAuthenticated()) {
      this.setUserDetails();
    }

    if(environment.name !== 'local') {
      this.loadAppDynamicScript();
    }
  }

  setUserDetails() {
    this.authorizationService.authorizationSuccess.subscribe({
      next: (authorizationResponse: any) => {
        if (authorizationResponse?.isAuthorized) {
          this.userService.setUserDetailsInSessionStorage(authorizationResponse.user);
          window.location.reload();
          // this.routeUser();
        } else {
          this.userService.clearUserSession();
          this.router.navigate(['/home']).then();
        }

      }, error: (error: any) => {
        console.log('No response from ACL Auth service', error);
        this.router.navigate(['/home']).then();
      }
    });
  }

  routeUser() {
    if(this.userService.isExternalUser()) {
      window.location.href = '/external';
      return;
    } else if(this.userService.isInternalUser()) {
      window.location.href = '/internal';
      return;
    } else {
      console.log('Unknown user');
      this.router.navigate(['/home']).then();
    }
  }

  logout = () => {
    return new Observable((subscriber) => {
      subscriber.next(true);
      sessionStorage.clear();
      subscriber.complete();
    });
  };

  private loadAppDynamicScript() {
    const appDynamicsConfigScript = document.createElement('script');
    appDynamicsConfigScript.type = 'text/javascript';
    appDynamicsConfigScript.innerHTML =
      'window["adrum-start-time"] = new Date().getTime(); ' +
      '(function(config){ ' +
      'config.appKey = "' + environment.appDynamicsKey + '"; ' +
      'config.adrumExtUrlHttp = "http://cdn.appdynamics.com"; ' +
      'config.adrumExtUrlHttps = "https://cdn.appdynamics.com"; ' +
      'config.beaconUrlHttp = "http://pdx-col.eum-appdynamics.com"; ' +
      'config.beaconUrlHttps = "https://pdx-col.eum-appdynamics.com"; ' +
      'config.useHTTPSAlways = true; ' +
      'config.resTiming = {"bufSize": 200, "clearResTimingOnBeaconSend": true}; ' +
      'config.maxUrlLength = 512; ' +
      '})(window["adrum-config"] || (window["adrum-config"] = {}));';
    const appDynamicsSrcScript = document.createElement('script');
    appDynamicsSrcScript.src = '//cdn.appdynamics.com/adrum/adrum-23.3.0.4265.js';
    const head = document.getElementsByTagName('head')[0];
    if (head !== null && head !== undefined) {
      document.head.appendChild(appDynamicsConfigScript);
      document.head.appendChild(appDynamicsSrcScript);
    }
  }
}
