import {Injectable} from '@angular/core';
import {PBMUserInfo} from '../models/pbm-user-info.model';
import * as _ from 'underscore';
import {ClientDetailsModel} from '../models/clientDetails.model';
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root'
})
export class UserService {


  setUserDetailsInSessionStorage(user: PBMUserInfo) {
    this.clearUserSession();
    sessionStorage.setItem('email', user.basicUserInfo.email.toLowerCase());
    sessionStorage.setItem('name', [user.basicUserInfo.firstName,
      user.basicUserInfo.middleName,
      user.basicUserInfo.lastName].filter(Boolean).join(' '));
    sessionStorage.setItem('appRole', JSON.stringify(user?.appRoles));
    if(user.internalUser &&
      (_.includes(user.appRoles, 'AMS_AUDIT_ADMIN') ||
        _.includes(user.appRoles, 'AMS_AUDIT_ANALYST') ||
          _.includes(user.appRoles, 'AMS_VIEW_ONLY'))) {
      sessionStorage.setItem('empId', user.employeeId);
      sessionStorage.setItem('corpName', user.corporation);
      if (_.includes(user.appRoles, 'AMS_AUDIT_ADMIN')) {
        sessionStorage.setItem('isAdmin', 'true');
      } else if (_.includes(user.appRoles, 'AMS_AUDIT_ANALYST')) {
        sessionStorage.setItem('isAnalyst', 'true');
      } else if(_.includes(user.appRoles, 'AMS_VIEW_ONLY')) {
        sessionStorage.setItem('isViewUser', 'true');
      } else {
        sessionStorage.setItem('isViewUser', 'false');
      }
    }

    if(user.accessType === 'Consultant User' && user.firm && _.includes(user.appRoles, 'AMS_EXTERNAL_ADMIN')) {
      sessionStorage.setItem('isExternalAdmin', 'true');
      sessionStorage.setItem('isFirmUser', 'true');
      sessionStorage.setItem('firmId', user.firm);
    } else if(user.accessType === 'Consultant User' && user.firm && _.includes(user.appRoles, 'AMS_FIRM_AUDITOR')) {
      sessionStorage.setItem('isFirmUser', 'true');
      sessionStorage.setItem('firmId', user.firm);
    }

    if(user.accessType === 'Client User' && user.assignedPortalClients && _.includes(user.appRoles, 'AMS_CLIENT_AUDITOR')) {
      sessionStorage.setItem('isClientUser', 'true');
      this.setClientDetail(user);
    }

  }

  setClientDetail(user: any) {
    const client: any = user?.assignedPortalClients?.length ? user.assignedPortalClients[0] : null;
    if(client?.carrierId || client?.clientCode) {
      const clientData = CryptoJS.AES.encrypt(JSON.stringify(client), 'client').toString();
      sessionStorage.setItem('client', clientData);
    } else if(client){
      const clientDetail: ClientDetailsModel = {
        superClientCode: client?.superClientId,
        superClientName: client?.superClientName,
        accountId: client?.accountId,
        accountName: client?.accountName,
        carrierId: null,
        carrierName: null,
        formattedClientName: null
      };
      const clientData = CryptoJS.AES.encrypt(JSON.stringify(client), 'client').toString();
      const clientData1 = CryptoJS.AES.encrypt(JSON.stringify(clientDetail), 'clientDetail').toString();
      sessionStorage.setItem('client', clientData);
      sessionStorage.setItem('clientDetail', clientData1);
    }
  }

  isInternalUser() {
    return sessionStorage.getItem('isAdmin') === 'true' ||
      sessionStorage.getItem('isAnalyst') === 'true' ||
      sessionStorage.getItem('isViewUser') === 'true';
  }

  isReadOnlyUser() {
    return sessionStorage.getItem('isViewUser') === 'true';
  }

  isAuditAnalyst() {
    return sessionStorage.getItem('isAnalyst') === 'true';
  }

  isAuditAdmin() {
    return sessionStorage.getItem('isAdmin') === 'true';
  }

  isExternalAdmin() {
    return sessionStorage.getItem('isExternalAdmin') === 'true';
  }

  getUserEmail() {
    return sessionStorage.getItem('email');
  }

  isExternalUser() {
    return sessionStorage.getItem('isFirmUser') === 'true' ||
      sessionStorage.getItem('isClientUser') === 'true';
  }

  clearUserSession() {
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('name');
    sessionStorage.removeItem('empId');
    sessionStorage.removeItem('corpName');
    sessionStorage.removeItem('appRole');
    sessionStorage.removeItem('isAdmin');
    sessionStorage.removeItem('isAnalyst');
    sessionStorage.removeItem('isViewUser');
    sessionStorage.removeItem('isExternalAdmin');
    sessionStorage.removeItem('isFirmUser');
    sessionStorage.removeItem('firmId');
    sessionStorage.removeItem('isClientUser');
    sessionStorage.removeItem('client');
    sessionStorage.removeItem('clientDetail');
  }

  getUserName() {
    return sessionStorage.getItem('name');
  }
}
