<div class="title">
  <h1>Audit Management</h1>
  <div class="title-content-right" >
    <button class="pds-btn-primary" [routerLink]="['audit-request/add']" tabindex="0">Request Audit</button>
  </div>
</div>
<div id="audit-dashboard-alert-div"></div>
<div class="tab" aria-live="polite">
  <mat-tab-group animationDuration="0ms" disableRipple class="pds-primary-tab" aria-label="Primary Tab">
    <mat-tab tabIndex="0">
      <ng-template matTabLabel>
        <div class="tab-label-container">
          <mat-icon *ngIf="activeNotesCount>0" svgIcon='dot-indicator--s' class="unread-dot-icon" aria-hidden='false'></mat-icon>
          <div class="tab-label-text">
            <p class="m-0">Active
              <span class="unread-notes-count" *ngIf="activeNotesCount>0">{{activeNotesCount}}</span>
            </p>
          </div>
        </div>
      </ng-template>
      <ng-template matTabContent>
        <app-external-dashboard-list (clientDetail)="getSuperClientDetail()" [tabStatus]="'active'"
                                     (tabNotesCount)="getTabNotesCount($event)"></app-external-dashboard-list>
      </ng-template>
    </mat-tab>
    <mat-tab tabIndex="0">
      <ng-template matTabLabel>
        <div class="tab-label-container">
          <mat-icon *ngIf="draftNotesCount>0" svgIcon='dot-indicator--s' class="unread-dot-icon" aria-hidden='false'></mat-icon>
          <div class="tab-label-text">
            <p class="m-0">Draft
              <span class="unread-notes-count" *ngIf="draftNotesCount>0">{{draftNotesCount}}</span>
            </p>
          </div>
        </div>
      </ng-template>
      <ng-template matTabContent>
        <app-external-dashboard-list (amsRecordId)="editAuditRecord($event)" [tabStatus]="'draft'"
                                     (tabNotesCount)="getTabNotesCount($event)"></app-external-dashboard-list>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>


<ng-template #submitTemplate>
  <cvs-banner [data]="bannerData">
    <h2 >Success</h2>
    <p class="pt-10">Audit Request Submitted</p>
    <div>
      <p>Your claim audit {{recordId}} has been submitted. A CVS analyst will be assigned and respond to you shortly.</p>
      <p>You can view your audit request by selecting on the more icon on your dashboard and go into view audit request</p>
    </div>
  </cvs-banner>
</ng-template>
