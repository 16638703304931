import { Directive, ElementRef, HostListener, Input } from '@angular/core';
@Directive({
    selector: '[appInputRestriction]'
})
export class InputRestrictionDirective {
    inputElement: ElementRef;

    @Input() appInputRestriction!: string;
    arabicRegex = '[u0600-u06FF]';

    constructor(el: ElementRef) {
        this.inputElement = el;
    }

    @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
        if (this.appInputRestriction === 'integer') {
            this.integerOnly(event);
        } else if (this.appInputRestriction === 'noSpecialChars') {
            this.noSpecialChars(event);
        }
    }

    integerOnly(event: KeyboardEvent) {
        const e = event;
        if (e.key === 'Tab' || e.key === 'TAB') {
            return;
        }
        /** Allow: Ctrl+A(65) Ctrl+C(67) Ctrl+V(86) Ctrl+X(88)**/
        if (['Delete', 'Backspace', 'Tab', 'Escape', 'Enter', 'NumpadDecimal'].indexOf(e.code) !== -1
          || (e.code === 'KeyA' && e.ctrlKey)
          || (e.code === 'KeyC' && e.ctrlKey)
          || (e.code === 'KeyV' && e.ctrlKey)
          || (e.code === 'KeyX' && e.ctrlKey)) {
            // let it happen, don't do anything
            return;
        }
        if (['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].indexOf(e.key) === -1) {
            e.preventDefault();
        }
    }

    noSpecialChars(event: KeyboardEvent) {
        const e = event;
        if (e.key === 'Tab' || e.key === 'TAB') {
            return;
        }
        const k: number = event.keyCode;  // k = event.charCode;  (Both can be used)
        if ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57)) {
            return;
        }
        const ch = e.key;
        const regEx = new RegExp(this.arabicRegex);
        if (regEx.test(ch)) {
            return;
        }
        e.preventDefault();
    }

    @HostListener('paste', ['$event']) onPaste(event: any) {
        let regex;
        if (this.appInputRestriction === 'integer') {
            regex = /[0-9]/g;
        } else if (this.appInputRestriction === 'noSpecialChars') {
            regex = /[a-zA-Z0-9\u0600-\u06FF]/g;

        }
        const e = event as ClipboardEvent;
        const pasteData = e?.clipboardData?.getData('text/plain');
        let m;
        const data = pasteData ? regex?.exec(pasteData) : null;
        let matches = 0;
        while ((m = data) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (regex && m?.index === regex.lastIndex) {
              regex.lastIndex++;
            }
            // The result can be accessed through the `m`-variable.
            m?.forEach((match, groupIndex) => {
                matches++;
            });
        }
        if (matches === pasteData?.length) {
            return;
        } else {
            e.preventDefault();
        }
    }

}
