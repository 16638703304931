import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Observable} from 'rxjs';
import {DocumentCategory} from 'src/app/models/document-category.model';
import {DocumentService} from 'src/app/services/document.service';
import {AuditRequest} from '../audit-request.model';
import {DocumentCategories} from 'src/app/enums/document-category-enums';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Constants} from '../../constants/constants';
import {BannerService} from '../../shared/services/banner.service';
import {BaseComponent} from '../../shared/components/base/base.component';
import {UserService} from 'src/app/services/user.service';

@Component({
	selector: 'app-upload-documents',
	templateUrl: './upload-documents.component.html',
	styleUrls: ['./upload-documents.component.scss']
})
export class UploadDocumentsComponent extends BaseComponent implements OnChanges {
	@Input() auditDetail!: AuditRequest;
  @Input() auditDocumentForm!: FormGroup;
  @Input() auditRequestMode!: string;
  @Output() inProgressAuditDocumentForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() completedAuditDocumentForm: EventEmitter<any> = new EventEmitter<any>();
  protected readonly Constants = Constants;
  documentCategories$!: Observable<DocumentCategory[]>;
	isCoalition!: boolean;
	recordId!: string;
  documentCategory = DocumentCategories;
  documentCategoryLength: number;


	constructor(
    private documentService: DocumentService,
    private fb: FormBuilder,
    private userService: UserService,
    private bs: BannerService) {
    super(bs);
  }

  get documentRows() {
    return this.auditDocumentForm.controls['documentRows'] as FormArray;
  }

  /**
   * Sets up Document Form
   */
  setupDocumentForm() {
    this.documentCategories$.subscribe(docCategories => {
      this.documentRows.clear();
      this.documentCategoryLength = docCategories.length;
      docCategories.forEach((category) => {
        const documentForm = {
          docGroup: new FormControl({value: category.uploadDocId, disabled: false}),
          hasFiles: new FormControl({
            value: category.documentDetailDtoList.length > 0,
            disabled: false}, [Validators.requiredTrue])
        };
        if(category.uploadDocCode === 'OTHERDOCUMENTS(OPTIONAL)' ||
          (category.uploadDocCode === 'COALITIONLISTWITHLOB*' && !this.isCoalition)) {
          documentForm.hasFiles.removeValidators([Validators.requiredTrue]);
          documentForm.hasFiles.updateValueAndValidity();
        }
        this.documentRows.push(this.fb.group(documentForm));
      });
      if(this.documentRows.valid) {
        this.completedAuditDocumentForm.emit(4);
      } else {
        this.inProgressAuditDocumentForm.emit();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.auditDetail?.currentValue) {
      this.isCoalition = this.auditDetail.auditInformation.coalition;
      this.recordId = this.auditDetail.recordId;
      this.documentCategories$ = this.documentService.getDocumentCategories(this.auditDetail.auditId);
      this.setupDocumentForm();
    }
  }

  checkFormValidity(evt: { docGroup: number; isUploaded: boolean }) {
    for (let i = 0; i < this.documentCategoryLength; i++) {
      if (this.documentRows.at(i).get('docGroup').value === evt.docGroup) {
        this.documentRows.at(i).get('hasFiles').setValue(evt.isUploaded);
      }
    }
    if (this.auditDocumentForm.valid) {
      this.completedAuditDocumentForm.emit(4);
    } else {
      this.inProgressAuditDocumentForm.emit();
    }
  }
}
