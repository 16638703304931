import { Injectable } from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class EnvConfigService {
 public environmentData: any;

  public getEnvConfigData() {
    return this.environmentData;
  }

  private setEnvConfigData(data: any) {
    this.environmentData = data;
  }

  public consumeEnvData(route: ActivatedRoute) {
    route.data.subscribe((response: any) => {
        this.setEnvConfigData(response.envData);
    });
  }

}
