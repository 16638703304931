import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AuditInformationService} from '../../audit-information/audit-information.service';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuditRequest} from '../../audit-request.model';
import {TimeLine} from '../../../models/time-line.model';
import {MatTableDataSource} from '@angular/material/table';
import {CVSBannerComponentData} from 'angular-component-library';
import {AuditSubmitModel} from '../AuditSubmitModel';
import {TimeLineService} from '../time-line.service';
import {BannerService} from '../../../shared/services/banner.service';
import {UserService} from '../../../services/user.service';
import {MatDialog} from '@angular/material/dialog';
import {AuditRequestUtil} from '../../../utilities/audit-request.util';
import {clone} from 'underscore';
import * as moment from 'moment';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {Constants} from 'src/app/constants/constants';
import * as AUDIT_INFO from '../../audit-information/audit-information.constants';
import {addBusinessDays} from 'date-fns';
import {NotesManagementComponent} from '../../../shared/components/notes-management/notes-management.component';
import {DatePipe} from '@angular/common';
import {NotesCategory, NotesType} from '../../../shared/components/notes-management/notes-management.constants';
import {NotesManagementModel} from '../../../shared/components/notes-management/notes-management.model';

enum TIMELINES {
  // KICK_OFF_DATE = 'Timeline External kick Off Date',
  // DELIVERABLE_DUE_DATE = 'Timeline Initial Deliverable Due Date',
  // REPORTING_DUE_DATE = 'Timeline GER Reporting Due Date',
  QUESTIONS_DUE_DATE = 'Timeline Follow Up Questions Due Date',
  // QUESTIONS_RESPONSE_DATE = 'Timeline Follow Up Questions Response Due Date',
  START_DATE = 'Timeline Onsite Start Date',
  RECEIVED_DUE_DATE = 'Timeline Sample Received Due Date',
  // RESPONSE_DUE_DATE = 'Timeline Sample Response Due Date',
  RESOLVED_DUE_DATE = 'Timeline All Sample Follow Up Resolved Due Date',
  DRAFT_RECEIVED_DUE_DATE = 'Timeline Draft Report Received Due Date',
  // DRAFT_RESPONSE_DUE_DATE = 'Timeline Draft Report Response Due Date',
  FINAL_RECEIVED_DUE_DATE = 'Timeline Final Report Received Due Date',
  // FINAL_RESPONSE_DUE_DATE = 'Timeline Final Report Response Due Date',
  COMPLETION_DATE = 'Timeline Expected Audit Completion Date'
}
@Component({
  selector: 'app-actual-time-line',
  templateUrl: './actual-time-line.component.html',
  styleUrls: ['./actual-time-line.component.scss']
})
export class ActualTimeLineComponent extends BaseComponent implements OnInit {
  amsAuditRecordID: string | null = null;
  auditId: number | null;
  protected readonly Constants = Constants;
  protected readonly AUDIT_INFO = AUDIT_INFO;
  timeLineListSaved: TimeLine[] = [];
  timeLineReview: TimeLine[] = [];
  timeLineDataSource!: MatTableDataSource<any>;
  enabledIndexes = [];
  isFormSetupComplete = false;
  enableCancelAndSave = false;
  savedArray: any[] = [];
  bannerData!: CVSBannerComponentData;
  externalUser!: boolean;
  confirmationDialog: any;
  isReviewAudit = false;
  gerTimeLime!: TimeLine;
  isAnalystOrAdmin = false;
  deleteNotesExtUser!: boolean;
  externalAdmin!: boolean;
  isClientAuditCase: boolean;
  clientOrCoalitionLabelName!: string;
  originalClientName!: string;
  clientName!: string;
  auditTimelineForm!: FormGroup;
  auditRequestMode = 'read';
  menuColumns = ['projectedTimeline', 'generalTimeline', 'responsibility','actualDate','revisedDate', 'notes'];
  isAuditTypePricing = false;
  auditDetail!: AuditRequest;
  revisedTimelineList: Date[] = [];
  enableNotes = true;

  constructor(
    private fb: FormBuilder,
    private timeLineService: TimeLineService,
    public bs: BannerService,
    private cdr: ChangeDetectorRef,
    public userService: UserService,
    private matDialog: MatDialog,
    public auditInfoService: AuditInformationService,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
  ) {
    super(bs);
    this.auditTimelineForm = this.fb.group({
      tableRows: this.fb.array([]),
    });

  }

  get tableRows() {
    return this.auditTimelineForm.controls['tableRows'] as FormArray;
  }

  ngOnInit(): void {
    this.amsAuditRecordID = this.route.snapshot.paramMap.get('amsAuditRecordID');
    this.auditId = Number(this.route.snapshot.paramMap.get('auditId'));
    this.externalUser = this.userService.isExternalUser();
    this.externalAdmin = this.userService.isExternalAdmin();
    this.isAnalystOrAdmin = this.userService.isAuditAnalyst() || this.userService.isAuditAdmin();
    this.deleteNotesExtUser = !this.userService.isExternalAdmin() && !this.userService.isAuditAnalyst() && !this.userService.isAuditAdmin();
    this.auditRequestMode = this.userService.isReadOnlyUser() ? AUDIT_INFO.modes.read : AUDIT_INFO.modes.edit;
    this.getAuditRequestDetails();
    this.initTimelines();
  }

  initTimelines(): void {
    this.timeLineService.getAll(this.auditId).subscribe(timelines => {
      this.timeLineListSaved = timelines;
      this.isTimeLineSavedOrNew(timelines); // TODO why is this line affecting mindates
      this.createTimeLineFormControls(timelines);
      this.timeLineDataSource = new MatTableDataSource(this.tableRows.controls);
      this.cdr.detectChanges();
      this.isFormSetupComplete = true;
      this.tableRows.value.forEach((item: any, index: number) => {
        const getFormControl = (idx = index) => this.tableRows.at(idx);
        this.updateMinDate(getFormControl(), this.calculateMinDate(index));
        this.revisedTimelineList.push(item.revisedDate);
      });
    }, error => {
    });
  }

  handleValueChange(dateInputIndex) {
    if (this.isFormSetupComplete && !this.enableCancelAndSave) {
      this.enableCancelAndSave = true;
    }
    const timeLine: TimeLine = this.tableRows.value[dateInputIndex];
    const getFormControl = (idx = dateInputIndex) => this.tableRows.at(idx);
    this.actualTimelineDateLogic(dateInputIndex, timeLine, getFormControl);
  }

  onKeyDown(event: any) {
    if (event.key === 'Enter') {
      this.enableNotes = false;
    }
  }

  /**
   * Get business day difference between two dates, can return negative days if startDate
   * is after endDate.
   *
   * @param startDate - date value to start from
   * @param endDate - date value to end on
   */
  getDifferenceInBusinessDays(startDate: moment.Moment, endDate: moment.Moment) {
    let currentDate = startDate.clone();
    let addedBusinessDays = 0;
    if (startDate.isBefore(endDate)) {
      while (endDate.diff(currentDate, 'days') > 0) {
        currentDate = currentDate.add(1, 'day');
        if (currentDate.day() !== 0 && currentDate.day() !== 6) {
          addedBusinessDays++;
        }
      }
    } else {
      while (currentDate.diff(endDate, 'days') > 0) {
        if (currentDate.day() !== 0 && currentDate.day() !== 6) {
          addedBusinessDays--;
        }
        currentDate = currentDate.subtract(1, 'day');
      }
    }
    return addedBusinessDays;
  }

  /**
   * Checks the actualDate value compared to the first revised timeline of the index.
   * If the actual day is after what was in the revisedTimelineList, we will update the
   * revisedDate of the index, then iterate through the remainder of the timelines updating the revisedDates
   * until an actualDate !== ''. We are not updating GER Reporting and Onsite Start timelines by iteration.
   *
   * If the actual day is before what was in the revisedTimelineList, we update the revisedDate of
   * the index with what is in the revisedTimelineList. This is just in case the user updates the
   * revisedDates to be after what was after revisedTimelineList, so they can go back to what was originally there.
   *
   * @param index - index of the timeline changed
   * @param item - timeline object
   * @param getFormControl - control object for the timeline
   */
  private actualTimelineDateLogic(index: number, item: any, getFormControl: (idx?: number) => AbstractControl<any, any>) {
    const actualDaysAfterFirstRevisedDate = this.getDifferenceInBusinessDays(
      moment(this.revisedTimelineList[index]),
      moment(getFormControl().value.actualDate)
    );
    // When actualDate is after revisedTimelineList[index], update revised date with calculations
    if (actualDaysAfterFirstRevisedDate >= 0 && getFormControl().value.actualDate !== null) {
      const actualDaysAfterRevisedDate = this.getDifferenceInBusinessDays(
        moment(getFormControl().value.revisedDate),
        moment(getFormControl().value.actualDate));
      this.updateRevisedDate(getFormControl(), item.actualDate);
      if (!(index === 2 || index === 5)) {
        for (let idx = index + 1; idx < this.tableRows.length; idx++) {
          if (!(idx === 2 || idx === 5) && getFormControl(idx).value.actualDate !== '') {
            break;
          }
          if (!(idx === 2 || idx === 5) && !getFormControl(idx).value.notApplicable) {
            this.updateRevisedDate(
              getFormControl(idx),
              addBusinessDays(moment(getFormControl(idx).value.revisedDate).toDate(), actualDaysAfterRevisedDate));
          }
        }
      }
    }
    // When actualDate is before revisedTimelineList[index], update revised date with revisedTimelineList values
    else if (actualDaysAfterFirstRevisedDate < 0 || getFormControl().value.actualDate === null) {
      this.updateRevisedDate(getFormControl(), this.revisedTimelineList[index]);
      if (!(index === 2 || index === 5)) {
        for (let idx = index + 1; idx < this.tableRows.length; idx++) {
          if (!(idx === 2 || idx === 5) && getFormControl(idx).value.actualDate !== '') {
            break;
          }
          if (!(idx === 2 || idx === 5) && !getFormControl(idx).value.notApplicable) {
            this.updateRevisedDate(
              getFormControl(idx),
              this.revisedTimelineList[idx]);
          }
        }
      }
    }
  }

  private updateRevisedDate(getFormControl: any, revisedDate: Date) {
    getFormControl?.patchValue(
      { revisedDate },
      { emitEvent: false }
    );
  }

  private updateMinDate(getFormControl: any, minDate: Date) {
    getFormControl?.patchValue(
      { minDate },
      { emitEvent: false }
    );
  }

  private isTimeLineSavedOrNew(timelines: TimeLine[]): boolean {
    let notInitialCall = false;

    if (timelines[0].date !== null && timelines[0].date !== '' || this.isReviewAudit) {
      const savedDate = moment(new Date(timelines[0].date as string));
      notInitialCall = true;
      this.timeLineListSaved = timelines;
      this.timeLineReview = clone(timelines);
      if(this.timeLineReview[2].date === '') {
        this.isAuditTypePricing = true;
      }
      timelines.forEach((timeline) => {
        if (timeline?.date && timeline.date !== '') {
          timeline.date = new Date(timeline.date as string);
        }
      });
    }

    timelines.forEach((timeline, index) => {
      for (const key in TIMELINES) {
        if (TIMELINES[key] === timeline.title) {
          this.enabledIndexes.push(index);
        }
      }
    });

    return notInitialCall;
  }

  private createTimeLineFormControls(timelines: TimeLine[]) {
    timelines.forEach((timeLine, index, timeLines) => {
      const auditPricing = this.isAuditTypePricing && index === 2;
      if (index === 2) {
        this.gerTimeLime = timeLine;
      }

      const timeLineForm = {
        auditId: new FormControl({value: timeLine.auditId, disabled: false}),
        title: new FormControl({value: timeLine.title, disabled: false}),
        date: auditPricing || timeLine.notApplicable ? new FormControl({value: undefined, disabled: false}) :
          new FormControl({value: timeLine.date, disabled: false}, [Validators.required]),
        notApplicable: new FormControl({
          value: timeLine.notApplicable,
          disabled: false
        }),
        duration: new FormControl({
          value: auditPricing || timeLine.notApplicable ? 0 : timeLine.duration,
          disabled: false
        }),
        responsibility: new FormControl({value: timeLine.responsibility, disabled: false}),
        notesExists: new FormControl({value: timeLine.notesExists, disabled: false}),
        timelineDetailId: new FormControl({value: timeLine.timelineDetailId, disabled: false}),
        timelineId: new FormControl({value: timeLine.timelineId, disabled: false}),
        minDate: new FormControl({value: this.getMinDateForForm(index, timeLines), disabled: false}),
      } as unknown as FormGroup;

      const actualTimeFormControls = {
        actualDate: new FormControl({
          value: timeLine.actualDate !== '' ? new Date(timeLine.actualDate as string) : timeLine.actualDate,
          disabled: false
        }),
        revisedDate: new FormControl({
          value: timeLine.revisedDate !== '' ? new Date(timeLine.revisedDate as string) : timeLine.date,
          disabled: false
        })
      };

      this.tableRows.push(this.fb.group({...timeLineForm, ...actualTimeFormControls}), {emitEvent: false});
      this.savedArray.push({
        actualDate: actualTimeFormControls.actualDate.value ?? '',
        revisedDate: actualTimeFormControls.revisedDate.value ?? '',
        // notes: timeLine.notes,
        notesExist: timeLine.notesExists
      });
    });
  }

  private getMinDateForForm(index: number, timeLines: TimeLine[]) {
    if (index === 2 && this.isAuditTypePricing) {
      return moment(timeLines[1].date).add(1, 'days').toDate();
    } else if (this.isReviewAudit && index === 0) {
      return new Date();
    } else {
      return this.calculateMinDate(index);
    }
  }

  private calculateMinDate(index: number) {
    if (index > 0) {
      let tempDate;
      const minDaysToAdd = (this.isAuditTypePricing && index === 2) || this.tableRows.at(index)?.get('notApplicable').value
      || (!this.tableRows.at(index)?.get('notApplicable').value && this.tableRows.at(index)?.get('duration').value) ? 0 : 1;
      if (this.tableRows.at(index - 1)?.get('date').value instanceof Date) {
        tempDate = addBusinessDays(new Date(this.tableRows.at(index - 1)?.get('date').value), minDaysToAdd);
      } else {
        tempDate = addBusinessDays(new Date(this.tableRows.at(index - 1)?.get('minDate').value), minDaysToAdd);
      }
      return this.tableRows.at(index)?.get('notApplicable').value ?
        tempDate : addBusinessDays(tempDate, this.tableRows.at(index)?.get('duration').value);
    } else {
      return new Date();
    }
  }

  saveAuditTimeLines() {
    let hasError = false;
    if (!this.auditTimelineForm.valid) {
      this.auditTimelineForm.markAllAsTouched();
    } else {
      for (let i = 0; i < this.tableRows.length; i++) {
        const tl = this.auditTimelineForm.get(['tableRows', i]) as FormGroup;
        if (tl.get('actualDate').hasError('matDatepickerParse') &&
          tl.get('actualDate').value === null) {
          hasError = true;
        }
      }
      if (!hasError) {
        let timeLines: Array<TimeLine> = this.auditTimelineForm.value.tableRows;
        if (this.isAuditTypePricing) {
          timeLines[2].date = '';
        }
        timeLines = timeLines.map( (timeline: TimeLine) => {
          timeline.date = timeline?.date ? this.datePipe.transform((timeline?.date as Date), 'MM/dd/yyyy') : '';
          timeline.revisedDate = timeline?.revisedDate ? this.datePipe.transform((timeline?.revisedDate as Date), 'MM/dd/yyyy') : '';
          timeline.actualDate = timeline?.actualDate ? this.datePipe.transform((timeline?.actualDate as Date), 'MM/dd/yyyy') : '';
          return timeline;
        });
        this.timeLineService.saveUpdateAuditTimelineRequest(timeLines, 'ACTUAL_TIMELINE')
          .subscribe({
            next: (response) => {
              this.timeLineListSaved = timeLines;
              this.savedArray = [];
              this.revisedTimelineList = [];
              timeLines.forEach(timeline => {
                this.savedArray.push({
                  actualDate: timeline.actualDate !== '' ? moment(timeline.actualDate).toDate() : '',
                  revisedDate: timeline.revisedDate ?? '',
                });
                this.revisedTimelineList.push(moment(timeline.revisedDate).toDate());
              });
              this.showSuccessNotification(
                'Actual Timelines Saved',
                'Actual Timelines Saved successfully',
                '#audit-timeline-alert-div',
                15000);
            },
            error: (error) => {
              this.showErrorNotification(
                'Please try again',
                'Unable to establish server connection',
                '#audit-timeline-alert-div',
                20000);
            }
          });
      }
    }
  }

  cancelAuditTimeLines() {
    this.bs.close();
    const isActualTimeLineInitialCall: boolean = this.timeLineListSaved[0]?.revisedDate === '';
    if (this.timeLineListSaved.length > 0) {
      this.timeLineListSaved.forEach((timeLine, index) => {
        if (isActualTimeLineInitialCall) {
          this.tableRows.at(index).get('actualDate').setValue('', {emitEvent: false});
          this.tableRows.at(index).get('revisedDate').setValue(timeLine.date, {emitEvent: false});
        } else if (!isActualTimeLineInitialCall) {
          this.tableRows.at(index).get('duration').setValue(
            this.isAuditTypePricing && index === 2 || timeLine.notApplicable ? 0 : timeLine.duration,
            {emitEvent: false}
          );
          this.savedArray.forEach((val, index) => {
            this.tableRows.at(index).get('actualDate').setValue(val.actualDate, {emitEvent: false});
            this.tableRows.at(index).get('revisedDate').setValue(val.revisedDate, {emitEvent: false});
          });
        }
      });
      this.timeLineDataSource = new MatTableDataSource(this.tableRows.controls);
    }
  }

  notesModal(element: any, action: string): void {
    if (!this.enableNotes) {
      this.enableNotes = true;
      return;
    }
    const dialogData: NotesManagementModel = {
      auditId: element.value.auditId,
      notesType: NotesType.AUDIT_TIMELINE,
      category: NotesCategory.ACTUAL_TIMELINE,
      title: element?.value?.title,
      timelineDetails: element.value,
      isActualTimeline: true,
      modalType: action,
      isNotesExists: element.value.notesExists,
    };
    this.matDialog.open(NotesManagementComponent,
      {
        disableClose: true,
        data: dialogData
      }).afterClosed().subscribe((result: any) => {
      if(result?.isNotesExist){
        element.value.notesExists = true;
        this.tableRows.controls?.forEach( control => {
          if(control?.get('timelineId')?.value === result?.data?.timelineId) {
            control?.get('timelineDetailId')?.setValue(result?.data?.timelineDetailId);
            control?.get('notesExists')?.setValue(true);
          }
        });
        this.cdr.detectChanges();
      }
    });
  }

  timelineActualDate(tl: FormGroup) {
    return (tl.get('actualDate').hasError('matDatepickerParse') &&
      tl.get('actualDate').value === null);
  }

  getAuditRequestDetails() {
    if(this.amsAuditRecordID) {
      this.auditInfoService.getAuditRequest(this.amsAuditRecordID).subscribe({
        next: (auditRequest: AuditRequest) => {
          this.auditDetail = auditRequest;
          this.clientOrCoalitionLabelName = AuditRequestUtil.getClientOrCoalitionLabel(this.auditDetail?.auditInformation?.coalition);
          this.clientName = AuditRequestUtil.getClientOrAccountName(this.auditDetail);
          this.originalClientName = this.auditDetail?.auditInformation?.originalClientName;
        },
        error: (err: any) => {
          if (err?.error?.code === '403') {
            this.auditInfoService.backClicked();
          }
        }
      });
    }
  }
}
