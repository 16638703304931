export const formValidationMessages = Object.freeze({
  addlInfoDataTitle: {
    required: 'Title is required when custom data requests are made!',
    unique: 'Information Data Title is not unique, please select another title and save!',
    maxlength: 'Maximum characters allowed is 50'
  },
  addlInfoDataNotes : {
    required: 'Notes are required when custom data requests are made!',
    maxlength: 'Maximum characters allowed is 300'
}

});

export const hintMessage = Object.freeze({
  customInformation: 'Enter a short title for the data you request that is not available as a selection on this page,' +
    ' provide additional details related to the data you request in the note section, and press Save.',
  pageHintLine: 'Please select the audit types that will be covered under this audit.  Claim Audit Information Request offerings ' +
    'will be populated below based on your audit type selections.  The Claim Audit Information Request Offerings displays the type of ' +
    'information that will be available to you as part of the audit deliverables.  After a successful save, you can proceed to the ' +
    'next step by selecting Audit Contacts in the progress menu on the left side of the page. ',
  reviewHint: 'The primary auditor has selected the following audit types and information request offerings. If you have concerns about' +
    ' this request, you can make the needed changes or return this request to the requester. Please review this page completely and select'+
    ' Audit Contacts in the progress menu to move to the next step in the Audit Review.',
  customInfoSectionHint: 'If any Claim Audit Information Request offerings are not listed in the Claim Audit Information Request' +
    '              section after all of your claim audit types have been selected, then select the button "Custom Request" to' +
    '              identify any custom data requests for your audit.',
  auditRoleExist: 'You have already added role(s) specific to the audit types you are removing. If you wish to proceed with ' +
    'this change, the audit roles associated to the audit type(s) you have removed will be deleted on your behalf. Press ' +
    'Yes to continue or Cancel!'
});
export const banner = Object.freeze({
  alertDiv: '#audit-type-alert-div'
});

export const successMessage = Object.freeze({
  saveTitle: 'Successful Save',
  save: 'Your Audit Types information was successfully saved!',
  saveWithRoleExist: 'You have added new Audit Types and you will need to navigate ' +
    'to Audit Roles to select the Information Requests needed for the newly added Audit Types.'
});

export const errorMessage = Object.freeze({
  title: 'Unsuccessful save',
  auditType: 'Please select at least one of the offering in claim audit types',
  save: 'You have failed to save audit types'
});
export const offeringsId = Object.freeze({
  pricing: 1,
  benefits: 2,
  rebateGuarantee: 3,
  operational: 4,
  security: 5,
  accumulator: 6,
  actualAcquisitionCost: 7,
  performanceGuarantee: 8,
  preImplementation: 9,
  postImplementation: 10,
  erisa5500: 11
});

export const modes = Object.freeze({
  request: 'request',
  edit: 'edit',
  read: 'read'
});

export  const label = Object.freeze({
  coalitionNameLabel: 'Coalition Name',
  clientNameLabel: 'Client Name'
});
