import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {MatStepperModule} from '@angular/material/stepper';
import {MatIconModule} from '@angular/material/icon';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTableModule} from '@angular/material/table';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatSortModule} from '@angular/material/sort';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatOptionModule} from '@angular/material/core';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatPaginatorModule} from '@angular/material/paginator';
import {
  CVSAutocompleteSelectModule, CVSBannerModule, CVSConfirmationDialogModule,
  CVSDateRangeModule,
  CVSToggleSwitchModule,
  PDSSpinnerModule
} from 'angular-component-library';
import {CoreModule} from '@core/core';
import {ExternalRoutingModule} from './external-routing.module';
import {ExternalDashboardComponent} from './external-dashboard/external-dashboard.component';
import {AuditRequestModule} from '../audit-request/audit-request.module';
import {ExternalAuditRequestComponent} from './external-audit-request/external-audit-request.component';
import {ExternalDashboardListComponent} from './external-dashboard/dashboard-list/dashboard-list.component';
import {MatInputModule} from '@angular/material/input';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {ExternalAuditViewComponent} from './external-audit-view/external-audit-view.component';
import {MatRadioModule} from '@angular/material/radio';
import {SharedModule} from '../shared/shared.module';


@NgModule({
  declarations: [
    ExternalDashboardComponent,
    ExternalAuditRequestComponent,
    ExternalDashboardListComponent,
    ExternalAuditViewComponent
  ],
    exports: [
        ExternalDashboardListComponent,
        ExternalAuditRequestComponent
    ],
    imports: [
        CommonModule,
        CoreModule,
        ExternalRoutingModule,
        AuditRequestModule,
        ReactiveFormsModule,
        MatStepperModule,
        MatSortModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatTabsModule,
        MatTableModule,
        MatFormFieldModule,
        MatSelectModule,
        MatCheckboxModule,
        MatOptionModule,
        MatButtonModule,
        MatPaginatorModule,
        CVSAutocompleteSelectModule,
        CVSDateRangeModule,
        CVSToggleSwitchModule,
        PDSSpinnerModule,
        CVSBannerModule,
        CVSConfirmationDialogModule,
        ClipboardModule,
        MatRadioModule,
        SharedModule,
    ]
})
export class ExternalModule { }
