import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';

const routes: Routes = [
  { path: 'navigate-to-external', redirectTo: 'external', pathMatch: 'full' },
  { path: 'navigate-to-internal', redirectTo: 'internal', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS,
    useValue: { displayDefaultIndicatorType: false }
  }]
})
export class AuditRequestRoutingModule { }
