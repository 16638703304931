export const formValidationMessages = Object.freeze({
  coalition: {
    required: 'Coalition or Single Client must be selected!',
  },
  originalClientName: {
    required: 'Client Name is required',
    maxlength: 'Client Name cannot be more than 255 chars',
  },
  originalCoalitionName: {
    required: 'Coalition Name is required',
    maxlength: 'Coalition Name cannot be more than 255 chars',
  },
  clientName: {
    required: 'Client Name is required',
    maxlength: 'Client Name cannot be more than 255 chars',
  },
  auditLobOneId: {
    required: 'LOB1 and LOB2 are required!',
    valueNotInList: 'LOB 1 option selected not in the list',
  },
  auditLobTwoList: {
    required: 'LOB1 and LOB2 are required!',
    valueNotInList: 'LOB 2 option selected not in the list',
  },
  auditScopeStartDate: {
    required: 'Audit Scope Start Date is required and must be in the past!',
    dateValidator: 'Audit Scope Start Date must be valid date',
    dateLessThanToday: 'Audit Scope Start Date must be in the past!',
    matDatepickerMax: 'Audit Scope Start Date must be in the past!',
    alpha: 'Audit Scope Start Date will accept date format only'
  },
  auditScopeEndDate: {
    required: 'Audit Scope End Date is required and must be in the past!',
    dateValidator: 'Audit Scope End Date must be valid date',
    dateLessThanToday: 'Audit Scope End Date must be in the past!',
    matDatepickerMax: 'Audit Scope End Date must be in the past!',
    alpha: 'Audit Scope End Date will accept date format only',
    dateLessThan: 'Audit Scope Start Date should be less than Audit Scope End Date'
  },
  reviewDropDown: {
    required: 'Please select the Audit Review Details',
    valueNotInList: 'Request option selected not in the list',
  }
});

export const errorMessages = Object.freeze({
    validationTitle: 'Error Validation for Audit Information'
});

export const successMessages = Object.freeze({
  successTitle: 'Successful Save',
  add: 'Your Audit Information was successfully saved!',
  subcontractorAuditNote: 'Note: You will not be able to adjust your response to creating this audit for a primary firm ' +
    'or your primary firm selection once contacts are entered!'
});

export const auditInfoHint = Object.freeze({
  pageHintLine: 'Please complete all required fields and press save to be able to move to the next step of your audit request. Once' +
    ' you have successfully saved this page you can select Audit Types and Information Request from the progress  menu on the left ' +
    'side of the page.',
  reviewPageHint: 'This audit request has been completed by the primary auditor for the client. Please review this page completely and ' +
    'select the correct client or coalition name and save to move to the next step in the Audit Review. After a successful save of your ' +
    'information, you can proceed to the next step of your Audit Review by selecting Audit Types & Information Request on the progress ' +
    'menu on the left side of the page. ',
  scopeDateHint: 'Audit Scope Start Date and Audit Scope End Date must be in the past and the Audit Scope End Date cannot be before ' +
    'the Audit Scope Start Date.'
});

export const dateFormat = Object.freeze({
  mm_dd_yyyy: 'MM/DD/YYYY',
  mm_dd_yy: 'MM/DD/YY',
  m_d_yyyy: 'M/D/YYYY',
  dateTime: 'MM/dd/yyyy hh:mm a',
  cst:'CST'
});

export const label = Object.freeze({
  coalitionNameLabel: 'Coalition Name',
  clientNameLabel: 'Client Name',
  auditFirm: 'Audit Firm',
  auditClient: 'Audit Client',
  multipleCarrierLabel: 'Multiple Carrier Client Name',
  platformID: 'Platform ID'
});

export const auditInitiationType = Object.freeze({
  firm: 'Firm',
  client: 'Client'
});

export const modes = Object.freeze({
  request: 'request',
  edit: 'edit',
  read: 'read',
  review: 'review'
});

export const commonRequest = Object.freeze({
  coalition: 'coalition',
  single: 'single',
  multiple: 'multiple'
});


