import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {ExternalDashboardComponent} from './external-dashboard/external-dashboard.component';
import {ExternalAuditRequestComponent} from './external-audit-request/external-audit-request.component';
import {AppEnvResolverService} from '../app.env.resolver';
import {ExternalAuditViewComponent} from './external-audit-view/external-audit-view.component';
import {AuthGuard} from '../guard/auth.guard';

const routes: Routes = [
  { path: '',
    component: ExternalDashboardComponent,
    canActivate: [AuthGuard],
    data: {role: 'external-creator'}
  },
  {
    path: 'audit-request/:type',
    component: ExternalAuditRequestComponent,
    resolve: { envData: AppEnvResolverService },
    canActivate: [AuthGuard],
    data: {role: 'external-creator'}
  },
  {
    path: 'audit-request-view/:tabStatus',
    component: ExternalAuditViewComponent,
    resolve: { envData: AppEnvResolverService },
    canActivate: [AuthGuard],
    data: {role: 'external-creator'}
  },
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExternalRoutingModule { }
