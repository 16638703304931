import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {CVSConfirmationDialogContentComponent} from 'angular-component-library';
import {take} from 'rxjs';
import {DocumentMetaData} from 'src/app/models/document-meta-data.model';
import {DocumentService} from 'src/app/services/document.service';
import {saveAs} from 'file-saver';
import {BannerService} from 'src/app/shared/services/banner.service';
import {UserService} from 'src/app/services/user.service';
import {BaseComponent} from '../../shared/components/base/base.component';
import {DocumentCategories} from '../../enums/document-category-enums';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FileShareObject} from '../../models/file-share-model';
import {NotesCategory} from '../../shared/components/notes-management/notes-management.constants';

@Component({
  selector: 'app-document-management-table',
  templateUrl: './document-management-table.component.html',
  styleUrls: ['./document-management-table.component.scss'],
})
export class DocumentManagementTableComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() header!: string;
  @Input() uploadDocId!: number;
  @Input() detailAddlInfoRequestId!: number;
  @Input() infoRequestId!: number;
  @Input() detailAuditorGroupId: number;
  @Input() auditRecordId: string;
  @Input() generalDocId!: string;
  @Input() files: DocumentMetaData[] = [];
  @Input() amRecordId!: string;
  @Input() auditId!: number;
  @Input() auditRequestMode!: string;
  @Input() outletId!: string;
  @Input() claimAuditInfoSection!: boolean;
  @Input() firmContactDropdownList!: any[];
  @Input() fileSharesList!: any[];
  @Input() ndaVerificationMap!: any;
  @Input() sectionNotesExists: any;
  @Output() addViewNotes: EventEmitter<string> = new EventEmitter<string>();
  @Output() uploadedFileGroup = new EventEmitter<{ docGroup: number; isUploaded: boolean }>();
  @Output() getFileSharesView: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild('shareWithTemplateRef') shareWithTemplateRef!: TemplateRef<any>;

  dataSource = new MatTableDataSource<DocumentMetaData>(this.files);
  columns: string[];
  confirmationDialog: any;
  inputIdString;
  selectedOptions = new FormControl();
  oldSelection: number[];
  detailFileId: any;
  shareWithForm = new FormGroup({
    shareWithList: new FormControl<string[]>({value: [], disabled: false}, [Validators.required]),
  });
  showLoader = true;
  createdBy = '';

  constructor(
    private matDialog: MatDialog,
    private documentService: DocumentService,
    private bs: BannerService,
    public userService: UserService
  ) {
    super(bs);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.files?.currentValue) {
      this.manageUploadedFiles();
      this.uploadedFileGroup.emit({ docGroup: this.uploadDocId, isUploaded: this.dataSource.data.length > 0 });
    }
  }

  manageUploadedFiles() {
    this.dataSource.data.splice(0);
    this.files?.forEach(fileUpload => {
      if (fileUpload.uploadDocId === this.uploadDocId) {
        this.dataSource.data.push(fileUpload);
      }
    });
    this.sortDataItems();
  }

  ngOnInit(): void {
    this.removeNoFileRowForReadOnlyUser();
    this.dataSource.sort = this.sort;
    this.inputIdString = 'select-file-' + this.header?.replace(/[^A-Z0-9]+/ig, '-');
    this.columns = (!this.claimAuditInfoSection && (this.userService.isAuditAdmin() || this.userService.isAuditAnalyst())) ?
      ['fileName', 'status', 'shareWith', 'uploadDate', 'uploadedBy', 'action'] :
      ['fileName', 'status', 'uploadDate', 'uploadedBy', 'action'];
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];

    if(!file) {
      return;
    }

    this.bs.close();
    const formData = new FormData();
    formData.append('fileName', file.name);
    formData.append('amsAuditRecordId', `${this.amRecordId}`);
    formData.append('fileType', file.name.split('.').pop());
    formData.append('fileStatus', 'File Uploaded');
    formData.append('uploadedUserEmail', this.userService.getUserEmail());
    formData.append('category', `${this.header}`);
    formData.append('auditId', `${this.auditId}`);
    this.uploadDocId ? formData.append('uploadDocId', `${this.uploadDocId}`) : ()=> {};
    this.infoRequestId ? formData.append('infoRequestId', `${this.infoRequestId}`) : ()=> {};
    this.detailAuditorGroupId ? formData.append('detailAuditorGroupId', `${this.detailAuditorGroupId}`) : ()=> {};
    this.detailAddlInfoRequestId ? formData.append('detailAddlInfoRequestId', `${this.detailAddlInfoRequestId}`) : ()=> {};
    this.generalDocId ? formData.append('generalDocId', `${this.generalDocId}`) : ()=> {};
    formData.append('uploadFile', file);
    // TODO replacing with auditUserGroupId
    formData.append('currentUserFirm', '');

    if (file.size > 300 * 1024 * 1024) {
      this.showErrorNotification(
        'File Size Limit of 300MB exceeded',
        'Audit File Not Uploaded',
        this.outletId
      );
      return;
    }

    this.documentService.uploadFile(formData).pipe(take(1)).subscribe({
      next: savedFile => {
        this.files.push(savedFile);
        this.manageUploadedFiles();
        this.sortDataItems();
        this.uploadedFileGroup.emit({ docGroup: savedFile.uploadDocId, isUploaded: this.dataSource.data.length > 0 });
        this.dataSource._updateChangeSubscription();
      },
      error: (error) => {
        if (error.error.code === '409') {
          this.showErrorNotification(
            'The filename you have chosen is not unique for this audit, please rename your file and upload again.',
            'Error',
            this.outletId
          );
        } else {
          this.showErrorNotification(
            'Please try again',
            'Audit File Not Uploaded',
            this.outletId
          );
        }
      }
    });
  }

  sortDataItems() {
    if (this.header === DocumentCategories.SCOPE || this.header === DocumentCategories.CLIENT) {
      this.dataSource.data.sort((item1, item2) => item1.fileName.localeCompare(item2.fileName));
      this.dataSource.sort?.sortChange.emit();
    }
  }

  uploadFile() {
    const input: HTMLInputElement = document.querySelector('#' + this.inputIdString);

    input.value = '';
    input.click();
  }

  deleteFile(file: DocumentMetaData) {
    this.detailFileId = file.detailFileId;
    this.bs.close();
    const index = this.files.indexOf(file);
    this.confirmationDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
      disableClose: true,
      data: {
        headline: 'Delete',
        body: 'You have selected to delete file "' + file.fileName + '"' + '<br><br>' + 'Do you wish to proceed with file deletion?',
        actionLabel: 'Yes',
        cancelLabel: 'Cancel',
      }
    });

    this.confirmationDialog.componentInstance.onConfirmClick.pipe(take(1)).subscribe(() => {
      this.documentService.deleteAllByDetailFileId(this.detailFileId)?.pipe(take(1)).subscribe( _ => {
        this.documentService.deleteDocument({
          fileId: file.detailFileId,
          dmsId: file.fileDmsId,
          emailAddress: this.userService.getUserEmail(),
          // TODO replacing with auditUserGroupId
          currentUserFirm: '',
          fileStatus: 'File Deleted'
        })?.pipe(take(1)).subscribe(_ => {
          if (index !== -1) {
            this.files.splice(index, 1);
            this.manageUploadedFiles();
            this.dataSource._updateChangeSubscription();
          }
          this.uploadedFileGroup.emit({ docGroup: +file.uploadDocId, isUploaded: this.dataSource.data.length > 0 });
          this.confirmationDialog.close();
        }, (error) => {
          this.matDialog.closeAll();
          this.showErrorNotification(
            'Error adding share with contacts.',
            'Error',
            '#audit-documents-alert-div',
            20000);
        });
      }, (error) => {
        this.confirmationDialog.close();
        this.matDialog.closeAll();
        this.showErrorNotification(
          'Error deleting file share contacts.',
          'Error',
          '#audit-documents-alert-div',
          20000);
      });
    });

    this.confirmationDialog.componentInstance.onCancelClick.pipe(take(1)).subscribe(() => {
      this.confirmationDialog.close();
    });
  }

  downloadFile(file: DocumentMetaData) {
    this.bs.close();
    this.documentService.downloadDocument({
      fileDmsId: file.fileDmsId,
      requestUserId: this.userService.getUserEmail(),
      currentUserEmail: this.userService.getUserEmail(),
      // TODO replacing with auditUserGroupId
      currentUserFirm: '',
      fileStatus: 'File Retrieved'
    }).subscribe(res => {
      saveAs(res, file.fileName);
    });
  }

  removeNoFileRowForReadOnlyUser() {
    if (this.auditRequestMode === 'read') {
      this.dataSource.data = this.dataSource.data.filter(document => document.detailFileId !== '-1');
    }
  }

  get isShowApproveFile() {
    return !(this.header === 'Samples' || this.header === 'Report'
      || this.header === 'Audit Closure' || this.header === 'Non-disclosure Agreement');
  }

  protected readonly sessionStorage = sessionStorage;
  protected readonly onclick = onclick;
  @Input() scetionNotesExists!: any;

  /**
   * Function to open Share File modal.
   * Opens modal and makes document service call for fileShareDetails.
   * On success, converts the response to dropdownSelections and sets them in the dropdown.
   * On failure, closes modal and shows error banner.
   *
   * @param templateRef - ng-container templateRef in HTML
   * @param file - file metadata from the row we selected the button in
   */
  openShareFileModal(templateRef: TemplateRef<any>, file: DocumentMetaData) {
    this.detailFileId = file.detailFileId;
    this.createdBy = file.createdBy;
    this.showLoader = false;
    this.matDialog.open(templateRef, {
      disableClose: true
    });
    const foundFileShare = this.fileSharesList.find(fileShare => fileShare.detailFileId === this.detailFileId);
    const selections = this.convertFileSharesViewToDropdownSelections(foundFileShare);
    this.selectedOptions.setValue(selections);
    this.oldSelection = this.selectedOptions.getRawValue();
  }

  /**
   * Dropdown Multi-Selector selection event listener.
   * Determines if the change is removing or adding a selection.
   * Note: This event listener will trigger after a checkbox is selected,
   * so the change will ALWAYS be 1 addition/subtraction.
   *
   * @param event - event object passed through directive
   */
  changedSelection(event) {
    if(this.oldSelection.length < event.value.length) {
      this.addFirmContactSelections(event.value);
    }
    else {
      this.removeFirmContactSelections(event.value);
    }
  }

  /**
   * Sets the selectedOptions for the add event.
   * If a firm gets selected, we select all contacts under the firm.
   * If a contact gets selected, we check the firm and all the contacts under the firm
   * to determine if we select the firm or not.
   *
   * @param selectedContacts - number array of selections passed from event listener
   */
  addFirmContactSelections(selectedContacts: number[]) {
    let newAddition;
    for(let i = 0; i < selectedContacts.length; i++) {
      if(!this.oldSelection.includes(selectedContacts[i])) {
        newAddition = selectedContacts[i];
      }
    }
    const newSelections = selectedContacts;
    if (this.firmContactDropdownList[newAddition].firm) { // Firm Selected
      for (let cntctIter = newAddition + 1; cntctIter < this.firmContactDropdownList.length; cntctIter++) {
        if (this.firmContactDropdownList[cntctIter].firm) {
          break;
        } else {
          if(this.firmContactDropdownList[cntctIter].value.contactEmail !== this.createdBy) { // Ignore selection for createdBy
            this.addNumberIfListDoesNotInclude(cntctIter, newSelections);
          }
        }
      }
    } else { // Contact Selected
      const firmNumber = this.findFirmIdFromContactId(newAddition);
      let setFirmSelection = true;
      for(let cntctIter = firmNumber + 1; cntctIter < this.firmContactDropdownList.length; cntctIter++) {
        if(this.firmContactDropdownList[cntctIter].firm) {
          break;
        }
        if(!newSelections.includes(cntctIter)) {
          setFirmSelection = false;
        }
        if(this.firmContactDropdownList[cntctIter].value.contactEmail === this.createdBy) { // Ignore selection for createdBy
          setFirmSelection = true;
        }
      }
      if(setFirmSelection) { // All Contacts for Firm are selected
        this.addNumberIfListDoesNotInclude(firmNumber, newSelections);
      }
      this.addNumberIfListDoesNotInclude(newAddition, newSelections);
    }
    this.oldSelection = newSelections;
    this.selectedOptions.setValue(newSelections);
  }

  /**
   * Checks the firmContactDropdownList for the firm the contact is under.
   * Returns the id for the firm in the firmContactDropdownList
   *
   * @param contactId - the contact used to find the firm
   */
  findFirmIdFromContactId(contactId: number) {
    let firmListId = 0;
    for(let i = contactId; i > 0; i--) {
      if(this.firmContactDropdownList[i].firm) {
        firmListId = i;
        i = 0;
      }
    }
    return firmListId;
  }

  /**
   * Simple check to see if number array has the number.
   * If number is not in list, add it.
   *
   * @param newAddition - New number being added
   * @param list - Number array we check
   */
  addNumberIfListDoesNotInclude(newAddition: number, list: number[]) {
    if(!list.includes(newAddition)) {
      list.push(newAddition);
    }
  }

  /**
   * Sets the selectedOptions for the remove event.
   * If a firm gets de-selected, we de-select all contacts under the firm.
   * If a contact gets selected, we de-select the firm if it is selected.
   *
   * @param selectedContacts - number array of selections passed from event listener
   */
  removeFirmContactSelections(selectedContacts: number[]) {
    let removeId;
    for(let i = 0; i < this.oldSelection.length; i++) {
      if(!selectedContacts.includes(this.oldSelection[i])) {
        removeId = this.oldSelection[i];
      }
    }
    let newSelections = selectedContacts;
    if (this.firmContactDropdownList[removeId].firm) { // Firm Selected
      for (let cntctIter = removeId + 1; cntctIter < this.firmContactDropdownList.length; cntctIter++) {
        if (this.firmContactDropdownList[cntctIter].firm) {
          break;
        } else {
          newSelections = newSelections.filter(selection => selection !== cntctIter);
        }
      }
    } else { // Contact Selected
      const firmNumber = this.findFirmIdFromContactId(removeId);
      newSelections = newSelections.filter(selection => selection !== firmNumber);
    }
    this.oldSelection = newSelections;
    this.selectedOptions.setValue(newSelections);
  }

  /**
   * Cancel and x-button function for Share File modal.
   */
  cancelAction() {
    this.matDialog.closeAll();
  }

  /**
   * Share button function for Share File modal.
   * On successful document service call, close modal.
   * On failed document service call, close modal and show error banner.
   */
  shareFile() {
    const contactObjects = this.convertSelectionsToFileShareObjects();
    this.showLoader = true;
    this.documentService.addFileShare(this.detailFileId, contactObjects).subscribe((res) => {
      this.matDialog.closeAll();
      this.getFileSharesView.emit();
    }, (error) => {
      this.matDialog.closeAll();
      this.showErrorNotification(
        'Error adding share with contacts.',
        'Error',
        '#audit-documents-alert-div',
        20000);
    });
  }

  /**
   * Function to convert dropdown selectedOptions number array into
   * array of FileShareObjects to pass to the addFileShare document service function.
   * Note: Only passes the contacts, not the firms
   */
  convertSelectionsToFileShareObjects() {
    const selectedContacts = this.selectedOptions.value;
    const fileShareObjects = [];
    selectedContacts.forEach((selectionIndex) => {
      if(!this.firmContactDropdownList[selectionIndex].firm) {
        fileShareObjects.push({
          detailContactId: this.firmContactDropdownList[selectionIndex].value.detailContactId,
          contactEmail: this.firmContactDropdownList[selectionIndex].value.contactEmail
        } as FileShareObject);
      }
    });
    return fileShareObjects;
  }

  /**
   * Converts fileShareView object to dropdown selection ids using the sharedWithEmails
   * property and comparing it to the email in the FirmContactDropdownList. After it sets
   * the ids of the contacts, check the firms to see if we should select them as well.
   *
   * @param fileShareView - Object from the fileShareView for the this.detailFileId value
   */
  convertFileSharesViewToDropdownSelections(fileShareView) {
    const selections = [];
    if(fileShareView !== undefined) {
      fileShareView.sharedWithEmails.forEach(email => {
        const setId = this.firmContactDropdownList.findIndex(contact => contact.value.contactEmail === email);
        selections.push(setId);
      });
    }
    if(selections.length > 0) { // Check if contacts are all selected for their corresponding firms
      selections.forEach(selection => {
        const firmNumber = this.findFirmIdFromContactId(selection);
        let setFirmSelection = true;
        for(let cntctIter = firmNumber + 1; cntctIter < this.firmContactDropdownList.length; cntctIter++) {
          if(this.firmContactDropdownList[cntctIter].firm) {
            break;
          }
          if(!selections.includes(cntctIter)) {
            setFirmSelection = false;
          }
          if(this.firmContactDropdownList[cntctIter].value.contactEmail === this.createdBy) { // Ignore selection for createdBy
            setFirmSelection = true;
          }
        }
        if(setFirmSelection) { // All Contacts for Firm are selected
          this.addNumberIfListDoesNotInclude(firmNumber, selections);
        }
      });
    }
    return selections;
  }

  /**
   * Function to populate the Share With column using the detailFileId and fileSharesList.
   * Returns a string with the number of emails found in the fileShareList for the detailFileId.
   *
   * @param detailFileId - detailFileId from Document metadata object
   */
  setMemberAmount(detailFileId) {
    const foundFileShare = this.fileSharesList.find(fileShare => fileShare.detailFileId === detailFileId);
    if(foundFileShare !== undefined) {
      return foundFileShare.sharedWithEmails.length + (foundFileShare.sharedWithEmails.length > 1 ? ' Members' : ' Member');
    }
    return '0 Members';
  }

  /**
   * Function to set the tooltip text for the Share With column using the detailFileId and fileSharesList.
   * Returns a string that contains all the contacts shared on the document by their names, grouped by firm.
   * Example: 'Firm1\nFirst Last\n\nFirm2\nJohn Doe'
   *
   * @param detailFileId - detailFileId from Document metadata object
   */
  setTooltipText(detailFileId) {
    const foundFileShare = this.fileSharesList.find(fileShare => fileShare.detailFileId === detailFileId);
    if(foundFileShare !== undefined) {
      let memberTooltip = '';
      const usedFirmList = [];
      for(let i = 0; i < foundFileShare.sharedWithEmails.length; i++) {
        if(!usedFirmList.includes(foundFileShare.sharedWithFirmClientNames[i])) {
          const firmClientName = foundFileShare.sharedWithFirmClientNames[i];
          memberTooltip += firmClientName + '\n';
          for(let j = i; j < foundFileShare.sharedWithEmails.length; j++) {
            if(foundFileShare.sharedWithFirmClientNames[j] === firmClientName) {
              memberTooltip += foundFileShare.sharedWithNames[j] + ', ';
            }
          }
          memberTooltip = memberTooltip.substring(0, memberTooltip.length - 2); // Removes ', ' from end of string
          memberTooltip += '\n\n';
          usedFirmList.push(firmClientName);
        }
      }
      memberTooltip = memberTooltip.substring(0, memberTooltip.length - 2); // Removes '\n\n' from end of string
      return memberTooltip;
    }
    return 'Shared with 0 Members';
  }

  /**
   * Disables the firm name selector if the only contact under the firm is the createdBy value
   * from the document metadata. Iterates over the contacts associated to the firm and
   * will return true if we find contactEmail === createdBy,
   * returns false if there is another value after, or if contactEmail !== createdBy
   *
   * @param index - The index of the firm inside the firmContactDropdownList
   */
  disableFirmNameSelector(index) {
    let disable = false;
    for (let cntctIter = index + 1; cntctIter < this.firmContactDropdownList.length; cntctIter++) {
      if (this.firmContactDropdownList[cntctIter].firm) {
        break;
      } else {
        if(this.firmContactDropdownList[cntctIter].value.contactEmail === this.createdBy) {
          disable = true;
        } else { // Re-enable if there is another value
          disable = false;
        }
      }
    }
    return disable;
  }

  /**
   * Uses the ndaVerificationMap to determine if we disable the dropdown selection for
   * the firm/contact. The map contains keys that are either string (firm selector) or number
   * (contact selector).
   *
   * @param contact - the contact metadata for the selector
   */
  checkNdaVerification(contact) {
    if(this.ndaVerificationMap !== undefined) {
      if(contact.firm) {
        return this.ndaVerificationMap.get(contact.value);
      } else if(contact.value.firmId) {
        return this.ndaVerificationMap.get(contact.value.firmId);
      } else if(contact.value.detailClientId) {
        return this.ndaVerificationMap.get(contact.value.detailClientId);
      }
    }
    return false;
  }

  callAddViewNotes(){
    this.addViewNotes.emit(this.header);
  }

  checkThickBorder(header: string) {
    return header === NotesCategory.SAMPLE || header === NotesCategory.REPORT;
  }
}
