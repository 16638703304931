import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AuditContactDetailModel, AuditContactModel} from './audit-contact.model';
import {FirmModel} from '../../models/firm.model';

@Injectable({
  providedIn: 'root'
})
export class AuditContactService {

  backendUrl = environment.backendUrl + '/audit/contact/';
  constructor(private http: HttpClient) {
  }

  updateAuditContactApiCall(contactId: number, contactBody: AuditContactModel) {
    if(!contactId) {
      return this.addAuditContact(contactBody);
    } else {
      return this.updateAuditContact(contactId, contactBody);
    }
  }

  /** Add Audit primary contact or subcontractor contact **/
  addAuditContact(body: AuditContactModel) {
    return this.http.post<AuditContactModel>(this.backendUrl+'addAuditContact', body);
  }

  /** Update Audit primary contact or subcontractor contact **/
  updateAuditContact(contactId: number, body: AuditContactModel) {
    const url = this.backendUrl + 'updateAuditContact/' + contactId;
    return this.http.put<AuditContactModel>(url, body);
  }

  /** Delete Audit primary contact or subcontractor contact **/
  deleteAuditContact(contactId: number) {
    const url = this.backendUrl + 'deleteAuditContact/' + contactId;
    return this.http.delete<any>(url);
  }

  /** Delete Audit primary contact or subcontractor contact **/
  getAuditContact(contactId: number) {
    return this.http.get<AuditContactModel>(this.backendUrl + 'getAuditContact/' + contactId);
  }

  /** get audit contact details based on auditId**/
  getAuditContactDetail(auditId: number) {
    return this.http.get<AuditContactDetailModel>(this.backendUrl + 'getAuditContactDetail/' + auditId);
  }

  /** Update audit contact details based on auditId for main save**/
  updateAuditContactDetail(auditId: number, body: AuditContactDetailModel) {
    const url = this.backendUrl + 'updateAuditContactDetail/' + auditId;
    return this.http.put<AuditContactDetailModel>(url, body);
  }

  getFirmList() {
    return this.http.get<FirmModel[]>(environment.backendUrl + '/audit/reference/activeFirmList');
  }


}
