import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {AppEnvResolverService} from './app.env.resolver';
import {DocumentManagementComponent} from './document-management/document-management.component';
import { ActualTimeLineComponent } from './audit-request/time-line/actual-time-line/actual-time-line.component';
import {AuthGuard} from './guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',

  },
  { path: 'home', component: HomeComponent},
  {
    path: 'external', loadChildren: () => import('./external/external.module').then(m => m.ExternalModule),
    resolve: {envData: AppEnvResolverService},
    canLoad: [AuthGuard]
  },
  {
    path: 'internal', loadChildren: () => import('./internal/audit.module').then(m => m.AuditModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'audit-timeline-form/:amsAuditRecordID/:auditId',
    component: ActualTimeLineComponent,
  },
  {
    path: 'document-management/:auditRecordId/:auditId',
    component: DocumentManagementComponent
  },
  {
    path: 'myPBM',
    component: HomeComponent,
    resolve: {
      url: 'externalUrlRedirectResolver'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
