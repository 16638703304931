import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {DocumentCategory} from '../models/document-category.model';
import {DocumentMetaData} from '../models/document-meta-data.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DocumentDownloadRequestModel} from '../models/document-download-request.model';
import {DocumentRequest} from '../models/document-request-model';
import {DocumentMetadataResponse} from '../models/document-metadata-response.model';
import {AuditOfferingRequest} from '../models/audit-offering-request.model';
import {AuditOfferingResponse} from '../models/audit-offering-response.model';
import {StartUpSaveRequest} from '../models/start-up-file-save-request.model';
import {FileShareObject, FileShareResponse, FileShareViewObject} from '../models/file-share-model';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private httpClient: HttpClient) { }

  getDocumentCategories(auditId: number): Observable<DocumentCategory[]> {
    return this.httpClient.get<DocumentCategory[]>(`${environment.backendUrl}/document/documentCategory/${auditId}`);
  }

  getStartUpFiles(auditId: number): Observable<any> {
    return this.httpClient.get<DocumentMetaData>(`${environment.backendUrl}/audit/information/startUpFiles/${auditId}`);
  }

  getNdaVerificationList(auditId: number): Observable<any> {
    return this.httpClient.get<any>(`${environment.backendUrl}/audit/information/ndaVerification/${auditId}`);
  }

  saveNdaOptions(auditId: number, StartUpSaveRequest: StartUpSaveRequest[]) {
    return this.httpClient.post(`${environment.backendUrl}/audit/information/saveNdaVerification/${auditId}`, StartUpSaveRequest, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    });
  }

  uploadFile(documentUpload: FormData): Observable<DocumentMetaData> {
    return this.httpClient.post<DocumentMetaData>(`${environment.backendUrl}/document/upload`, documentUpload);
  }

  deleteDocument(deletePayload: any) {
    return this.httpClient.delete(`${environment.backendUrl}/document/delete`, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: deletePayload
    });
  }

  downloadDocument(downloadRequest: DocumentDownloadRequestModel): Observable<Blob> {
    return this.httpClient.post(`${environment.backendUrl}/document/download`, downloadRequest,
      {
        responseType: 'blob',
        headers: new HttpHeaders().append('Content-Type', 'application/json')
      });

  }

  getDocumentMetadataForAudit(documentRequest: DocumentRequest): Observable<DocumentMetadataResponse[]> {
    return this.httpClient.post<DocumentMetadataResponse[]>(
      `${environment.backendUrl}/document/getDocuments`,
      documentRequest
    );
  }

  getAuditOfferingsForAudit(auditOfferingRequest: AuditOfferingRequest): Observable<AuditOfferingResponse[]> {
    return this.httpClient.post<AuditOfferingResponse[]>(
      `${environment.backendUrl}/document/getOfferings`,
      auditOfferingRequest
    );
  }

  addFileShare(detailFileId: number, fileShareObjects: FileShareObject[]): Observable<FileShareResponse[]> {
    return this.httpClient.post<FileShareResponse[]>(
      `${environment.backendUrl}/shareFile/createFileShare/${detailFileId}`,
      fileShareObjects
    );
  }

  getFileSharesByAuditId(auditId: number) {
    return this.httpClient.get<FileShareViewObject[]>(`${environment.backendUrl}/shareFile/getFileShare/${auditId}`);
  }

  deleteAllByDetailFileId(detailFileId: number) {
    return this.httpClient.delete(`${environment.backendUrl}/shareFile/deleteFileShare/${detailFileId}`, {responseType: 'text'});
  }

}
