<!--Template for 'View Status Notes' modal-->
<div *ngIf="!notesReceived && notesType === NotesType.AUDIT_STATUS">
  <pds-spinner [loadingText]="'Loading Notes...'" [mode]="'indeterminate'"></pds-spinner>
</div>
<div  id="viewStatusNotesTemplate" *ngIf="notesReceived && notesViewData?.length > 0  && notesType === NotesType.AUDIT_STATUS" [ngClass]="{'minModalWith': notesViewData?.length}">
  <div class="template-title title-bottom-margin">
    <h2 >View Status Notes</h2>
    <button class="pds-icon-only-btn center-close-x" mat-icon-button [mat-dialog-close]="isNotesViewed"
            aria-label="View Audit Status Notes dialog">
      <mat-icon svgIcon="close-btn--s"></mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div  class="template-content" *ngIf="notesViewData?.length > 0">
      <div  class="template-content content-bottom-margin" *ngFor="let noteData of notesViewData;">
        <div *ngIf="(noteData?.keyAction !== 'Submit')">
          <p class="name-color">{{ noteData?.notesCreator }} {{ noteData?.notesCreatedDatetime }}</p>
          <p>
            <ng-container *ngFor="let keyAction of noteData.keyAction">
              <mat-basic-chip *ngIf="!getStatusBoolean(keyAction)" class="pds-status-chip--success">{{getAuditStatus(keyAction)}}</mat-basic-chip>
              <mat-basic-chip *ngIf="getStatusBoolean(keyAction)" class="pds-status-chip--error">{{getAuditStatus(keyAction)}}</mat-basic-chip>
            </ng-container>
          </p>
          <p>{{ noteData?.notes }}</p>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>

<!--Template for 'View Status Notes' modal with empty notes data-->
<div id="emptyNotesTemplate" *ngIf="notesViewData?.length == 0 && notesType === NotesType.AUDIT_STATUS">
  <div class="no-notes-close">
    <button class="pds-icon-only-btn center-close-x" mat-icon-button mat-dialog-close
            aria-label="View Audit Status Notes dialog">
      <mat-icon svgIcon="close-btn--s"></mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="no-notes-content">
      <mat-icon class="large-icon" svgIcon="error--m"></mat-icon>
      <h2 class="empty-bottom-margin">View Status Notes</h2>
      <p>Status note history does not exist for this audit!</p>
    </div>
  </mat-dialog-content>
</div>


<div  id="addViewNotesTemplate" *ngIf="notesType !== NotesType.AUDIT_STATUS">
  <div class="template-title title-bottom-margin">
    <h2>{{notesModalData?.modalType === 'read' ? 'View ' : 'Add '}}Notes</h2>
    <button class="pds-icon-only-btn center-close-x" mat-icon-button [mat-dialog-close]="{ data: timelineDetails, isNotesExist: notesViewData?.length > 0}"
            [aria-label]="(notesModalData?.modalType === 'read' ? 'View ' : 'Add ') + 'Notes dialog'">
      <mat-icon svgIcon="close-btn--s"></mat-icon>
    </button>
  </div>
  <p class="notes-p-spacing" *ngIf="notesType === NotesType.AUDIT_TIMELINE">{{ notesModalData?.title }}</p>
  <p class="notes-p-spacing" *ngIf="notesType !== NotesType.AUDIT_TIMELINE">{{ notesModalData?.category }}</p>
  <div class="add-note-content-spacing" *ngIf="notesModalData?.modalType !== 'read'">
      <mat-dialog-content>
        <form [formGroup]="notesForm">
          <div *ngIf="notesModalData?.category === NotesCategory.INITIAL_DELIVERABLES" class="pt-10">
            <mat-form-field appearance="outline" class="cvs-compact-form-field w-18-rem" id="multiple-select-all-a11y">
              <mat-label>Select Tag category and enter notes</mat-label>
              <mat-select multiple formControlName="notesTag">
                <div>
                  <mat-checkbox [checked]="notesForm.get('notesTag')?.value?.length === notesModalData?.tagDropdownList?.length"
                                class="tag-select-all" [indeterminate]="isIndeterminate()"
                                (change)="tagAllSelection($event)" (keydown.enter)="tagAllSelection($event)">Select All</mat-checkbox>
                  <mat-option class="tag-option" *ngFor="let option of notesModalData?.tagDropdownList" [value]="option">
                    {{option?.value}}
                  </mat-option>
                </div>
              </mat-select>
            </mat-form-field>
          </div>
          <mat-form-field appearance="outline" class="notes-section">
            <mat-label>Notes</mat-label>
            <textarea maxlength="500" matInput id="notesTextArea"
                      formControlName="notes" placeholder="Enter Notes"></textarea>
            <mat-error *ngIf="notesForm.controls.notes.invalid && (notesForm.controls.notes.dirty || notesForm.controls.notes.touched)">
              Notes are required!
            </mat-error>
            <mat-hint>{{ 500 - descriptionLength}} Characters left</mat-hint>
          </mat-form-field>
        </form>
      </mat-dialog-content>

    <!--Action Buttons-->
      <mat-dialog-actions class="btn-position">
          <button class="pds-btn-white" [mat-dialog-close]="{ data: timelineDetails, isNotesExist: notesViewData?.length > 0}">Cancel</button>
          <button type="submit" class="pds-btn-primary" [disabled]="notesForm?.invalid" (click)="addNotes()">Add</button>
      </mat-dialog-actions>
  </div>
  <div>
      <div id="bannerDiv" [ngClass]="{'banner-spacing':notesModalData?.modalType !== 'read'}"></div>
  </div>
  <mat-dialog-content class="notes-content">
    <div *ngIf="!notesReceived && notesModalData?.modalType !== 'add'">
      <pds-spinner [loadingText]="'Loading Notes...'" [mode]="'indeterminate'"></pds-spinner>
    </div>
    <div class="template-content" *ngIf="notesReceived && (notesModalData?.isNotesExists || notesViewData?.length > 0)">
      <div class="template-content content-bottom-margin" *ngFor="let noteData of notesViewData;">
        <div>
          <p class="name-color">{{ getNotesCreator(noteData?.notesCreatorEmail) }} {{ noteData?.notesCreator }}&nbsp;&nbsp;{{ noteData?.notesCreatedDatetime }}
            <ng-container *ngFor="let keyAction of noteData?.keyAction">
              <mat-basic-chip class="pds-status-chip mr-10">{{ keyAction }}</mat-basic-chip>
            </ng-container>
          </p>
          <span>{{ noteData.notes }}</span>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>

