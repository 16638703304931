export class Constants {
  public static ACTIVE_TAB = 'active';
  public static DRAFT_TAB = 'draft';

  public static ACTIVE_STATUS = [
    { id: 'In Progress', value: 'In Progress' },
    { id: 'On Hold', value: 'On Hold' },
    { id: 'Staging', value: 'Staging' },
    { id: 'Pending Client Approval', value: 'Pending Client Approval' },
    { id: 'Cancelled', value: 'Cancelled' },
    { id: 'Closed', value: 'Closed' },
  ];
  public static DRAFT_STATUS = [{ id: 'Open', value: 'Open' }];
  public static TYPE_OF_AUDIT = [{ id: 'Claim Audit', value: 'Claim Audit' }];

  public static CREATED_DATE = 'createdDate';
  public static NOTIFICATION_DATE = 'notificationDate';
  public static AUDIT_SCOPE_START_DATE = 'auditScopeStartDate';
  public static AUDIT_SCOPE_END_DATE = 'auditScopeEndDate';
  public static AMS_AUDIT_RECORD_ID = 'amsAuditRecordId';
  public static AUDIT_STATUS = 'auditStatus';
  public static AUDIT_TYPE = 'auditType';
  public static CLIENT_NAME = 'clientName';
  public static ASSIGNED_MEMBER_AUDITOR = 'assignedMemberAuditor';
  public static AUDIT_SUB_CONTRACTOR_1 = 'auditSubContractor1';
  public static AUDIT_SUB_CONTRACTOR_2 = 'auditSubContractor2';
  public static ASSIGNED_MEMBER_CVS = 'assignedMemberCvs';
  public static CLIENT_AUDIT_LOB_1 = 'clientAuditLob1';
  public static CLIENT_AUDIT_LOB_2 = 'clientAuditLob2';
  public static STATUS_IN_PROGRESS = 'In Progress';
  public static IN_PROGRESS = 'In Progress';
  public static DATE_FORMAT = 'MM/dd/yyyy';
  public static INPUT_DROPDOWN = 'DROP_DOWN';

  public static REVIEW = 'review';

  public static REQUEST_NEW_AUDIT  = 'Request New Audit';
  public static AUDIT_REVIEW  = 'Audit Review';

  public static NAVIGATION_ERROR_SUMMARY = 'Error';
  public static NAVIGATION_ERROR_DETAIL = 'You have made a change to this page without saving. Please save or cancel your changes before ' +
    'navigating away from this page.';
  public static NAVIGATION_ERROR_DETAIL_NO_CANCEL = 'You have made a change to this page without saving. Please save your changes before ' +
    'navigating away from this page.';
  public static CHANGE_AUDIT_STATUS_CONFIRMATION_MESSAGE = 'You have selected to change ' +
    'the status of Audit ${recordId} from "${fromStatus}" to "${toStatus}", are you sure?';
}

export enum AuditStatus {
  OPEN = 'Open',
  IN_PROGRESS = 'In Progress',
  ON_HOLD = 'On Hold',
  STAGING = 'Staging',
  PENDING_CLIENT_APPROVAL = 'Pending Client Approval',
  CANCELLED = 'Cancelled',
  CLOSED = 'Closed'
}

export enum AuditAnalyst {
  ASSIGNED_ANALYST = 'Assigned Analyst',
  SUPPORTING_ANALYST_ONE = 'Supporting Analyst One',
  SUPPORTING_ANALYST_TWO = 'Supporting Analyst Two',
  SUPPORTING_ANALYST_THREE = 'Supporting Analyst Three'
}
