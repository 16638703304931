import {AuditRoleModel} from '@core/core/lib/model/audit-role.model';

export class AuditRoleDetailModel{
  'auditId': number | null;
  'divideAuditResponsibility': boolean | null;
  'primaryAuditRole': AuditRole | null;
  'subcontractorOneAuditRole': AuditRole | null;
  'subcontractorTwoAuditRole': AuditRole | null;
}

export class AuditRole {
  'auditId': number;
  'contactType': string | null;
  'offeringId': number[] | [];
  'infoRequestId': number[] | [];
  'customRequestId': number[] | [];
}

export class AuditRoleResponsibility {
    'primaryAuditRole': AuditRoleModel | null;
    'subcontractorOneAuditRole': AuditRoleModel | null;
    'subcontractorTwoAuditRole': AuditRoleModel | null;
}
