import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {CVSConfirmationDialogContentComponent} from 'angular-component-library';
import {take} from 'rxjs';
import {DocumentCategories} from '../../../enums/document-category-enums';
import {DocumentMetaData} from 'src/app/models/document-meta-data.model';
import {DocumentService} from 'src/app/services/document.service';
import {UserService} from '../../../services/user.service';
import {saveAs} from 'file-saver';
import {BannerService} from '../../services/banner.service';
import {BaseComponent} from '../base/base.component';

@Component({
  selector: 'app-upload-docs',
  templateUrl: './upload-docs.component.html',
  styleUrls: ['./upload-docs.component.scss'],
})
export class UploadDocsComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() header!: string;
  @Input() uploadDocId!: number;
  @Input() detailAddlInfoRequestId!: number;
  @Input() infoRequestId!: number;
  @Input() detailAuditorGroupId: number;
  @Input() auditRecordId: string;
  @Input() generalDocId!: string;
  @Input() files: DocumentMetaData[] = [];
  @Input() amRecordId!: string;
  @Input() auditId!: number;
  @Input() auditRequestMode!: string;
  @Input() outletId!: string;
  @Output() uploadedFileGroup = new EventEmitter<{ docGroup: number; isUploaded: boolean }>();
  @ViewChild(MatSort, { static: true }) sort!: MatSort;


  dataSource = new MatTableDataSource<DocumentMetaData>(this.files);
  columns: string[];
  confirmationDialog: any;
  inputIdString;

  constructor(
    private matDialog: MatDialog,
    private documentService: DocumentService,
    private bs: BannerService,
    public userService: UserService
  ) {
    super(bs);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.files?.currentValue) {
      this.manageUploadedFiles();
      this.uploadedFileGroup.emit({ docGroup: this.uploadDocId, isUploaded: this.dataSource.data.length > 0 });
    }
  }

  private manageUploadedFiles() {
    this.dataSource.data.splice(0);
    this.files?.forEach(fileUpload => {
      if (fileUpload.uploadDocId === this.uploadDocId) {
        this.dataSource.data.push(fileUpload);
      }
    });
    this.sortDataItems();
  }

  ngOnInit(): void {
    this.removeNoFileRowForReadOnlyUser();
    this.dataSource.sort = this.sort;
    this.inputIdString = 'select-file-' + this.header?.replace(/[^A-Z0-9]+/ig, '-');
    this.columns = ['fileName', 'uploadDate', 'uploadedBy', 'action'];
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];

    if(!file) {
      return;
    }

    this.bs.close();
    const formData = new FormData();
    formData.append('fileName', file.name);
    formData.append('amsAuditRecordId', `${this.amRecordId}`);
    formData.append('fileType', file.name.split('.').pop());
    formData.append('fileStatus', 'File Uploaded');
    formData.append('uploadedUserEmail', this.userService.getUserEmail());
    formData.append('category', `${this.header}`);
    formData.append('auditId', `${this.auditId}`);
    this.uploadDocId ? formData.append('uploadDocId', `${this.uploadDocId}`) : ()=> {};
    this.infoRequestId ? formData.append('infoRequestId', `${this.infoRequestId}`) : ()=> {};
    this.detailAuditorGroupId ? formData.append('detailAuditorGroupId', `${this.detailAuditorGroupId}`) : ()=> {};
    this.detailAddlInfoRequestId ? formData.append('detailAddlInfoRequestId', `${this.detailAddlInfoRequestId}`) : ()=> {};
    this.generalDocId ? formData.append('generalDocId', `${this.generalDocId}`) : ()=> {};
    formData.append('uploadFile', file);
    // TODO replacing with auditUserGroupId
    formData.append('currentUserFirm', '');

    if (file.size > 300 * 1024 * 1024) {
      this.showErrorNotification(
        'File Size Limit of 300MB exceeded',
        'Audit File Not Uploaded',
        this.outletId
      );
      return;
    }

    this.documentService.uploadFile(formData).pipe(take(1)).subscribe({
      next: savedFile => {
        this.files.push(savedFile);
        this.manageUploadedFiles();
        this.sortDataItems();
        this.uploadedFileGroup.emit({ docGroup: savedFile.uploadDocId, isUploaded: this.dataSource.data.length > 0 });
        this.dataSource._updateChangeSubscription();
      },
      error: (error) => {
        if (error.error.code === '409') {
          this.showErrorNotification(
            'The filename you have chosen is not unique for this audit, please rename your file and upload again.',
            'Error',
            this.outletId
          );
        } else {
          this.showErrorNotification(
            'Please try again',
            'Audit File Not Uploaded',
            this.outletId
          );
        }
      }
    });
  }

  sortDataItems() {
    if (this.header === DocumentCategories.SCOPE || this.header === DocumentCategories.CLIENT) {
      this.dataSource.data.sort((item1, item2) => item1.fileName.localeCompare(item2.fileName));
      this.dataSource.sort?.sortChange.emit();
    }
  }

  uploadFile() {
    const input: HTMLInputElement = document.querySelector('#' + this.inputIdString);

    input.value = '';
    input.click();
  }

  deleteFile(file: DocumentMetaData) {
    this.bs.close();
    const index = this.files.indexOf(file);
    this.confirmationDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
      disableClose: true,
      data: {
        headline: 'Remove File?',
        body: 'Are you sure you wish to remove this file? It will be permanently deleted.',
        actionLabel: 'Yes',
        cancelLabel: 'Cancel',
      }
    });

    this.confirmationDialog.componentInstance.onConfirmClick.pipe(take(1)).subscribe(() => {
      this.documentService.deleteDocument({
        fileId: file.detailFileId,
        dmsId: file.fileDmsId,
        emailAddress: this.userService.getUserEmail(),
        // TODO replacing with auditUserGroupId
        currentUserFirm: '',
        fileStatus: 'File Deleted'
      })?.pipe(take(1)).subscribe(_ => {
        if (index !== -1) {
          this.files.splice(index, 1);
          this.manageUploadedFiles();
          this.dataSource._updateChangeSubscription();
        }
        this.uploadedFileGroup.emit({ docGroup: +file.uploadDocId, isUploaded: this.dataSource.data.length > 0 });
        this.confirmationDialog.close();
      });
    });

    this.confirmationDialog.componentInstance.onCancelClick.pipe(take(1)).subscribe(() => {
      this.confirmationDialog.close();
    });
  }

  downloadFile(file: DocumentMetaData) {
    this.bs.close();
    this.documentService.downloadDocument({
      fileDmsId: file.fileDmsId,
      requestUserId: this.userService.getUserEmail(),
      currentUserEmail: this.userService.getUserEmail(),
      // TODO replacing with auditUserGroupId
      currentUserFirm: '',
      fileStatus: 'File Retrieved'
    }).subscribe(res => {
      saveAs(res, file.fileName);
    });
  }

  removeNoFileRowForReadOnlyUser() {
    if (this.auditRequestMode === 'read') {
      this.dataSource.data = this.dataSource.data.filter(document => document.detailFileId !== '-1');
    }
  }

  get isShowApproveFile() {
    return !(this.header === 'Samples' || this.header === 'Report'
      || this.header === 'Audit Closure' || this.header === 'NDA');
  }

  protected readonly sessionStorage = sessionStorage;
  protected readonly onclick = onclick;
}
