import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {
  AuthCodePKCEService,
  AuthenticationService,
  CVSBaseModule,
  CVSLoaderModule,
  CVSPopoverButtonService
} from 'angular-component-library';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {SharedModule} from './shared/shared.module';
import {HomeComponent} from './home/home.component';
import {CoreModule} from '@core/core';
import {DashboardService} from './services/dashboard.service';
import {EnvironmentService} from '../environments/EnvironmentService';
import {AuthHttpInterceptorService} from './service/auth-http-interceptor.service';
import {MatSelectModule} from '@angular/material/select';
import {ReactiveFormsModule} from '@angular/forms';
import {ExternalModule} from './external/external.module';
import {environment} from '../environments/environment';

@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    AppComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CVSBaseModule,
    HttpClientModule,
    CVSLoaderModule,
    SharedModule.forRoot(),
    CoreModule,
    MatSelectModule,
    ReactiveFormsModule,
    ExternalModule
  ],
  providers: [
    EnvironmentService,
    {
      provide: APP_INITIALIZER,
      useFactory: (ds: EnvironmentService) => () => ds.applyOverrides(),
      deps: [EnvironmentService],
      multi: true
    },
    {provide: AuthenticationService, useClass: AuthCodePKCEService},
    {provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptorService, multi: true},
    {
      provide: 'externalUrlRedirectResolver',
      useValue: () => {
        window.location.href = environment.myPBMSiteURL;
      }
    },
    DashboardService,
    CVSPopoverButtonService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
