
export const formValidationMessage = Object.freeze({
  offeringId: {
    auditRoleSelection: 'Audit Information Requests were not selected for Audit Type(s) "claim audit type(s)" for ' +
      'the Primary or Subcontracting Firm(s).',
    primaryAuditRole: 'All audit responsibilities will go to the audit subcontractor'
  },
  typeRequestId: {
    error: 'Claim Audit Type is missing for ${claimAuditType}. ' +
      'Please select the missing Claim Audit Type(s) and select save or update!'
  },
  infoRequestId: {
    error: 'Audit Information Request is missing for ${claimAuditType}. ' +
      'Please select an Audit Information Request for the Audit Type or ' +
      'remove the referenced Audit Type from your selection and select save or update!'
  },
  customRequestId: {
    error: 'Other Information Request is missing for ${customInfoType}. ' +
      'Please select the missing Other Information Request(s) and select save or update!'
  }
});

export const banner = Object.freeze({
  alertDiv: '#audit-role-alert-div'
});

export const successMessages = Object.freeze({
  successTitle: 'Successful Save',
  save: 'Your Audit Role Responsibility was successfully saved!'
});

export const errorMessage = Object.freeze({
  multiError: 'Resolve the following criteria in order to proceed',
  auditRoleStep: 'Your Audit Roles Step is not complete',
  errorTitle: 'Unsuccessful Save!',
  save: 'You have failed to Save Audit Role Responsibility',
  customRequest: 'The Custom Data Request was not assigned to the Primary or Subcontracting Firm(s).',
});

export const hintMessage = Object.freeze({
  pageHintLine: 'Select Add Role and select the audit type(s) and requested initial deliverables for your firm and ' +
    'subcontracting firm(s).  After a successful save of your information, you can proceed to the next step of this ' +
    'audit by selecting Upload Documents on the progress menu on the left side of the page.',
  subcontractorResponsibility: 'You have selected to change your responsibility assignment, your responsibilities saved to the ' +
    'Primary Firm will be deleted. You will need to update the subcontractor roles to ensure you firms are covering the Audit Types ' +
    'and Information Data Requests. Select Yes to continue with your change or cancel!',
  divideAuditResponsibility: 'You have selected to change your responsibility assignment, you need to adjust your ' +
    'responsibilities to divide the responsibilities between the primary firm and subcontractor(s).  Select Yes to ' +
    'continue with your change or cancel!',
  reviewPageHint: 'The primary auditor has selected role’s for each contact associated with this audit.  This role reflects' +
    ' the information data that each contact will be able to view.  If you have concerns about this request, you can make the needed' +
    ' changes or return this request to the requester.  Please review this page completely and select Upload Documents in the progress' +
    ' menu to move to the next step in the Audit Review.'
});

export const modes = Object.freeze({
  request: 'request',
  edit: 'edit',
  read: 'read'
});
