import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {BehaviorSubject, catchError, throwError} from 'rxjs';
import {AuditDetail} from '../models/audit-detail-model';
import {FilterObjModel} from '../models/filter-obj.model';
import {ExternalAuditDetailResponseModel} from '../models/external-audit-detail-response.model';
import {AUDIT_CONTACT_SOURCE} from '../enums/audit-contact-source-enum';
import {ClientDetailsModel} from '../models/clientDetails.model';
import {AuditContactModel} from '../audit-request/audit-contact/audit-contact.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  backendUrl: string = environment.backendUrl;
  globalValue: any = {
    dashboardSummaryList: [],
    closedSummaryList: []
  };

  globalObject: BehaviorSubject<any> = new BehaviorSubject<any>(this.globalValue);

  constructor(public  httpClient: HttpClient) { }

  setObservable(key: string, data: any) {
    this.globalValue[key] = data;
    this.globalObject.next(this.globalValue);
  }

  getObservable(key: string) {
    return this.globalValue[key];
  }

  getAuditSummaryFilterList(body: any){
    return this.httpClient.post(this.backendUrl+'/audit/internal/activeDashboardList',body).pipe(catchError(this.errorHandler));
  }

  getAuditContactsBySource(contactSource: AUDIT_CONTACT_SOURCE) {
    return this.httpClient.get<AuditContactModel[]>(this.backendUrl+'/audit/contact/getAuditContactInfo/contactSource/' + contactSource)
      .pipe(catchError(this.errorHandler));
  }

  getClosedAuditSummaryFilterList(body: any){
    return this.httpClient.post(this.backendUrl+'/audit/internal/closedDashboardList',body).pipe(catchError(this.errorHandler));
  }

  getAuditDetailByClientAuditRecordId(clientAuditRecordName: any) {
    return this.httpClient.get<AuditDetail>( this.backendUrl+
      'audit/information/auditDetails'+clientAuditRecordName).pipe(catchError(this.errorHandler));
  }

  getAuditDetailsByRecordId(recordId: any) {
    return this.httpClient
      .get<AuditDetail>(this.backendUrl+'/audit/information/auditDetails/'+recordId).pipe(catchError(this.errorHandler));
  }

  getAuditSummaryActiveList(body: FilterObjModel){
    const apiUrl: string = this.backendUrl+'/audit/external/activeDashboardList';
    return this.httpClient.post<ExternalAuditDetailResponseModel>(apiUrl ,body).pipe(catchError(this.errorHandler));
  }

  getAuditSummaryDraftList(body: FilterObjModel){
    const apiUrl: string = this.backendUrl+'/audit/external/draftDashboardList';
    return this.httpClient.post<ExternalAuditDetailResponseModel>(apiUrl, body).pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    const errorMessage = error.error instanceof ErrorEvent ? `Error: ${error.error.message}`
      : `Error Code: ${error.status}\n Message: ${error.message}`;

    return throwError(()=> new Error(errorMessage));
  }

  deleteAuditRequest(auditId: number) {
    return this.httpClient.delete(`${environment.backendUrl}/audit/information/deleteAuditRequest/${auditId}`, {responseType: 'text'});
  }

  getSuperClientDetail(id: string, type: any) {
    if(type === 'clientCode') {
      return this.getClientCodeDetailApi(id);
    } else if(type === 'carrier') {
      return this.getCarrierDetailApi(id);
    }
  }

  getClientCodeDetailApi(clientCode: string) {
    return this.httpClient.get<ClientDetailsModel>(environment.backendUrl+'/audit/information/getClientDetail/'+clientCode);
  }

  getCarrierDetailApi(carrierId: string) {
    return this.httpClient.get<ClientDetailsModel>(environment.backendUrl+'/audit/information/getCarrierDetail/'+carrierId);
  }

  setAuditAnalystEmail(recordId: string, auditAnalystEmail: string, auditAnalystType: string) {
    const apiUrl = this.backendUrl + '/audit/updateAuditAnalyst';
    const auditAnalystBody = {
      recordId,
      auditAnalystEmail,
      auditAnalystType
    };
    return this.httpClient.post(apiUrl, auditAnalystBody).pipe(catchError(this.errorHandler));
  }

  updateAuditStatus(recordId: string, status: string) {
    const apiUrl = this.backendUrl + '/audit/updateAuditStatus/' + recordId + '?status=' + status;
    return this.httpClient.put(apiUrl, {}).pipe(catchError(this.errorHandler));
  }

  getSalesforceLink(key: string, audit: any){
    const salesforceUrl = environment.salesforceUrl;
    if (key === 'clientAuditRecordName' && audit?.clientAuditRecordID) {
      return salesforceUrl + `/Client_Audits__c/${audit?.clientAuditRecordID}/view`;
    } else if (key === 'accountName' && audit?.accountId) {
      return salesforceUrl + `/Account/${audit?.accountId}/view`;
    }
    return '';
  }
}
