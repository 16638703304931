import { Pipe, type PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'appDateFormat',
  standalone: true,
})
export class DateFormatPipe implements PipeTransform {
  transform(value: string, format: string = 'MM/DD/YYYY h:mm a z'): string {
    return value ? moment(new Date(value), 'America/Toronto').format(format) : value;
  }
}
