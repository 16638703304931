<div class="audit-role-container pt-10" *ngIf="auditRoleForm && auditRoleResponsibility">
    <div id="audit-role-alert-div"></div>
    <h2 class="pt-24">Auditor Roles</h2>
    <form [formGroup]="auditRoleForm" (keydown.enter)="$event.preventDefault()">
      <div class="field-padding-top pageHint" *ngIf="auditRequestMode !== Constants.REVIEW">
          <label class="hint-text">{{AUDIT_ROLE.hintMessage.pageHintLine}}</label>
      </div>
      <div class="field-padding-top pageHint" *ngIf="auditRequestMode === Constants.REVIEW">
        <label class="hint-text">{{AUDIT_ROLE.hintMessage.reviewPageHint}}</label>
      </div>
      <div class="field-padding-top" *ngIf="auditContactDetail?.hasAuditSubcontractor">
          <label class="label-gray">How will you assign audit responsibilities? *</label>
          <mat-radio-group aria-label="select audit responsibilities" formControlName="divideAuditResponsibility"
                           (input)="divideRoleChange($event)" class="radio-group">
              <mat-radio-button value="true" tabindex="0" >Divide audit responsibilities</mat-radio-button>
              <mat-radio-button value="false" tabindex="0" >All audit responsibilities will go to the audit subcontractor</mat-radio-button>
          </mat-radio-group>
      </div>
      <ng-container *ngIf="!auditContactDetail?.hasAuditSubcontractor || (auditContactDetail?.hasAuditSubcontractor && this.auditRoleForm.get('divideAuditResponsibility')!.value !== null)">
        <div class="field-padding-top">
            <h3>{{auditContactDetail?.firmId ? getFirmName(auditContactDetail?.firmId) : auditContactDetail?.clientName}}'s Role</h3>
        </div>
        <div class="field-padding-top pb-10">
            <label class="label-gray">{{auditContactDetail?.firmId ? label.auditFirm : label.auditClient}}</label>
            <h3 class="mt-10">{{auditContactDetail?.firmId ? getFirmName(auditContactDetail?.firmId) : auditContactDetail?.clientName}}</h3>
        </div>

        <div class="field-padding-top" *ngIf="showAddRole">
            <button class="pds-btn-ghost" [disabled]="auditRequestMode === AUDIT_CONTACT.modes.read" (click)="addPrimaryRole()" (keydown.enter)="addPrimaryRole()">Add Role</button>
        </div>
        <div class="field-padding-top" *ngIf="showPrimaryCard">
            <ams-core-audit-role-view [auditRoleDetail]="auditRoleResponsibility!.primaryAuditRole"
                                      [divideAuditResponsibility]="auditRoleForm.get('divideAuditResponsibility')!.value ? auditRoleForm.get('divideAuditResponsibility')!.value === 'true' : null"
                                      (auditRoleAction)="auditRoleAction($event)"></ams-core-audit-role-view>
        </div>
        <div class="field-padding-top contact-view-flex-container">
            <div *ngFor="let contact of auditContactDetail?.primaryAuditContact; let i=index;" class="contact-view-div">
                <ams-core-contact-card-view [contactDetail]="contact" [menuOption]="false"></ams-core-contact-card-view>
            </div>
        </div>

        <ng-container *ngIf="auditContactDetail?.auditSubcontractorOne?.length && auditContactDetail?.hasAuditSubcontractor">
            <hr>
            <div class="field-padding-top">
                <h3>Audit Subcontractor Role</h3>
            </div>
            <div class="field-padding-top pb-10">
                <label class="label-gray">Audit Subcontracting Firm</label>
                <h3 class="mt-10">{{getFirmName(auditContactDetail?.auditSubcontractorOne[0]?.firmId)}}</h3>
            </div>
            <div class="field-padding-top" *ngIf="auditRoleResponsibility && (auditRoleResponsibility!.subcontractorOneAuditRole!.offeringId!.length === 0 &&
                                                  auditRoleResponsibility!.subcontractorOneAuditRole!.customRequestId!.length === 0)">
                <button class="pds-btn-ghost" [disabled]="auditRequestMode === AUDIT_CONTACT.modes.read" (click)="addSubOneRole()" (keydown.enter)="addSubOneRole()">Add Subcontractor Role</button>
            </div>
            <div class="field-padding-top" *ngIf="showSubcontractorOneCard">
                <ams-core-audit-role-view [auditRoleDetail]="auditRoleResponsibility!.subcontractorOneAuditRole"
                                          [divideAuditResponsibility]="auditRoleForm.get('divideAuditResponsibility')!.value"
                                          (auditRoleAction)="auditRoleAction($event)"></ams-core-audit-role-view>
            </div>
            <div class="field-padding-top contact-view-flex-container">
                <div *ngFor="let contact of auditContactDetail?.auditSubcontractorOne" class="contact-view-div">
                    <ams-core-contact-card-view [contactDetail]="contact" [menuOption]="false"></ams-core-contact-card-view>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="auditContactDetail?.auditSubcontractorTwo?.length && auditContactDetail?.hasAuditSubcontractor">
            <hr>
            <div class="field-padding-top">
                <h3>Audit Subcontractor Role</h3>
            </div>
            <div class="field-padding-top pb-10">
                <label class="label-gray">Audit Subcontracting Firm</label>
                <h3 class="mt-10">{{getFirmName(auditContactDetail?.auditSubcontractorTwo[0]?.firmId)}}</h3>
            </div>
            <div class="field-padding-top" *ngIf="auditRoleResponsibility!.subcontractorTwoAuditRole!.offeringId!.length === 0 &&
                                                  auditRoleResponsibility!.subcontractorTwoAuditRole!.customRequestId!.length === 0">
                <button class="pds-btn-ghost" [disabled]="auditRequestMode === AUDIT_ROLE.modes.read" (click)="addSubTwoRole()" (keydown.enter)="addSubTwoRole()">Add Subcontractor Role</button>
            </div>
            <div class="field-padding-top" *ngIf="showSubcontractorTwoCard">
                <ams-core-audit-role-view [auditRoleDetail]="auditRoleResponsibility!.subcontractorTwoAuditRole"
                                          [divideAuditResponsibility]="auditRoleForm.get('divideAuditResponsibility')!.value"
                                          (auditRoleAction)="auditRoleAction($event)"></ams-core-audit-role-view>
            </div>
            <div class="field-padding-top contact-view-flex-container">
                <div *ngFor="let contact of auditContactDetail?.auditSubcontractorTwo" class="contact-view-div">
                    <ams-core-contact-card-view [contactDetail]="contact" [menuOption]="false"></ams-core-contact-card-view>
                </div>
            </div>
        </ng-container>

        <div class="btn-field" *ngIf="auditContactDetail?.hasAuditSubcontractor" [hidden]="auditRequestMode === AUDIT_CONTACT.modes.read">
            <button type="submit" class="pds-btn-primary" [disabled]="!auditRoleForm!.get('divideAuditResponsibility')!.value"
                    (click)="saveMainAuditorRole()" (keydown.enter)="saveMainAuditorRole()">Save</button>
        </div>
      </ng-container>
    </form>
</div>

<ng-template #auditTypeRef>
  <div class="model-container">
    <div class="contact-title">
      <h2 *ngIf="!roleAction">{{isAuditor ? 'Auditor Roles' : 'Auditor Subcontractor Roles'}}</h2>
      <h2 *ngIf="roleAction">{{isAuditor ? roleAction + ' Auditor Roles' : roleAction + ' Auditor Subcontractor Roles'}}</h2>
      <button class="content-align-right title-icon" mat-icon-button mat-dialog-close (click)="cancelAuditRoleModal()" (keydown.enter)="cancelAuditRoleModal()"
              [aria-label]="'Close ' + (roleAction ? roleAction + ' ' : '' ) + (isAuditor ? 'Auditor Roles' : 'Auditor Subcontractor Roles') + ' dialog'">
        <mat-icon svgIcon="close-btn--s" aria-hidden="false"></mat-icon>
      </button>
    </div>
    <form [formGroup]="auditTypeForm">
      <div class="pt-10 pb-10">
        <label>Please define the firm's auditor roles by selecting their audit types and information request for the audit.*</label>
      </div>
      <div class="field-padding-top pb-20">
        <label class="label-gray">{{isAuditor ? 'Audit Firm' : 'Audit Subcontracting Firm'}}</label>
        <h3 class="mt-10" *ngIf="isAuditor">{{auditRoleResponsibility!.primaryAuditRole!.firmId ? auditRoleResponsibility!.primaryAuditRole!.firmName : auditRoleResponsibility!.primaryAuditRole!.clientName}}</h3>
        <h3 class="mt-10" *ngIf="!isAuditor && contactType === AUDIT_CONTACT.contactType.subcontractorOne">{{auditRoleResponsibility!.subcontractorOneAuditRole!.firmName}}</h3>
        <h3 class="mt-10" *ngIf="!isAuditor && contactType === AUDIT_CONTACT.contactType.subcontractorTwo">{{auditRoleResponsibility!.subcontractorTwoAuditRole!.firmName}}</h3>
      </div>
      <div>
        <hr>
        <div class="pt-10" *ngIf="auditTypeError">
          <mat-error>{{auditTypeError}}</mat-error>
        </div>
        <div class="field-padding pt-10" id="claim-audit-types-roles"><strong>Claim Audit Types</strong></div>
        <div class="field-padding">
          <div class="item-container checkbox-audit-type-col-align" formArrayName="auditOffering" role="group" aria-labelledby="claim-audit-types-roles">
            <ng-container *ngFor="let offering of auditOfferingForm!.controls; let i = index" [formGroupName]="i">
              <mat-checkbox class="checkbox-row" formControlName="checked" (input)="offeringCheckboxInput()">{{offering.get('name')?.value}}</mat-checkbox>
              <br/>
            </ng-container>
          </div>
        </div>
      </div>
      <div *ngIf="auditTypeSelection">
        <hr>
        <div class="pt-10" *ngIf="auditInfoError">
          <mat-error>{{auditInfoError}}</mat-error>
        </div>
        <div class="field-padding pt-10" id="audit-information-request"><strong>Audit Information Request</strong></div>
        <div class="field-padding">
          <div class="item-container checkbox-align-col-4" formArrayName="auditInfoRequest" role="group" aria-labelledby="audit-information-request">
            <ng-container *ngFor="let info of auditInfoRequestForm!.controls ; let i = index" [formGroupName]="i">
              <mat-checkbox class="checkbox-row" formControlName="checked" (input)="infoCheckboxInput()">{{info.get('name')?.value}}</mat-checkbox>
              <br/>
            </ng-container>
          </div>
        </div>
      </div>
      <div *ngIf="auditCustomRequestForm!.controls!.length > 0">
        <hr>
        <div class="pt-10" *ngIf="customInfoError">
          <mat-error>{{customInfoError}}</mat-error>
        </div>
        <div class="field-padding pt-10" id="other-information-request"><strong>Other Information Request</strong></div>
        <div class="field-padding">
          <div class="item-container checkbox-align-col-4" formArrayName="auditCustomRequest" role="group" aria-labelledby="other-information-request">
            <ng-container *ngFor="let custom of auditCustomRequestForm!.controls ; let i = index" [formGroupName]="i">
              <mat-checkbox class="checkbox-row" formControlName="checked" (input)="customCheckboxInput()">{{custom.get('name')?.value}}</mat-checkbox>
              <br/>
            </ng-container>
          </div>
        </div>
      </div>
      <div></div>
    </form>
    <div class="content-align-right pt-15" *ngIf="roleAction != 'View'">
      <button class="pds-btn-ghost" (click)="cancelAuditRoleModal()" (keydown.enter)="cancelAuditRoleModal()">Cancel</button>
      <button class="pds-btn-primary" *ngIf="roleAction !== 'Edit'" [disabled]="!auditInfoSelection"
              (click)="saveAuditorRole()" (keydown.enter)="saveAuditorRole()">Save</button>
      <button class="pds-btn-primary" *ngIf="roleAction === 'Edit'"
              (click)="saveAuditorRole()" (keydown.enter)="saveAuditorRole()">Update</button>
    </div>
  </div>
</ng-template>

<ng-template #confirmationRef>
  <div class="model-container">
    <div class="contact-title">
      <h2>Are You Sure?</h2>
      <button mat-icon-button class="content-align-right title-icon" (click)="closeConfirmationModel()" (keydown.enter)="closeConfirmationModel()">
        <mat-icon svgIcon="close-btn--s" aria-hidden="false"></mat-icon>
      </button>
    </div>
    <div class="contact-content">
      <p *ngIf="this.auditRoleForm.get('divideAuditResponsibility')?.value === 'false'">{{AUDIT_ROLE.hintMessage.subcontractorResponsibility}}</p>
      <p *ngIf="this.auditRoleForm.get('divideAuditResponsibility')?.value === 'true'">{{AUDIT_ROLE.hintMessage.divideAuditResponsibility}}</p>
    </div>
    <div class="content-align-right pt-15">
      <button class="pds-btn-ghost" (click)="closeConfirmationModel()" (keydown.enter)="closeConfirmationModel()">Cancel</button>
      <button class="pds-btn-primary" (click)="changeAuditResponsibility()" (keydown.enter)="changeAuditResponsibility()">Yes</button>
    </div>
  </div>
</ng-template>

<ng-template #customBannerRoleTemplate>
  <cvs-banner [data]="bannerData">
    <h2>{{AUDIT_ROLE.errorMessage.errorTitle}}</h2>

    <p class="pt-15">{{AUDIT_ROLE.errorMessage.multiError}}</p>
    <ul>
      <li>{{AUDIT_ROLE.errorMessage.auditRoleStep}}</li>
      <li *ngIf="claimAuditType.length!='0'">Audit Information Requests were not selected for Audit Type(s) {{claimAuditType.join(', ')}} for the Primary or Subcontracting Firm(s).</li>
      <li *ngIf="!customRequestValid">{{AUDIT_ROLE.errorMessage.customRequest}}</li>
    </ul>
  </cvs-banner>
</ng-template>
