<div *ngIf="auditTypeForm" class="audit-info-type-container pt-10">
  <div id="audit-type-alert-div"></div>
  <h2 class="pt-24">Audit Types & Information Request</h2>
  <form [formGroup]="auditTypeForm" (keydown.enter)="$event.preventDefault()">
    <div class="audit-info-type-content field-padding">
      <div class="audit-request-type">
        <div class="audit-type" aria-labelledby="audit-type">
          <div class="pageHint" *ngIf="(auditRequestMode === AUDIT_TYPES.modes.request
          || auditRequestMode === AUDIT_TYPES.modes.edit || auditRequestMode === AUDIT_TYPES.modes.read)
          && auditRequestMode !== Constants.REVIEW">
            <span class="hint-text">{{AUDIT_TYPES.hintMessage.pageHintLine}}</span>
          </div>
          <div class="pageHint" *ngIf="auditRequestMode === Constants.REVIEW">
            <span class="hint-text">{{AUDIT_TYPES.hintMessage.reviewHint}}</span>
          </div>
          <h3 class="audit-info-content field-padding pt-10" id="audit-type">
            <label for="audit-type" id="claim-audit-types">Claim Audit Types</label>
            <button class="pds-icon-only-btn" (click)="typeDefDialog()" aria-label='More Information on Claim Audit Types details'>
              <mat-icon svgIcon="info-circle-f--xs" class="info-icon" aria-hidden='false'></mat-icon>
            </button>
          </h3>
          <div class="audit-info-content field-padding pt-10">
            <span>Please select from the following offerings *</span>
          </div>
          <div class="audit-info-content field-padding">
            <div class="item-container checkbox-container" role="group" aria-labelledby="claim-audit-types" formArrayName="auditOffering">
              <ng-container *ngFor="let offering of auditOffering ; let i = index">
                <mat-checkbox class="checkbox-row" (input)="auditTypeValidation(i , $event)"
                              [attr.aria-disabled]="auditOfferingForm.controls[i.toString()].disabled" [attr.aria-checked]="auditOfferingForm.controls[i.toString()].value"
                              [attr.aria-label]="offering.offeringName" [formControlName]="i">{{offering.offeringName}}</mat-checkbox>
                <br/>
              </ng-container>
            </div>
          <ng-container *ngIf="filterAuditOfferingType?.length > 0">
            <hr>
            <div class="audit-info-title">
              <h3 class="audit-info-content field-padding">
                <span>Claim Audit Information Request</span>
                <button class="pds-icon-only-btn" (click)="InfoDefDialog()"
                        aria-label="More information on Claim Audit Information Request">
                  <mat-icon svgIcon="info-circle-f--xs" class="info-icon"></mat-icon>
                </button>
              </h3>
            </div>
            <div class="audit-info-description">
              <ul>
                <li *ngFor="let ir of selectedInfoRequest" class="child" [attr.aria-label]="ir!.infoName">{{ ir!.infoName }}</li>
              </ul>
            </div>
          </ng-container>
        </div>
        <div class="custom-info" *ngIf="filterAuditOfferingType?.length > 0">
          <hr>
          <div class="custom-info-title">
            <h3>Other Audit Information Requested</h3>
          </div>
          <div class="custom-info-description pageHint">
            <p class="hint-text">{{AUDIT_TYPES.hintMessage.customInfoSectionHint}}</p>
          </div>
          <div class="custom-request">

            <button class="pds-btn-ghost" [disabled]="auditRequestMode === AUDIT_TYPES.modes.read" (click)="openDialog()">Custom Request</button>

          </div>
          <div>
            <ul class="custom-info-array" *ngIf="customInformationArray?.controls">
              <ng-container *ngFor="let control of customInformationArray?.controls; let i = index">
                  <li>{{control?.value?.value?.addlInfoDataTitle}}
                    <button class="pds-icon-only-btn" mat-button [matMenuTriggerFor]="menu" (click)="openMenuDialog()"
                            [attr.aria-label]="'Expand ' + control?.value?.value?.addlInfoDataTitle + '\'s Menu'">
                      <mat-icon svgIcon="more-vert-f--s" class='dot-icon' aria-hidden='false'></mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="viewDialog(i)">View Notes</button>
                      <button mat-menu-item *ngIf="auditRequestMode !== AUDIT_TYPES.modes.read" (click)="editDialog(i)">Edit Notes</button>
                      <button mat-menu-item *ngIf="auditRequestMode !== AUDIT_TYPES.modes.read
                        && isCustomInfoDeleteExist" (click)="deleteDialog(i)">Delete</button>
                    </mat-menu>
                  </li>
              </ng-container>
            </ul>
          </div>
          <hr>
          <div class="btn-field" [hidden]= "auditRequestMode === AUDIT_TYPES.modes.read">
            <button class="pds-btn-ghost" (click)="cancelAuditType()">Cancel</button>
            <button class="pds-btn-primary" (click)="confirmationModelForSave()">Save</button>
          </div>
        </div>
      </div>
    </div>
    </div>
  </form>
</div>

<ng-template #formRef>
  <div class="container">
    <form [formGroup]="customInformationGroup" (keydown.enter)="$event.preventDefault()">
      <ng-container>
        <div class="template-title">
          <h2>{{ customInformationGroup.get('addlInfoId')?.value ? 'Update' : 'Add'}} Custom Data</h2>
          <button class="pds-icon-only-btn" mat-icon-button mat-dialog-close
                  [aria-label]="'Close ' + (customInformationGroup.get('addlInfoId')?.value ? 'Update' : 'Add') + ' Custom Data dialog'">
            <mat-icon svgIcon="close-btn--s"></mat-icon>
          </button>
        </div>
        <div class="data-title">
          <mat-form-field>
            <mat-label>Information Data Title</mat-label>
            <input matInput [readonly]="isReadOnly" formControlName="addlInfoDataTitle"
                   aria-describedby="noteTitleError">
            <mat-error id="noteTitleError" [attr.aria-label]="showFieldErrors(customInformationGroup.get('addlInfoDataTitle'), AUDIT_TYPES.formValidationMessages.addlInfoDataTitle)"
                       *ngIf="(showError || customInformationGroup.get('addlInfoDataTitle')?.touched) && customInformationGroup.get('addlInfoDataTitle')?.invalid">
              <mat-icon svgIcon="error-f--s" matPrefix></mat-icon>
              {{showFieldErrors(customInformationGroup.get('addlInfoDataTitle'), AUDIT_TYPES.formValidationMessages.addlInfoDataTitle)}}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="data-notes">
          <mat-form-field appearance="outline" id="character-count-text-area">
            <mat-label>Notes</mat-label>
            <textarea matInput name="character-count" id="character-count"
                      formControlName="addlInfoDataNotes" (input)="onInputChange()" maxlength="300"
                      aria-describedby="noteTextError"></textarea>
            <mat-error [attr.aria-label]="showFieldErrors(customInformationGroup.get('addlInfoDataNotes'), AUDIT_TYPES.formValidationMessages.addlInfoDataNotes)" id="noteTextError"
                       *ngIf="(showError || customInformationGroup.get('addlInfoDataNotes')?.touched) && customInformationGroup.get('addlInfoDataNotes')?.invalid">
              <mat-icon svgIcon="error-f--s" matPrefix></mat-icon>
              {{showFieldErrors(customInformationGroup.get('addlInfoDataNotes'), AUDIT_TYPES.formValidationMessages.addlInfoDataNotes)}}
            </mat-error>
            <mat-hint [attr.aria-label]="remainingCharacters + ' Characters left'"
              [attr.aria-live]="remainingCharacters > 5 ? 'off' : 'assertive'">
              {{remainingCharacters}} Characters left
            </mat-hint>
          </mat-form-field>
          <div class="custom-hint">
            <mat-hint>{{AUDIT_TYPES.hintMessage.customInformation}}</mat-hint>
          </div>
        </div>
        <mat-dialog-actions class="dialog-actions" align="end">
          <button class="pds-btn-white" mat-dialog-close>Cancel</button>
          <button class="pds-btn-primary" (click)="saveCustomInformation()">{{ customInformationGroup.get('addlInfoId')?.value ? 'Update' : 'Add'}} Custom Request
          </button>
        </mat-dialog-actions>
      </ng-container>
    </form>
  </div>
</ng-template>

<ng-template #viewRef>
  <div class="view-container">
    <form [formGroup]="customInformationGroup">
      <div class="template-title">
        <h2>{{customInformationGroup.get('addlInfoDataTitle')?.value}}</h2>
        <button class="pds-icon-only-btn" mat-icon-button mat-dialog-close
                [aria-label]="'Close ' + (customInformationGroup.get('addlInfoDataTitle')?.value) + ' dialog'">
          <mat-icon svgIcon="close-btn--s"></mat-icon>
        </button>
      </div>
      <div class="template-body">
        <div>
          {{customInformationGroup.get('modifiedById')?.value}} {{customInformationGroup.get('modifiedDatetime')?.value}}
        </div>
        <p>{{customInformationGroup.get('addlInfoDataNotes')?.value}}</p>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #deleteRef>
  <div class="delete-container">
    <form [formGroup]="customInformationGroup">
      <div class="template-title">
        <h2>Delete Information Data</h2>
        <button class="pds-icon-only-btn" mat-icon-button mat-dialog-close
                aria-label="Close Delete Information Data dialog">
          <mat-icon svgIcon="close-btn--s"></mat-icon>
        </button>
      </div>
      <div class="template-body">
        <p>Are you sure you want to delete this custom request?</p>
      </div>
      <mat-dialog-actions class="dialog-actions" align="end">
        <button class="pds-btn-white" mat-dialog-close>Cancel</button>
        <button class="pds-btn-primary" (click)="deleteCustomInformation(customInformationGroup.get('addlInfoId')?.value)">Yes</button>
      </mat-dialog-actions>
    </form>
  </div>
</ng-template>

<ng-template #typeDefRef>
  <div class="type-def-container">
    <div class="template-title">
      <h2>Claim Audit Types</h2>
      <button class="pds-icon-only-btn center-close-x" mat-icon-button mat-dialog-close
              aria-label="Close Claim Audit Types dialog">
        <mat-icon svgIcon="close-btn--s"></mat-icon>
      </button>
    </div>
    <div class="def-body">
      <ng-container *ngFor="let definition of auditTypesDefinitions">
        <div class="part-a">
          <strong> {{definition.offeringName}} </strong>
        </div>
        <div class="part-b">
          {{definition.offeringDesc}}
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #infoDefRef>
  <div class="info-def-container">
    <div class="template-title">
      <h2>Claim Audit Information</h2>
      <button class="pds-icon-only-btn center-close-x" mat-icon-button mat-dialog-close
              aria-label="Close Claim Audit Information dialog">
        <mat-icon svgIcon="close-btn--s"></mat-icon>
      </button>
    </div>
    <div class="def-body">
      <ng-container *ngFor="let definition of auditInformationDefinitions">
        <div class="part-a">
          <strong> {{definition.infoRequestName}} </strong>
        </div>
        <div class="part-b">
          {{definition.infoRequestDesc}}
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #confirmationRef>
  <div class="model-container">
    <div class="contact-title">
      <h2>Are You Sure?</h2>
      <button class="content-align-right title-icon" mat-icon-button (click)="closeDialog()"
              aria-label="Close Are You Sure? dialog">
        <mat-icon svgIcon="close-btn--s" aria-hidden="false"></mat-icon>
      </button>
    </div>
    <div class="contact-content">
      <p>{{AUDIT_TYPES.hintMessage.auditRoleExist}}</p>
    </div>
    <div class="content-align-right pt-15">
      <button class="pds-btn-ghost" (click)="cancelAuditType()">Cancel</button>
      <button class="pds-btn-primary" (click)="saveAuditType()">Yes</button>
    </div>
  </div>
</ng-template>
