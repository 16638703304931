import {Component} from '@angular/core';
import {BannerService} from '../../services/banner.service';
import {FormControl, FormGroup} from '@angular/forms';
import * as _ from 'underscore';
import {BannerLink, CVSBannerComponentData, CVSBannerType} from 'angular-component-library';
import {Constants} from '../../../constants/constants';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent {
  formErrors: any;
  constructor(private bannerService: BannerService) { }


  /**
   *
   * @param form  formGroup
   * @param validationMessage load values form constants file
   * @param isCheckControlDirty in the formvaluechange we need show the gowrl msg is false,in save we can set as true
   * @param summaryText
   * @param clearMessage
   * @param outletId - CSS ID selector to show the banner on
   * @param removedAfterMilliseconds - Milliseconds before auto-closing banner (0 means indefinite)
   */
  showErrorMsg(form: FormGroup, validationMessage: any, isCheckControlDirty: boolean,
               summaryText: string, clearMessage: boolean = true, outletId?: string, removedAfterMilliseconds?: number): any {
    this.formErrors = {};
    if (clearMessage) {this.bannerService.close();}
    const isValid = this.validate(form, validationMessage, isCheckControlDirty);
    if (!isValid) {
      let validationMessages: BannerLink[] = [];

      for (const error in this.formErrors) {
        if (typeof this.formErrors[error] === 'object' && this.formErrors[error] !== null) {
          for (const err in this.formErrors[error]) {
            validationMessages.push({
              linkText: this.formErrors[error][err],
              linkFunc: this.errorCallBackForm
            });
          }
        }
      }
      validationMessages = _.uniq(validationMessages);
      this.showMultipleErrorMessage(validationMessages, summaryText, outletId, removedAfterMilliseconds);
    }
  }

  showFieldErrors(formControl: any, fieldValidationMessages: any): string {
    for (const errorCode in fieldValidationMessages) {
      if (formControl.hasError(errorCode)) {
        return fieldValidationMessages[errorCode];
      }
    }

    return JSON.stringify(formControl.errors);
  }

  errorCallBackForm = () => {
    console.log('errorCallBackForm called');
  };

  /**
   * check formgroup and its controls if any errors will add in the formErrors
   *
   * @param formGroup formgroup
   * @param validations  load values form constants file
   * @param isCheckControlDirty
   */
  validate(formGroup: any, validations: any, isCheckControlDirty: boolean): boolean {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    if (!formGroup) { // @ts-ignore
      return;
    }
    const form = formGroup;
    const errorMessages = validations;
    const isDirtyCheck = isCheckControlDirty ? formGroup.dirty : true;
    this.updateMessageForWholeForm(form, errorMessages, isDirtyCheck);

    for (const field in errorMessages) {
      this.updateMessageForFormField(form, field, errorMessages, isCheckControlDirty);
    }

    return Object.keys(this.formErrors).length <= 0;
  }

  updateMessageForWholeForm(formGroup: any, errorMessages: any, isDirtyCheck: boolean) {
    if (isDirtyCheck && formGroup.errors) {
      for (const key in formGroup.errors) {
        if (this.formErrors[key] === undefined) {
          this.formErrors[key] = errorMessages[key];
        } else {
          this.formErrors[key] += errorMessages[key]`${' '} `;
        }
      }
    }
  }

  updateMessageForFormField(form: any, field: string, errorMessages: any, isCheckControlDirty: boolean) {
    const control = form.get(field);
    if (control && !control.valid) {
      if (control instanceof FormControl && isCheckControlDirty ? control.dirty : true) {
        this.updateMessage(errorMessages, field, control); // form Control
        // Update messages for formArray and formGroup
        const keys = control?.controls ? Object.keys(control.controls) : null;
        if (keys) {
          keys.forEach((key) => {
            this.updateMessage(errorMessages[field], key, control.controls[key]);
          });
        }
      }
    }
  }

  updateMessage(errorMessages: any, field: any, control: any): void {
    const messages = errorMessages[field];
    for (const key in control.errors) {
      if (messages?.[key]) {
        if (this.formErrors[field] === undefined) {
          this.formErrors[field] = messages[key];
        }
      }
    }
  }

  private showMultipleMessage(severity: CVSBannerType, messages: Array<any>, summaryText = 'General',
                              outletId?: string, removedAfterMilliseconds?: number) {
    this.bannerService.sendAlertToCvsNotification({
      bannerType: severity, // required
      headline: summaryText,
      body: 'Resolve the following validation information in order to proceed',
      bannerLinks: messages,
      outletId,
      removedAfterMilliseconds
    });
  }

  showMultipleErrorMessage(messages: Array<any>, summaryText = 'Error',
                           outletId?: string, removedAfterMilliseconds?: number): void {
    const seconds =  removedAfterMilliseconds ?? 0;
    this.showMultipleMessage(CVSBannerType.Error, messages, summaryText, outletId, seconds);
  }

  showMultipleSuccessMessage(messages: Array<string>, summaryText = 'Success',
                             outletId?: string, removedAfterMilliseconds?: number) {
    const seconds =  removedAfterMilliseconds ?? 0;
    this.showMultipleMessage(CVSBannerType.Success, messages, summaryText, outletId, seconds);
  }

  showSuccessNotification(detail: string, summary?: string, outletId?: string, removedAfterMilliseconds?: number): void {
    const severity = CVSBannerType.Success;
    const summaryText = summary ?? 'Success';
    const selector = outletId ?? outletId;
    const seconds =  removedAfterMilliseconds ?? 0;
    this.bannerService.sendAlertToCvsNotification({
      bannerType: severity, // required
      headline: summaryText,
      body: detail,
      bannerLinks: [],
      outletId: selector,
      removedAfterMilliseconds: seconds
    });
  }

  showErrorNotification(detail: string, summary?: string, outletId?: string, removedAfterMilliseconds?: number, bannerLinks?: any[]): void {
    const severity = CVSBannerType.Error;
    const summaryText = summary ?? 'Error';
    const selector = outletId ?? outletId;
    const seconds =  removedAfterMilliseconds ?? 0;
    this.bannerService.sendAlertToCvsNotification({
      bannerType: severity, // required
      headline: summaryText,
      body: detail,
      bannerLinks: bannerLinks && bannerLinks?.length > 0 ? bannerLinks : [],
      outletId: selector,
      removedAfterMilliseconds: seconds
    });
  }

  showInformationNotification(detail: string, summary?: string, outletId?: string, removedAfterMilliseconds?: number): void {
    const severity = CVSBannerType.Info;
    const summaryText = summary ?? 'Information';
    const selector = outletId ?? outletId;
    const seconds =  removedAfterMilliseconds ?? 0;
    this.bannerService.sendAlertToCvsNotification({
      bannerType: severity, // required
      headline: summaryText,
      body: detail,
      bannerLinks: [],
      outletId: selector,
      removedAfterMilliseconds: seconds
    });
  }

  showWarningNotification(detail: string, summary?: string, outletId?: string, removedAfterMilliseconds?: number): void {
    const severity = CVSBannerType.Warning;
    const summaryText = summary ?? 'Warning';
    const selector = outletId ?? outletId;
    const seconds =  removedAfterMilliseconds ?? 0;
    this.bannerService.sendAlertToCvsNotification({
      bannerType: severity, // required
      headline: summaryText,
      body: detail,
      bannerLinks: [],
      outletId: selector,
      removedAfterMilliseconds: seconds
    });
  }

  showCustomNotification(data: CVSBannerComponentData) {
    this.bannerService.sendCustomAlert(data);
  }

  clearNotification(): void {
    this.bannerService.close();
  }

  /**
   * Shows the navigation error banner for audit request/review pages.
   *
   * @param errorDetail - String to set the banner detail (sometimes is different)
   * @param outletId - String id selector for div to output to
   */
  showNavErrorBanner(errorDetail, outletId) {
    this.showErrorNotification(
      errorDetail,
      Constants.NAVIGATION_ERROR_SUMMARY,
      outletId,
      20000);
  }
}
