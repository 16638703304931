import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import { Router} from '@angular/router';
import {BaseComponent} from '../../shared/components/base/base.component';
import {BannerService} from '../../shared/services/banner.service';
import {CVSBannerComponentData, CVSBannerType} from 'angular-component-library';
import {ClientDetailsModel} from '../../models/clientDetails.model';
import {DashboardService} from '../../services/dashboard.service';
import * as CryptoJS from 'crypto-js';


@Component({
  selector: 'app-external-dashboard',
  templateUrl: './external-dashboard.component.html',
  styleUrls: ['./external-dashboard.component.scss']
})
export class ExternalDashboardComponent extends BaseComponent implements OnInit, AfterViewInit {
  recordId = '';
  isSubmitSuccess = sessionStorage.getItem('submitSuccess')?.length ? sessionStorage.getItem('submitSuccess') : '';
  bannerData!: CVSBannerComponentData;
  activeNotesCount = 0;
  draftNotesCount = 0;
  @ViewChild('submitTemplate') submitTemplate: TemplateRef<any>;

  constructor(private router: Router, public bs: BannerService, public viewContainerRef: ViewContainerRef,
              private externalDashboardService: DashboardService
) {
    super(bs);
  }

  ngAfterViewInit(): void {
    if(this.isSubmitSuccess === 'true'){
      this.recordId = sessionStorage.getItem('recordId')?.length ? sessionStorage.getItem('recordId') : 'error';
      this.bannerData = {
        bannerType: CVSBannerType.Success,
        outletId: '#audit-dashboard-alert-div',
        template: this.submitTemplate,
        viewContainerRef: this.viewContainerRef,
        removedAfterMilliseconds: 0
      };
      this.showCustomNotification(this.bannerData);
      sessionStorage.removeItem('submitSuccess');
      sessionStorage.removeItem('recordId');
    }
  }

  ngOnInit(): void {
  }

  editAuditRecord(recordId: string) {
    this.router.navigate(['audit-request/', recordId]).then();
  }

  getTabNotesCount(event: any) {
    this.activeNotesCount = event?.activeNotesCount ?? 0;
    this.draftNotesCount = event?.draftNotesCount ?? 0;
  }

  getSuperClientDetail() {
    const clientUser = sessionStorage.getItem('isClientUser') ?? null;
    const clientDetail = sessionStorage.getItem('clientDetail') ?? null;
    if(clientUser === 'true' && !clientDetail) {
      const clientData = sessionStorage.getItem('client') ?? null;
      const client = clientData ? JSON.parse(CryptoJS.AES.decrypt(clientData, 'client').toString(CryptoJS.enc.Utf8)) : null;
      if (client?.clientCode || client?.carrierId) {
        const id = client?.carrierId ?? client?.clientCode;
        const type = client?.carrierId ? 'carrier' : 'clientCode';
        this.externalDashboardService.getSuperClientDetail(id, type).subscribe({
          next: (clientData: ClientDetailsModel) => {
            if (clientData) {
              const clientData1 = CryptoJS.AES.encrypt(JSON.stringify(clientData), 'clientDetail').toString();
              sessionStorage.setItem('clientDetail', clientData1);
            }
          }, error: () => {
            // setTimeout(() => {
              this.showErrorNotification('Client Details are not found!', 'Error', '#audit-dashboard-alert-div', 20000);
            // },3000);
          }
        });
      } else {
        // setTimeout(() => {
          this.showErrorNotification('Client Details are not found!', 'Error', '#audit-dashboard-alert-div', 20000);
        // },3000);
      }
    }
  }

}
