export class ViewNotesModel {
  'auditId': number;
  'detailNotesId': number[];
  'detailViewNotesId': number | null;
  'keyAction': string[];
  'notes': string;
  'notesCreator': string;
  'notesCreatedDatetime': string;
  'viewerEmailAddress': string | null;
  'notesViewed': boolean | null;
  'notesCreatorEmail': string;
}

export class NotesManagementModel {
  'auditId': number;
  'notesType': string;
  'category': string | null;
  'modalType': string | null;
  'title'?: string;

  'timelineDetails'?: any | null;
  'isNotesExists'?: boolean | null;
  'isActualTimeline'?: boolean | null;

  'tagDropdownList'?: any[] | null;
  'generalDocId'?: number | null;
  'detailAuditorGroupId'?: number | null;
}

export class NotesViewRequest {
  'auditId': number;
  'notesType': string;
  'keyAction': string | null;
  'notes': string | null;
  'timelineDetailId'?: number | null;
  'timelineId'?: number | null;
  'infoRequestId'?: number | null;
  'detailAdditionalInfoReqId'?: number | null;
  'generalDocId'?: number | null;
  'detailAuditorGroupId'?: number | null;
  'detailViewNotesIds'?: number[] | null;
}

export class NotesViewObject {
  'auditId': number;
  'notesType': string;
  'keyAction': string | null;
  'timelineDetailId'?: number | null;
  'infoRequestId'?: number[];
  'detailAdditionalInfoReqId'?: number[];
}
