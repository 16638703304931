<div *ngIf="!savedNote && !viewNote">
  <button type="button" class="pds-btn-text-only"
    [disabled]="disableNotes"
    (click)="showNotesManagementModal(noteAction.ADD)">
    <mat-icon svgIcon="paper-edit--s"></mat-icon>
    Add Notes
  </button>
</div>

<div *ngIf="viewNote">
  <button type="button" class="pds-btn-text-only"
    [disabled]="disableNotes"
    (click)="showNotesManagementModal(noteAction.VIEW)">
    <mat-icon svgIcon="paper-edit--s"></mat-icon>
    View Notes
  </button>
  <button *ngIf="deleteNoteExtAdmin && !disableNotes" type="button" class="pds-icon-only-btn" [matMenuTriggerFor]="menu"
          [attr.aria-label]="'Expand ' + timeLine?.value.title + '\'s Notes Management Menu'">
    <mat-icon svgIcon="more-vert-f--s"></mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="showNotesManagementModal(noteAction.DELETE)">Delete Notes</button>
  </mat-menu>
</div>

<div *ngIf="savedNote && !viewNote">
  <div class="note-management">
    <div class="added-check">
      <mat-icon style="color: #118738" svgIcon="check-circle-f--s"></mat-icon>
      <span id="added-text">Added</span>
    </div>
    <button type="button" class="pds-icon-only-btn" [matMenuTriggerFor]="menu"
            [attr.aria-label]="'Expand ' + timeLine?.value.title + '\'s Notes Management Menu'">
      <mat-icon svgIcon="more-vert-f--s"></mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="showNotesManagementModal(noteAction.VIEW)">View Notes</button>
      <button mat-menu-item (click)="showNotesManagementModal(noteAction.EDIT)">Edit Notes</button>
      <button mat-menu-item (click)="showNotesManagementModal(noteAction.DELETE)" *ngIf="!disableDelete">Delete Notes</button>
    </mat-menu>
  </div>
</div>

<ng-template #notesModalRef>
  <div>
    <mat-dialog-content>
      <p class="notes-p-spacing">{{ timeLine.value.title }} - Notes</p>
      <form [formGroup]="timeLine">
        <mat-form-field appearance="outline" style="width: 40vw;">
          <mat-label>Notes</mat-label>
          <textarea [maxlength]="500" matInput formControlName="notes" placeholder="Enter Notes"></textarea>
          <mat-hint>{{500 - timeLine.value.notes.length }} characters remaining!</mat-hint>
        </mat-form-field>
      </form>
    </mat-dialog-content>
  </div>
</ng-template>
