import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

export class DateValidator {
  static dateValidator(c: AbstractControl) {
    return !isNaN(c.value) ? null : {dateValidator: true};
  }

  static dateLessThanToday(control: FormControl): ValidationErrors | null {
    const today: Date = new Date();
    today.setHours(0,0,0,0);
    if (new Date(control.value) >= today)
      {return { dateLessThanToday: true };}

    return null;
  }

  /**
   * Validator function to set auditScopeEndDate dateLessThan error on the Audit Information screen.
   * Checks if auditScopeStartDate < auditScopeEndDate
   *  if true, set error as null
   *  else, set error as {dateLessThan: true}
   */
  static dateRangeValidator(): ValidatorFn {
    return (form: FormGroup): ValidationErrors | null => {
      const start: Date = form?.get('auditScopeStartDate')?.value;
      const end: Date = form?.get('auditScopeEndDate')?.value;
      if (start && end) {
        if(end <= start) {
          form.controls['auditScopeEndDate'].setErrors({dateLessThan: true});
          form.controls['auditScopeEndDate'].markAsTouched();
        }
        else {
          form.controls['auditScopeEndDate'].setErrors(null);
        }
      }
      return null;
    };
  }

  /**
   * Validator function to check auditScopeStartDate dateLessThanToday error on the Audit Information screen.
   * Checks if auditScopeStartDate < todayDate
   *  if true, set error as null
   *  else, set error as {dateLessThanToday: true}
   */
  static startDateLessThanToday(): ValidatorFn {
    const today: Date = new Date();
    today.setHours(0,0,0,0);
    return (form: FormGroup): ValidationErrors | null => {
      const start: Date = form?.get('auditScopeStartDate')?.value;
      if(start >= today) {
        form.controls['auditScopeStartDate'].setErrors({dateLessThanToday: true});
        form.controls['auditScopeStartDate'].markAsTouched();
      }
      return null;
    };
  }

  /**
   * Validator function to check auditScopeEndDate dateLessThanToday error on the Audit Information screen.
   * Checks if auditScopeEndDate < todayDate
   *  if true, set error as null
   *  else, set error as {dateLessThanToday: true}
   */
  static endDateLessThanToday(): ValidatorFn {
    const today: Date = new Date();
    today.setHours(0,0,0,0);
    return (form: FormGroup): ValidationErrors | null => {
      const end: Date = form?.get('auditScopeEndDate')?.value;
      if(end >= today) {
        form.controls['auditScopeEndDate'].setErrors({dateLessThanToday: true});
        form.controls['auditScopeEndDate'].markAsTouched();
      }
      return null;
    };
  }
}
