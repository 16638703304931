export const formValidationMessages = Object.freeze({
  generalMail: {
    email: 'Invalid Email Address!',
    emailExists: 'This contact email has already been associated to this audit!'
  },
  hasAuditSubcontractor: {
    required: 'You must select yes or no if you are using subcontractors before saving',
    length: 'One contact must be added for Audit Subcontractor Contacts',
  },
  auditSubcontractorOne: {
    maxLength: 'You can add only 5 contacts in audit subcontracting firm one'
  },
  auditSubcontractorTwo: {
    maxLength: 'You can add only 5 contacts in audit subcontracting firm two'
  },
  primaryAuditContact: {
    maxlength: 'You can add only 5 contacts in primary contact',
    primaryAuditor: 'One contact must be Assigned the Primary Member Auditor prior to successful save! ' +
      'Select the Primary Audit Contact for this audit by editing your existing contacts and selecting save again on the page.'
  }
});
export const contactType = Object.freeze({
  auditor: 'Firm Auditor',
  clientAuditor: 'Client Auditor',
  subcontractorOne: 'Subcontractor 1',
  subcontractorTwo: 'Subcontractor 2',
  general: 'General'
});

export const contactMode = Object.freeze({
  add: 'add',
  edit: 'edit',
  view: 'view',
  delete: 'delete',
  cancel: 'cancel'
});

export const banner = Object.freeze({
  alertDiv: '#audit-contact-alert-div'
});

export const successMessages = Object.freeze({
  successTitle: 'Successful Save',
  add: 'Your contact information was successfully saved!',
  deleteTitle: 'Successful Delete',
  delete: 'Your contact information was successfully deleted!',
});

export const errorMessage = Object.freeze({
  multiError: 'Resolve the following criteria in order to proceed',
  errorTitle: 'Unsuccessful Save',
  add: 'You have failed to Save Audit Contacts',
  primaryDelete: 'Primary contact cannot be deleted, we recommend you replace the primary member auditor before deleting this contact!',
  deleteTitle: 'Unsuccessful Delete'
});

export const hintMessage = Object.freeze({
  pageHintLine: 'Please add all of your firm contacts that will be involved with this audit. One primary contact must associated' +
    '        to this audit for the Firm that owns the client relationship. After a successful save, you can move to the next step' +
    '        of this request by selecting Audit Roles on the progress menu on the left side of the page.',
  auditRoleExist: 'You are requesting to delete a contact that has already been assigned roles within this audit. If you proceed ' +
    'with this delete, the roles for this contact will also be deleted and you will need to reassign them to another auditor.',
  reviewPageHint: 'The primary auditor has selected audit firm’s contacts involved in this audit request. If you have concerns about ' +
    'this request, you can make the needed changes or return this request to the requester.  Please review this page completely ' +
    'and select Auditor Roles in the progress menu to move to the next step in the Audit Review. '

});

export const modes = Object.freeze({
  request: 'request',
  edit: 'edit',
  read: 'read'
});

