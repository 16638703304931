import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ListValueModel} from '../../models/list-value.model';
import {environment} from '../../../environments/environment';
import {AuditRequest} from '../audit-request.model';
import {Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {ClientDetailsModel} from '../../models/clientDetails.model';

@Injectable({
  providedIn: 'root'
})
export class AuditInformationService {

  constructor(private http: HttpClient,
              private router: Router,
              private userService: UserService) { }

  /** Pulling Lob1 list from API**/
  getLobOneListFromApi() {
    return this.http.get<ListValueModel[]>(environment.backendUrl+'/audit/reference/auditLobOneList');
  }

  /** Pulling Lob2 list from API**/
  getLobTwoListFromApi() {
    return this.http.get<ListValueModel[]>(environment.backendUrl+'/audit/reference/auditLobTwoList');
  }

  saveAuditRequestDetails(body: AuditRequest, recordId?: string) {
    if(recordId) {
      return this.updateAuditRequest(body, recordId);
    } else {
      return this.saveAuditRequest(body);
    }
  }

  saveAuditRequest(body: AuditRequest){
    return this.http.post<AuditRequest>(environment.backendUrl+'/audit/information/addAuditRequest',body);
  }

  updateAuditRequest(body: AuditRequest, recordId: string) {
    return this.http.put<AuditRequest>(environment.backendUrl+'/audit/information/updateAuditRequest/'+recordId, body);
  }

  getAuditRequest(recordId: string) {
    return this.http.get<AuditRequest>(environment.backendUrl + '/audit/information/auditDetails/' + recordId);
  }

  getClientDetails( searchParameter: string,  requestSelection: string) {
    return this.http.get<ClientDetailsModel[]>(environment.backendUrl+'/audit/information/searchClientDetails/'+searchParameter,
    {params: {requestSelection}});
  }

  backClicked() {
    if (this.userService.isExternalUser()) {
      this.router.navigate(['/external']).then();
    } else if(this.userService.isInternalUser()){
      this.router.navigate(['/internal']).then();
    } else {
      this.router.navigate(['/home']).then();
    }
  }

}
