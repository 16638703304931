export const NotesType =  Object.freeze({
  AUDIT_STATUS: 'Audit Status',
  AUDIT_TIMELINE: 'Audit Timeline',
  DMS: 'DMS'
});

export const NotesCategory = Object.freeze({
  AUDIT_STATUS_INTERNAL: 'Internal',
  AUDIT_STATUS_EXTERNAL: 'External',
  PROJECTED_TIMELINE: 'Projected Timeline',
  ACTUAL_TIMELINE: 'Actual Timeline',
  NDA: 'Non-disclosure Agreement',
  SAMPLE: 'Samples',
  REPORT: 'Audit Reports',
  AUDIT_CLOSURE: 'Audit Closure',
  INITIAL_DELIVERABLES: 'Initial Deliverables'
});

export const InitialDeliverablesType = Object.freeze({
  INFO_REQUEST: 'Info Request',
  ADDITIONAL_INFO_REQUEST: 'Additional Info Request'
});
