import {
  Component,
  EventEmitter,
  Input,
  OnChanges, OnInit,
  Output, SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FirmModel} from '../../models/firm.model';
import {AuditTypeModel, CustomInformationModel} from '../audit-types/audit-types.model';
import {AuditContactDetailModel} from '../audit-contact/audit-contact.model';
import { label } from '../audit-information/audit-information.constants';
import {MatDialog} from '@angular/material/dialog';
import * as AUDIT_CONTACT from '../audit-contact/audit-contact.constants';
import * as AUDIT_ROLE from './audit-role.constants';
import * as _ from 'underscore';
import {
    AuditRole,
    AuditRoleDetailModel,
    AuditRoleResponsibility
} from './audit-role.model';
import {AuditRoleModel} from '@core/core/lib/model/audit-role.model';
import {AuditRoleService} from './audit-role.service';
import {BaseComponent} from '../../shared/components/base/base.component';
import {BannerService} from '../../shared/services/banner.service';
import {CVSBannerComponentData, CVSBannerType} from 'angular-component-library';
import {Constants} from '../../constants/constants';


@Component({
  selector: 'app-audit-role',
  templateUrl: './audit-role.component.html',
  styleUrls: ['./audit-role.component.scss']
})
export class AuditRoleComponent extends BaseComponent implements OnChanges, OnInit {

  @Input() auditRoleForm!: FormGroup;
  @Input() auditRequestMode!: string;
  @Input() firmList!: FirmModel[];
  @Input() auditTypeDetail!: AuditTypeModel[];
  @Input() auditTypes!: AuditTypeModel[];
  @Input() auditContactDetail!: AuditContactDetailModel;
  @Input() customRequest!: CustomInformationModel[];
  @Output() completedAuditorRoleForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() inProgressAuditRoleForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() onUpdateAuditRole: EventEmitter<any> = new EventEmitter<any>();
  @Output() roleWarning = new EventEmitter<boolean>();

  @ViewChild('auditTypeRef') auditTypeTemplateRef!: TemplateRef<any>;
  @ViewChild('confirmationRef') confirmationTemplateRef!: TemplateRef<any>;
  @ViewChild('customBannerRoleTemplate') customBannerRoleTemplate: TemplateRef<any>;
  protected readonly label = label;
  bannerData!: CVSBannerComponentData;
  isAuditor = true;
  contactType = '';
  auditTypeList: any[];
  roleAction = '';
  auditTypeError = '';
  auditInfoError = '';
  customInfoError = '';
  claimAuditType = [];
  auditRoleAddAction = false;
  auditRoleResponsibility!: AuditRoleResponsibility;
  auditTypeForm: FormGroup = this.formBuilder.group({
    auditId: [null, [Validators.required]],
    auditOffering: this.formBuilder.array([]),
    auditInfoRequest: this.formBuilder.array([]),
    auditCustomRequest: this.formBuilder.array([]),
  });
  divideAuditResponsibilityValue = '';
  customRequestValid = false;

  get auditOfferingForm() {
    return (this.auditTypeForm.get('auditOffering') as FormArray);
  }
  get auditInfoRequestForm() {
    return (this.auditTypeForm.get('auditInfoRequest') as FormArray);
  }
  get auditCustomRequestForm() {
    return (this.auditTypeForm.get('auditCustomRequest') as FormArray);
  }

  constructor(private matDialog: MatDialog,
              private formBuilder: FormBuilder,
              private auditRoleService: AuditRoleService,
              public bs: BannerService,
              public viewContainerRef: ViewContainerRef,) {
    super(bs);
  }

  ngOnInit() {
    this.divideAuditResponsibilityValue = this.auditRoleForm?.controls['divideAuditResponsibility'].value;
    this.auditRoleForm?.valueChanges.subscribe(() => {
      if(this.auditRequestMode !== AUDIT_ROLE.modes.read && !this.auditRoleForm.pristine) {
        this.roleWarning.emit(true);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.auditContactDetail?.auditId &&
      this.auditTypes?.length &&
      this.auditTypeDetail?.length) {
      this.filterAuditTypeAndInfoData();
      this.getAuditRoleResponsibility();
    }
  }

  filterAuditTypeAndInfoData() {
    if(this.auditTypes?.length && this.auditTypeDetail?.length) {
      const selectedOfferingId: number[] = this.auditTypeDetail.map( (map: AuditTypeModel) => map.offeringId);
      this.auditTypeList = _.uniq(this.auditTypes, x => x.offeringName).filter( f => selectedOfferingId.includes(f.offeringId));
    }
  }

  getFirmName(data: number) {
    const firm: FirmModel[] = this.firmList.filter( fList => fList.firmId === data);
    return firm?.length > 0 ? firm[0].firmName : null;
  }

  getAuditRoleResponsibility() {
    this.auditRoleService.getAuditRoleDetail(this.auditContactDetail?.auditId).subscribe({
      next: (auditRole: AuditRoleDetailModel) => {
        this.auditRoleResponsibility = new AuditRoleResponsibility();
        this.setAuditRoleDetail(auditRole);
        if(this.auditRequestMode === AUDIT_ROLE.modes.read){
          this.auditRoleForm.disable();
          this.completedAuditorRoleForm.emit(3);
          this.roleWarning.emit(false);
        }
      }
    });
  }

  setAuditRoleDetail(auditRoleDetail: AuditRoleDetailModel) {
    const primaryContactType = this.auditContactDetail?.firmId
        ? AUDIT_CONTACT.contactType.auditor : AUDIT_CONTACT.contactType.clientAuditor;
    this.auditRoleResponsibility = {
      primaryAuditRole:
          this.getRoleResponse(auditRoleDetail?.primaryAuditRole, primaryContactType),
      subcontractorOneAuditRole:
          this.getRoleResponse(auditRoleDetail?.subcontractorOneAuditRole, AUDIT_CONTACT.contactType.subcontractorOne),
      subcontractorTwoAuditRole:
          this.getRoleResponse(auditRoleDetail?.subcontractorTwoAuditRole, AUDIT_CONTACT.contactType.subcontractorTwo),
    };
    this.setAuditRoleForm(auditRoleDetail);
  }

  getRoleResponse(auditRole: AuditRole, contactType: string) {
    const firmDetail = this.getFirmDetail(contactType);
    return {
      auditId: auditRole?.auditId,
      firmId: firmDetail?.firmId,
      firmName: firmDetail?.firmName,
      clientId: firmDetail?.clientId,
      clientName: firmDetail?.clientName,
      contactType,
      offeringId: auditRole?.offeringId?.length ? auditRole?.offeringId : [],
      offeringName: this.getAuditOfferingName(auditRole?.offeringId),
      infoRequestId: auditRole?.infoRequestId?.length ? auditRole.infoRequestId : [],
      infoRequestName: this.getAuditInfoName(auditRole?.infoRequestId),
      customRequestId: auditRole?.customRequestId?.length ? auditRole.customRequestId : [],
      customRequestTitle: this.getAuditCustomRequestTitle(auditRole?.customRequestId),
      modes: this.auditRequestMode
    } as AuditRoleModel;
  }

  setAuditRoleForm(auditRole: AuditRoleDetailModel) {
    this.auditRoleForm.get('auditId')?.setValue(this.auditContactDetail?.auditId);
    if(!this.auditRoleAddAction) {
      this.auditRoleForm.get('divideAuditResponsibility')?.setValue(
        auditRole?.divideAuditResponsibility != null ? auditRole?.divideAuditResponsibility?.toString() : null);
    }

    this.contactType = auditRole?.primaryAuditRole?.contactType;
    this.setAuditRoleFormByFirm('primaryAuditRole');

    this.contactType = auditRole?.subcontractorOneAuditRole?.contactType;
    this.setAuditRoleFormByFirm('subcontractorOneAuditRole');

    this.contactType = auditRole?.subcontractorTwoAuditRole?.contactType;
    this.setAuditRoleFormByFirm('subcontractorTwoAuditRole');
    this.contactType = '';

    if(this.auditContactDetail?.hasAuditSubcontractor) {
      if(this.auditRoleForm.get('divideAuditResponsibility')?.value === 'false'
        && this.auditRoleForm?.get('primaryAuditRole')?.get('offeringId')?.value?.length > 0) {
        this.auditRoleForm.get('divideAuditResponsibility')?.setValue(null);
      }
      this.auditRoleForm.get('divideAuditResponsibility')?.setValidators(Validators.required);
      this.auditRoleForm.get('divideAuditResponsibility')?.updateValueAndValidity();
    }

    if(this.auditRoleForm.valid && this.auditRoleForm.pristine) {
      this.completedAuditorRoleForm.emit(3);
      this.roleWarning.emit(false);
    } else if(this.auditRoleForm.invalid && this.auditRoleAddAction){
      this.inProgressAuditRoleForm.emit();
    }
    this.auditRoleAddAction = false;
  }

  setAuditRoleFormByFirm(controlName: string) {
    if(controlName) {
      (this.auditRoleForm.get(controlName)?.get('offeringId') as FormArray)?.clear();
      (this.auditRoleForm.get(controlName)?.get('infoRequestId') as FormArray)?.clear();
      (this.auditRoleForm.get(controlName)?.get('customRequestId') as FormArray)?.clear();
      let auditRoleData: AuditRole = null;
      if (this.contactType === AUDIT_CONTACT.contactType.auditor
        || this.contactType === AUDIT_CONTACT.contactType.clientAuditor) {
        auditRoleData = this.auditRoleResponsibility?.primaryAuditRole ?? null;
      } else if (this.contactType === AUDIT_CONTACT.contactType.subcontractorOne) {
        auditRoleData = this.auditRoleResponsibility?.subcontractorOneAuditRole ?? null;
      } else if (this.contactType === AUDIT_CONTACT.contactType.subcontractorTwo) {
        auditRoleData = this.auditRoleResponsibility?.subcontractorTwoAuditRole ?? null;
      }
      this.auditRoleForm.get(controlName)?.get('auditId')?.setValue(auditRoleData?.auditId);
      this.auditRoleForm.get(controlName)?.get('contactType')?.setValue(this.contactType);
      auditRoleData?.offeringId?.forEach( (offering: any) => {
        (this.auditRoleForm.get(controlName)?.get('offeringId') as FormArray)?.push(new FormControl({value: offering, disabled: false}));
      });
      auditRoleData?.infoRequestId?.forEach( (info: any) => {
        (this.auditRoleForm.get(controlName)?.get('infoRequestId') as FormArray)?.push(new FormControl({value: info, disabled: false}));
      });
      auditRoleData?.customRequestId?.forEach( (custom: any) => {
        (this.auditRoleForm.get(controlName)?.get('customRequestId') as FormArray)?.push(new FormControl({value: custom, disabled: false}));
      });
    }
  }

  getFirmDetail(contactType: string) {
    const firmData: any = {
      firmId: null,
      firmName: null,
      clientId: null,
      clientName: null
    };
    switch (contactType) {
        case AUDIT_CONTACT.contactType.auditor: {
          firmData.firmId = this.auditContactDetail?.firmId;
          firmData.firmName = this.getFirmName(this.auditContactDetail?.firmId);
          return firmData;
        }
        case AUDIT_CONTACT.contactType.clientAuditor: {
          firmData.clientId = this.auditContactDetail?.clientId;
          firmData.clientName = this.auditContactDetail?.clientName;
          return firmData;
        }
        case AUDIT_CONTACT.contactType.subcontractorOne: {
          if(this.auditContactDetail?.auditSubcontractorOne?.length > 0) {
              firmData.firmId = this.auditContactDetail?.auditSubcontractorOne[0].firmId;
              firmData.firmName = this.getFirmName(this.auditContactDetail?.auditSubcontractorOne[0].firmId);
          }
            return firmData;
        }
        case AUDIT_CONTACT.contactType.subcontractorTwo: {
            if(this.auditContactDetail?.auditSubcontractorTwo?.length > 0) {
                firmData.firmId = this.auditContactDetail?.auditSubcontractorTwo[0].firmId;
                firmData.firmName = this.getFirmName(this.auditContactDetail?.auditSubcontractorTwo[0].firmId);
            }
            return firmData;
        }
        default: return firmData;
    }
  }

  getAuditOfferingName(offeringId: number[]) {
    offeringId = offeringId ?? [];
    const offeringName: string[] = this.auditTypes?.length
        ? this.auditTypes.filter( auditType => auditType?.offeringId && offeringId.includes(auditType?.offeringId))
            .map(m => m.offeringName)
        : [];
    const filteredOfferingName = _.uniq(offeringName, x => x);
    return filteredOfferingName?.length > 0 ? filteredOfferingName : [];
  }

  getAuditInfoName(infoRequestId: number[]) {
    infoRequestId = infoRequestId ?? [];
    const infoRequestName: string[] = this.auditTypes?.length ?
      this.auditTypes.filter( auditType => auditType?.infoId && infoRequestId.includes(auditType?.infoId))
        .map(m => m.infoName) : [];
    const filteredInfoRequestName = _.uniq(infoRequestName, x => x);
    return filteredInfoRequestName?.length > 0 ? filteredInfoRequestName : [];
  }

  getAuditCustomRequestTitle(customReqId: number[]) {
    customReqId = customReqId ?? [];
    const filterCustomReqTitle = this.customRequest.filter(
        (f: any) => customReqId.includes(f?.value?.addlInfoId)).map((map: any) => {return map?.value?.addlInfoDataTitle;});
    return filterCustomReqTitle?.length > 0 ? filterCustomReqTitle : [];
  }

  getAuditTypeForm() {
    this.auditTypeForm.get('auditId')?.setValue(this.auditContactDetail?.auditId);
    this.getAuditOfferingForm(this.contactType);
    this.getAuditInfoRequestForm(this.contactType, 'new');
    this.getAuditCustomRequestForm(this.contactType, 'new');
  }

  getAuditOfferingForm(contactType: string) {
    this.auditOfferingForm.clear();
    this.auditTypeList?.forEach((type: AuditTypeModel) => {
      const formControl = this.formBuilder.group({
        id: [type?.offeringId],
        name: [type?.offeringName],
        checked: [{
          value: this.auditTypeChecked(type?.offeringId, contactType),
          disabled: this.roleAction === 'View' ? true : this.auditTypeDisableCheck(type?.offeringId, contactType)
        }]
      });

      this.auditOfferingForm.push(formControl);
    });
    this.setAuditRoleFormOffering(this.auditRoleControlName);
  }

  auditTypeChecked(offeringId: number, contactType: string) {
    let checkedValue = [];
    if(contactType === AUDIT_CONTACT.contactType.auditor || contactType === AUDIT_CONTACT.contactType.clientAuditor) {
      checkedValue = this.auditRoleResponsibility?.primaryAuditRole?.offeringId?.filter(f => f === offeringId);
    } else if(contactType === AUDIT_CONTACT.contactType.subcontractorOne) {
      checkedValue = this.auditRoleResponsibility?.subcontractorOneAuditRole?.offeringId?.filter(f => f === offeringId);
    } else if(contactType === AUDIT_CONTACT.contactType.subcontractorTwo) {
      checkedValue = this.auditRoleResponsibility?.subcontractorTwoAuditRole?.offeringId?.filter(f => f === offeringId);
    }
    return !!checkedValue?.length;
  }

  auditTypeDisableCheck(offeringId: number, contactType: string) {
    const primaryCheck =  this.auditRoleResponsibility?.primaryAuditRole?.offeringId?.filter(f => f === offeringId);
    const subOneCheck =  this.auditRoleResponsibility?.subcontractorOneAuditRole?.offeringId?.filter(f => f === offeringId);
    const subTwoCheck =  this.auditRoleResponsibility?.subcontractorTwoAuditRole?.offeringId?.filter(f => f === offeringId);
    return this.checkDisableCondition(contactType, primaryCheck, subOneCheck, subTwoCheck);
  }

  getAuditInfoRequestForm(contactType: string, status?: string) {
    const preInfoRequestValue = this.auditInfoRequestForm?.value;
    const selectedOffering = this.auditOfferingForm?.controls?.filter(
        (offering: any) => offering.get('checked')?.value && offering.get('id')?.value).map((map: any) => {return map.get('id')?.value;});
    const infoRequestData = this.auditTypes?.filter( (type: AuditTypeModel) => selectedOffering.includes(type?.offeringId));
    const filterInfoRequest = _.uniq(infoRequestData, x => x.infoName);

    this.auditInfoRequestForm.clear();
    filterInfoRequest?.forEach((info: AuditTypeModel) => {
      const previousValue = preInfoRequestValue.filter((res: any) => res.id === info.infoId && res.checked);
      const previousSelection = status === 'clear' ? false : !!previousValue?.length;

      const formControl = this.formBuilder.group({
        id: [info?.infoId],
        name: [info?.infoName],
        checked: [{
          value: status === 'new' ? this.auditInfoChecked(info?.infoId, contactType) : previousSelection,
          disabled: this.roleAction === 'View'
        }]
      });
      this.auditInfoRequestForm.push(formControl);
    });
    this.setAuditRoleFormInfoRequest(this.auditRoleControlName);
  }

  auditInfoChecked(infoId: number, contactType: string) {
    let checkedInfoValue = [];
    if(contactType === AUDIT_CONTACT.contactType.auditor || contactType === AUDIT_CONTACT.contactType.clientAuditor) {
      checkedInfoValue = this.auditRoleResponsibility?.primaryAuditRole?.infoRequestId?.filter(f => f === infoId);
    } else if(contactType === AUDIT_CONTACT.contactType.subcontractorOne) {
      checkedInfoValue = this.auditRoleResponsibility?.subcontractorOneAuditRole?.infoRequestId?.filter(f => f === infoId);
    } else if(contactType === AUDIT_CONTACT.contactType.subcontractorTwo) {
      checkedInfoValue = this.auditRoleResponsibility?.subcontractorTwoAuditRole?.infoRequestId?.filter(f => f === infoId);
    }
    return !!checkedInfoValue?.length;
  }

  getAuditCustomRequestForm(contactType: string, status?: string) {
    const previousCustomRequestValue = this.auditCustomRequestForm?.value;
    this.auditCustomRequestForm.clear();
    this.customRequest?.forEach((custom: any) => {
      const previousValue = previousCustomRequestValue?.filter( (res: any) => res?.id === custom?.value?.addlInfoId && res?.checked);
      const previousSelection = status === 'clear' ? false : !!previousValue?.length;
      const formControl = this.formBuilder.group({
        id: [custom?.value?.addlInfoId],
        name: [custom?.value?.addlInfoDataTitle],
        checked: [{
          value: status === 'new' ? this.auditCustomRequestChecked(custom?.value?.addlInfoId, contactType) : previousSelection,
          disabled: this.roleAction === 'View' ? true : this.auditCustomRequestDisableCheck(custom?.value?.addlInfoId, contactType)
        }]
      });
      this.auditCustomRequestForm.push(formControl);
    });
    this.setAuditRoleFormCustomRequest(this.auditRoleControlName);
  }

  auditCustomRequestChecked(customReqId: number, contactType: string) {
    let checkedCustomReqValue = [];
    if(contactType === AUDIT_CONTACT.contactType.auditor || contactType === AUDIT_CONTACT.contactType.clientAuditor) {
      checkedCustomReqValue = this.auditRoleResponsibility?.primaryAuditRole?.customRequestId?.filter(f => f === customReqId);
    } else if(contactType === AUDIT_CONTACT.contactType.subcontractorOne) {
      checkedCustomReqValue = this.auditRoleResponsibility?.subcontractorOneAuditRole?.customRequestId?.filter(f => f === customReqId);
    } else if(contactType === AUDIT_CONTACT.contactType.subcontractorTwo) {
      checkedCustomReqValue = this.auditRoleResponsibility?.subcontractorTwoAuditRole?.customRequestId?.filter(f => f === customReqId);
    }
    return !!checkedCustomReqValue?.length;
  }

  auditCustomRequestDisableCheck(customReqId: number, contactType: string) {
    const primaryCheck =  this.auditRoleResponsibility?.primaryAuditRole?.customRequestId?.filter(f => f === customReqId);
    const subOneCheck =  this.auditRoleResponsibility?.subcontractorOneAuditRole?.customRequestId?.filter(f => f === customReqId);
    const subTwoCheck =  this.auditRoleResponsibility?.subcontractorTwoAuditRole?.customRequestId?.filter(f => f === customReqId);
    return this.checkDisableCondition(contactType, primaryCheck, subOneCheck, subTwoCheck);
  }

  checkDisableCondition(contactType: string, primaryCheck: number[], subOneCheck: number[], subTwoCheck: number[]) {
    return (contactType === AUDIT_CONTACT.contactType.subcontractorOne && (primaryCheck?.length > 0 || subTwoCheck?.length > 0))
        || (contactType === AUDIT_CONTACT.contactType.subcontractorTwo && (primaryCheck?.length > 0 || subOneCheck?.length > 0))
        || (contactType === AUDIT_CONTACT.contactType.auditor || contactType === AUDIT_CONTACT.contactType.clientAuditor)
        && (subOneCheck?.length > 0 || subTwoCheck?.length > 0);
  }

  addPrimaryRole(roleAction?: string) {
    this.isAuditor = true;
    this.roleAction = roleAction;
    this.contactType = this.auditRoleResponsibility?.primaryAuditRole?.contactType;
    this.getAuditTypeForm();
    this.openModelPopup();
  }

  addSubOneRole(roleAction?: string) {
    this.isAuditor = false;
    this.roleAction = roleAction;
    this.contactType = AUDIT_CONTACT.contactType.subcontractorOne;
    this.getAuditTypeForm();
    this.openModelPopup();
  }

  addSubTwoRole(roleAction?: string) {
    this.isAuditor = false;
    this.roleAction = roleAction;
    this.contactType = AUDIT_CONTACT.contactType.subcontractorTwo;
    this.getAuditTypeForm();
    this.openModelPopup();
  }

  auditRoleAction(event: any) {
    let mode = '';
    if(event?.mode === AUDIT_CONTACT.contactMode.edit) {
      mode = 'Edit';
    } else if(event?.mode === AUDIT_CONTACT.contactMode.view) {
      mode = 'View';
    }

    if(mode) {
      const auditRoleData = event?.auditRoleDetail ? event.auditRoleDetail : null;
      if(auditRoleData?.contactType === AUDIT_CONTACT.contactType.auditor
          || auditRoleData?.contactType === AUDIT_CONTACT.contactType.clientAuditor) {
        this.addPrimaryRole(mode);
      } else if(auditRoleData?.contactType === AUDIT_CONTACT.contactType.subcontractorOne) {
        this.addSubOneRole(mode);
      } else if(auditRoleData?.contactType === AUDIT_CONTACT.contactType.subcontractorTwo) {
        this.addSubTwoRole(mode);
      }
    }
  }

  offeringCheckboxInput() {
    this.auditInfoError = '';
    this.auditTypeError = '';
    if(this.auditTypeSelection) {
      this.getAuditInfoRequestForm(this.contactType);
    } else {
      this.getAuditInfoRequestForm(this.contactType, 'clear');
      this.getAuditCustomRequestForm(this.contactType, 'clear');
    }
    this.setAuditRoleFormOffering(this.auditRoleControlName);
  }

  infoCheckboxInput() {
    this.auditInfoError = '';
    this.setAuditRoleFormInfoRequest(this.auditRoleControlName);
  }

  customCheckboxInput() {
    this.customInfoError = '';
    this.setAuditRoleFormCustomRequest(this.auditRoleControlName);
  }

  get auditTypeSelection() {
    return this.auditOfferingForm?.controls && !!this.auditOfferingForm?.controls?.filter( (f: any) => f.get('checked')?.value)?.length;
  }

  get enableCustomSave(){
    const selectedAuditTypes = this.auditOfferingForm?.controls?.filter( (f: any) => f.get('checked')?.disabled);
    const divideRole = this.auditRoleForm.get('divideAuditResponsibility')?.value;
    if(divideRole &&
      this.auditOfferingForm?.value?.length === selectedAuditTypes?.length ){
      return true;
    }else{
      return this.auditOfferingForm?.controls && !!this.auditOfferingForm?.controls?.filter( (f: any) => f.get('checked')?.value)?.length;
    }
  }

  saveMainAuditorRole() {
    this.customRequestValid = this.customRequestCheck();
    if(this.auditRoleForm?.valid && this.customRequestValid) {
      this.auditRoleService.saveAuditRoleResponsibility(this.auditContactDetail?.auditId, this.auditRoleForm?.value).subscribe({
        next: () => {
          this.showSuccessNotification(
            AUDIT_ROLE.successMessages.save,
            AUDIT_ROLE.successMessages.successTitle,
            AUDIT_ROLE.banner.alertDiv,
            15000);
          this.roleWarning.emit(false);
          this.auditRoleForm.markAsPristine();
          this.divideAuditResponsibilityValue = this.auditRoleForm.controls['divideAuditResponsibility'].value;
          this.completedAuditorRoleForm.emit(3);
        }, error: () => {
          this.showErrorNotification(
            AUDIT_ROLE.errorMessage.save,
            AUDIT_ROLE.errorMessage.errorTitle,
            AUDIT_ROLE.banner.alertDiv,
            20000);
        }
      });
    } else if((this.auditRoleForm?.invalid && this.auditRoleForm?.hasError('offeringId')) || !this.customRequestValid){
      const primaryOffering = this.auditRoleResponsibility?.primaryAuditRole?.offeringId ?? [];
      const subOneOffering = this.auditRoleResponsibility?.subcontractorOneAuditRole?.offeringId ?? [];
      const subTwoOffering = this.auditRoleResponsibility?.subcontractorTwoAuditRole?.offeringId ?? [];
      this.claimAuditType  = this.auditTypeDetail?.filter( (type: AuditTypeModel) => type?.offeringId
        && !(primaryOffering.includes(type?.offeringId) || subOneOffering.includes(type?.offeringId)
          || subTwoOffering.includes(type?.offeringId)))
        .map((map: any) => {
          const auditTypeValue = this.auditTypes?.filter( (f: AuditTypeModel) => f.offeringId === map.offeringId);
          return auditTypeValue?.length > 0 ? auditTypeValue[0].offeringName : '';
      });
      this.bannerData = {
        bannerType: CVSBannerType.Warning,
        outletId: AUDIT_ROLE.banner.alertDiv,
        template: this.customBannerRoleTemplate,
        viewContainerRef: this.viewContainerRef,
        removedAfterMilliseconds: 15000
      };
      this.showCustomNotification(this.bannerData);
    } else {
      this.showErrorNotification(
        AUDIT_ROLE.errorMessage.save,
        AUDIT_ROLE.errorMessage.errorTitle,
        AUDIT_ROLE.banner.alertDiv,
        20000);
    }
  }

  saveAuditorRole() {
    const roleForm = this.validateSaveAuditRole();
    if(roleForm?.valid) {
      const mode = !this.roleAction ? 'add' : 'update';
      this.onUpdateAuditRole.emit(false);
      this.auditRoleService.saveAuditRole(mode, roleForm?.value).subscribe(({
        next: () => {
          this.auditRoleAddAction = true;
          this.getAuditRoleResponsibility();
          this.closeModelPopup();
          this.onUpdateAuditRole.emit(true);
          this.roleWarning.emit(false);
        }, error: () => {
          this.closeModelPopup();
          this.showErrorNotification(
            AUDIT_ROLE.errorMessage.save,
            AUDIT_ROLE.errorMessage.errorTitle,
            AUDIT_ROLE.banner.alertDiv,
            20000);
        }
      }));
    }
  }

  validateSaveAuditRole() {
    let auditRole = null;
    if(this.contactType === AUDIT_CONTACT.contactType.auditor
        || this.contactType === AUDIT_CONTACT.contactType.clientAuditor) {
      auditRole = this.auditRoleForm.get('primaryAuditRole');
    } else if(this.contactType === AUDIT_CONTACT.contactType.subcontractorOne) {
      auditRole = this.auditRoleForm.get('subcontractorOneAuditRole');
    } else if(this.contactType === AUDIT_CONTACT.contactType.subcontractorTwo) {
      auditRole = this.auditRoleForm.get('subcontractorTwoAuditRole');
    }
    this.auditInfoError = '';
    this.customInfoError = '';
    if(auditRole?.invalid) {
      if(auditRole?.hasError('auditTypes')) {
        const selectedInfoId = this.auditInfoRequestForm?.controls?.filter(
          (info: any) => info?.get('checked')?.value).map( (map: any) => map?.get('id')?.value);
        const allCustomTypes = this.auditOfferingForm?.controls?.filter(
          (offering: any) => !offering.get('checked')?.value)
          .map( (auditType: any) => {
            const auditTypeValue = this.auditTypes?.filter(
              (f: AuditTypeModel) => f.offeringId === auditType.get('id')?.value && selectedInfoId.includes(f?.offeringId));
            return auditTypeValue?.length === 0 ? auditType.get('name')?.value : '';
          }).filter(f => f !== '');
        const replaceValue = allCustomTypes?.length? allCustomTypes.join(', ') : '';
        this.auditTypeError = AUDIT_ROLE.formValidationMessage.typeRequestId.error.replace('${claimAuditType}', replaceValue);
      }
      if(auditRole?.hasError('offeringId')) {
        const selectedInfoId = this.auditInfoRequestForm?.controls?.filter(
          (info: any) => info?.get('checked')?.value).map( (map: any) => map?.get('id')?.value);

        const claimAuditType = this.auditOfferingForm?.controls?.filter(
          (offering: any) => offering.get('checked')?.value)
          .map( (auditType: any) => {
            const auditTypeValue = this.auditTypes?.filter(
              (f: AuditTypeModel) => f.offeringId === auditType.get('id')?.value && selectedInfoId.includes(f?.infoId));
            return auditTypeValue?.length === 0 ? auditType.get('name')?.value : '';
          }).filter(f => f !== '');

        const replaceValue = claimAuditType?.length? claimAuditType.join(', ') : '';
        this.auditInfoError = AUDIT_ROLE.formValidationMessage.infoRequestId.error.replace('${claimAuditType}', replaceValue);
      }
      if(auditRole?.hasError('customRequestId')) {
        const selectedInfoId = this.auditCustomRequestForm?.controls?.filter(
          (info: any) => info?.get('checked')?.value).map( (map: any) => map?.get('id')?.value);
        const allCustomTypes = this.auditCustomRequestForm?.controls?.filter(
          (offering: any) => !offering.get('checked')?.value)
          .map( (auditType: any) => {
          const auditTypeValue = this.customRequest?.filter(
            (f: CustomInformationModel) => f.addlInfoId === auditType.get('id')?.value && selectedInfoId.includes(f?.addlInfoId));
          return auditTypeValue?.length === 0 ? auditType.get('name')?.value : '';
        }).filter(f => f !== '');
        const replaceValue = allCustomTypes?.length? allCustomTypes.join(', ') : '';
        this.customInfoError = AUDIT_ROLE.formValidationMessage.customRequestId.error.replace('${customInfoType}', replaceValue);
      }
      return null;
    }
    return auditRole;

  }

  get auditRoleControlName() {
    let controlName = '';
    if(this.contactType === AUDIT_CONTACT.contactType.auditor
        || this.contactType === AUDIT_CONTACT.contactType.clientAuditor) {
      controlName = 'primaryAuditRole';
    } else if(this.contactType === AUDIT_CONTACT.contactType.subcontractorOne) {
      controlName = 'subcontractorOneAuditRole';
    } else if(this.contactType === AUDIT_CONTACT.contactType.subcontractorTwo) {
      controlName = 'subcontractorTwoAuditRole';
    }
    return controlName;
  }

  setAuditRoleFormOffering(control: string) {
    if(control) {
      (this.auditRoleForm.controls[control]?.get('offeringId') as FormArray)?.clear();
      this.auditOfferingForm?.controls?.forEach((offering: any) => {
        if (offering?.get('checked')?.value) {
          (this.auditRoleForm.controls[control]?.get('offeringId') as FormArray)?.push(offering?.get('id'));
        }
      });
    }
  }

  setAuditRoleFormInfoRequest(control: string) {
    if (control) {
      (this.auditRoleForm.controls[control]?.get('infoRequestId') as FormArray)?.clear();
      this.auditInfoRequestForm?.controls?.forEach((info: any) => {
        if (info?.get('checked')?.value) {
          (this.auditRoleForm.controls[control]?.get('infoRequestId') as FormArray)?.push(info?.get('id'));
        }
      });
    }
  }

  setAuditRoleFormCustomRequest(control: string) {
    if (control) {
      (this.auditRoleForm.controls[control]?.get('customRequestId') as FormArray)?.clear();
      this.auditCustomRequestForm?.controls?.forEach((custom: any) => {
        if (custom?.get('checked')?.value) {
          (this.auditRoleForm.controls[control]?.get('customRequestId') as FormArray)?.push(custom?.get('id'));
        }
      });
    }
  }

  openModelPopup(type?: string) {
    const template = type === 'confirmation' ? this.confirmationTemplateRef : this.auditTypeTemplateRef;
    this.matDialog.open(template, {
      disableClose: true,
      panelClass: type === 'confirmation' ? 'audit-small-model' : 'audit-type-dialog'
    });
  }

  cancelAuditRoleModal() {
    this.setAuditRoleFormByFirm(this.auditRoleControlName);
    this.closeModelPopup();
  }

  closeModelPopup() {
    this.matDialog.closeAll();
    this.contactType = null;
  }

  divideRoleChange(event: any) {
    const primaryRole = this.auditRoleForm?.get('primaryAuditRole')?.value;
    const divideRole = this.auditRoleForm.get('divideAuditResponsibility')?.value;
    this.divideAuditResponsibilityValue = divideRole;
    if((divideRole === 'true' && primaryRole?.offeringId?.length > 0) || (divideRole === 'false')) {
      this.auditRoleForm.get('divideAuditResponsibility')?.setValue(divideRole === 'true' ? 'false' : 'true');
      this.openModelPopup('confirmation');
    } else if(!divideRole && event.target?.value === 'false' && primaryRole?.offeringId?.length > 0) {
      this.auditRoleForm.get('divideAuditResponsibility')?.setValue('false');
      this.openModelPopup('confirmation');
    }
  }

  changeAuditResponsibility() {
    if(this.auditRoleForm.get('divideAuditResponsibility')?.value === 'false') {
      this.auditRoleResponsibility.primaryAuditRole.offeringId = [];
      this.auditRoleResponsibility.primaryAuditRole.infoRequestId = [];
      this.auditRoleResponsibility.primaryAuditRole.customRequestId = [];
      this.contactType = this.auditRoleResponsibility?.primaryAuditRole?.contactType;
      this.setAuditRoleFormByFirm('primaryAuditRole');
      this.roleAction = 'Edit';
      this.saveAuditorRole();
    } else if(this.auditRoleForm.get('divideAuditResponsibility')?.value === 'true') {
      this.roleWarning.emit(true);
      this.closeModelPopup();
    }
  }

  closeConfirmationModel() {
    if(!this.divideAuditResponsibilityValue) {
      this.auditRoleForm.get('divideAuditResponsibility')?.setValue(null);
    } else {
      const divideRole = this.auditRoleForm.get('divideAuditResponsibility')?.value;
      this.auditRoleForm.get('divideAuditResponsibility')?.setValue(divideRole === 'true' ? 'false' : 'true');
    }
    this.closeModelPopup();
  }

  /**
   * Checks if custom requests are put into roles.
   * If any are missing, return false
   * If custom requests are found in the roles OR if there are no custom requests, return true
   */
  customRequestCheck() {
    let crValid = true;
    if(this.customRequest.length > 0) {
      this.customRequest.forEach((cr: any) => {
        let customRequestFound = false;
        if(this.auditRoleForm.controls['primaryAuditRole'].value.customRequestId.length > 0) {
          this.auditRoleForm.controls['primaryAuditRole'].value.customRequestId.forEach(id => {
            if(id === cr.value.addlInfoId) {
              customRequestFound = true;
            }
          });
        }
        if(this.auditRoleForm.controls['subcontractorOneAuditRole'].value.customRequestId.length > 0) {
          this.auditRoleForm.controls['subcontractorOneAuditRole'].value.customRequestId.forEach(id => {
            if(id === cr.value.addlInfoId) {
              customRequestFound = true;
            }
          });
        }
        if(this.auditRoleForm.controls['subcontractorTwoAuditRole'].value.customRequestId.length > 0) {
          this.auditRoleForm.controls['subcontractorTwoAuditRole'].value.customRequestId.forEach(id => {
            if(id === cr.value.addlInfoId) {
              customRequestFound = true;
            }
          });
        }
        if(!customRequestFound) {
          crValid = false;
        }
      });
    }
    return crValid;
  }

  get showAddRole() {
    return ((!this.auditRoleResponsibility?.primaryAuditRole?.offeringId?.length &&
      !this.auditRoleResponsibility?.primaryAuditRole?.customRequestId?.length)
      && (!this.auditContactDetail?.hasAuditSubcontractor ||
        (this.auditContactDetail?.hasAuditSubcontractor && this.auditRoleForm.get('divideAuditResponsibility')?.value === 'true')));
  }

  get showPrimaryCard() {
    return this.auditRoleForm.get('divideAuditResponsibility')?.value === 'false' ||
      (this.auditRoleResponsibility
      && (this.auditRoleResponsibility?.primaryAuditRole?.offeringId?.length > 0 ||
          this.auditRoleResponsibility?.primaryAuditRole?.customRequestId?.length > 0)
      && (this.auditRoleForm.get('divideAuditResponsibility')?.value !== null || !this.auditContactDetail.hasAuditSubcontractor));
  }

  get showSubcontractorOneCard(){
    return this.auditRoleResponsibility && (this.auditRoleResponsibility?.subcontractorOneAuditRole?.offeringId?.length > 0 ||
      this.auditRoleResponsibility?.subcontractorOneAuditRole?.customRequestId?.length > 0);
  }

  get showSubcontractorTwoCard(){
    return this.auditRoleResponsibility?.subcontractorTwoAuditRole?.offeringId?.length > 0 ||
      this.auditRoleResponsibility?.subcontractorTwoAuditRole?.customRequestId?.length > 0;
  }

  /**
   * Function to determine if Save button is enabled in the modal when this.roleAction === undefined (Add role is clicked).
   * Ensures either an infoRequest or customRequest is selected before enabling.
   * Note: Other validations are handled by functions when Save is clicked (validateSaveAuditRole())
   */
  get auditInfoSelection() {
    const infoRequest = this.auditInfoRequestForm?.controls?.filter((info: any) => info.get('checked')?.value);
    const customRequest = this.auditCustomRequestForm?.controls?.filter((info: any) => info.get('checked')?.value);
    return infoRequest?.length > 0 || customRequest?.length > 0;
  }

  protected readonly AUDIT_CONTACT = AUDIT_CONTACT;
  protected readonly AUDIT_ROLE = AUDIT_ROLE;
  protected readonly Constants = Constants;
}
