<button (keydown.enter)="auditInfoService.backClicked()" (click)="auditInfoService.backClicked()" class="previous-page" tabindex="0">
  <mat-icon svgIcon="caret-left--s" aria-hidden="false"></mat-icon>
  <span class="previous-page-name">Dashboard</span>
</button>
<h1 role="doc-subtitle" class="pt-15">{{Constants.REQUEST_NEW_AUDIT}}</h1>
<ng-container *ngIf="auditDetail?.status">
  <div class="review-contents pt-24">
    <div class="audit-request" id="clientName">
      <strong>{{clientCoalitionLabel}}: </strong>{{auditDetail?.auditInformation?.originalClientName}}
    </div>
    <div class="record-id" id="recordId">
      <strong>Record ID: </strong>{{auditDetail?.recordId}}
    </div>
  </div>
  <div class="review-contents pt-10" *ngIf="approvedAuditStatuses.includes(auditDetail?.status) && clientOrAccountName">
    <div class="audit-request" id="cvsClientName">
      <strong>CVS {{clientCoalitionLabel}}: </strong>{{clientOrAccountName}}
    </div>
  </div>
</ng-container>

<div class="audit-container">
  <div class="stepper-container" aria-label="Progress Menu">
    <mat-stepper orientation="vertical" linear=true #stepper (selectionChange)="onStepChange($event)" (click)="errorBannerStepCheck()">
      <ng-template matStepperIcon="done">
        <mat-icon svgIcon="check-circle--s" style="height: 32px; width: 32px;"></mat-icon>
      </ng-template>
      <ng-template matStepperIcon="editDone">
        <mat-icon svgIcon="dot-indicator--s" style="height: 24px; width: 24px; color: white;"></mat-icon>
      </ng-template>
      <mat-step [stepControl]="stepperForm.controls['infoStep']"
                [state]="stepState[0]"
                label="Audit Information"></mat-step>
      <mat-step [stepControl]="stepperForm.controls['typeStep']"
                [state]="stepState[1]"
                label="Audit Types & Information Request"></mat-step>
      <mat-step [stepControl]="stepperForm.controls['contactStep']"
                [state]="stepState[2]"
                label="Audit Contacts"></mat-step>
      <mat-step [stepControl]="stepperForm.controls['roleStep']"
                [state]="stepState[3]"
                label="Auditor Roles"></mat-step>
      <mat-step [stepControl]="stepperForm.controls['documentStep']"
                [state]="stepState[4]"
                label="Upload Documents"></mat-step>
      <mat-step [stepControl]="stepperForm.controls['timelineStep']"
                [state]="stepState[5]"
                label="Projected Timeline"></mat-step>
    </mat-stepper>
  </div>

  <div class="form-div">
    <div [hidden]="!(showStep === 0)" class="screen-height">
      <app-audit-information [showBanner]="showWarningBanner[0]"
                             (infoWarning)="showWarning(0, $event)"
                             [auditRequestMode]="auditRequestMode"
                             [amsRecordId]="amsRecordId"
                             [auditDetail]="auditDetail"
                             [firmList]="firmList"
                             [auditInfoForm]="auditForm!.controls!.auditInfoForm"
                             [auditContactForm]="auditForm!.controls!.auditContactForm"
                             (savedAuditInfoForm)="savedAuditInfoForm($event)"
                             (completedAuditInfoForm)="setAuditStepperStates($event)"></app-audit-information>
    </div>
    <div [hidden]="!(showStep === 1)" class="screen-height">
      <app-audit-types [showBanner]="showWarningBanner[1]"
                       (typeWarning)="showWarning(1, $event)"
                       [auditRequestMode]="auditRequestMode"
                       [auditTypeForm]="auditForm!.controls.auditTypeForm"
                       [auditRoleChange]="auditRoleChange"
                       (savedAuditTypeForm)="savedAuditTypeForm($event)"
                       (completedAuditTypeForm)="setAuditStepperStates($event)"
                       (isAuditTypePricing)="setIsAuditPricing($event)"
      [auditDetails]="auditDetail" >
      </app-audit-types>
    </div>
    <div [hidden]="!(showStep === 2)" class="screen-height">
      <app-audit-contact [showBanner]="showWarningBanner[2]"
                         (contactWarning)="showWarning(2, $event)"
                         [auditRequestMode]="auditRequestMode"
                         [auditDetail]="auditDetail"
                         [auditContactForm]="auditForm!.controls!.auditContactForm"
                         [firmList]="firmList"
                         (inProgressAuditContactForm)="inProgressAuditContactForm()"
                         (savedAuditContactForm)="savedAuditContactForm($event)"
                         (completedAuditContactForm)="setAuditStepperStates($event)"></app-audit-contact>
    </div>
    <div [hidden]="!(showStep === 3)">
      <app-audit-role   (roleWarning)="showWarning(3, $event)"
                        [auditRequestMode]="auditRequestMode"
                        [auditRoleForm]="auditForm!.controls!.auditRoleForm"
                        [firmList]="firmList"
                        [customRequest]="auditForm!.controls!.auditTypeForm!.controls!.customInformation!.value"
                        [auditTypeDetail]="auditTypeDetail"
                        [auditTypes]="auditTypes"
                        [auditContactDetail]="auditContactDetail"
                        (onUpdateAuditRole)="onUpdateAuditRole($event)"
                        (inProgressAuditRoleForm)="inProgressAuditRoleForm() "
                        (completedAuditorRoleForm)="setAuditStepperStates($event)"></app-audit-role>
    </div>
    <div [hidden]="!(showStep === 4)">
      <app-upload-documents [auditDetail]="auditDetail"
                            [auditDocumentForm]="auditForm!.controls!.auditDocumentForm"
                            [auditRequestMode] = "auditRequestMode"
                            (inProgressAuditDocumentForm)="inProgressAuditDocumentForm()"
                            (completedAuditDocumentForm)="setAuditStepperStates($event)"></app-upload-documents>
    </div>
    <div [hidden]="!(showStep === 5)">
      <app-request-time-line [showBanner]="showWarningBanner[5]"
                     [auditRequestMode]="auditRequestMode"
                     (timelineWarning)="showWarning(5, $event)"
                     [auditTimelineForm]="auditForm!.controls!.auditTimelineForm"
                     [getTimelines]="getTimelines"
                     [auditDetail]="auditDetail"
                     [isAuditTypePricing]="isAuditPrice"
                     (savedAuditTimelineForm)="savedAuditTimelineForm()"
                     (completedAuditTimelineForm)="setAuditStepperStates($event)"
                     [auditForm]="auditForm"></app-request-time-line>
    </div>

  </div>
</div>
