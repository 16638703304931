<form class="audit-timeline-form pt-10" [formGroup]="auditTimelineForm" autocomplete="off">
  <div id="audit-timeline-alert-div">
  </div>
  <ng-container>
    <h2 class="header-spacing">Projected Timeline</h2>
    <p class="paragraph-style actual-timeline-info">The primary auditor has entered their desired timeline dates for this Audit Request. Please review the requested
      dates and notes. If the audit needs to be returned, please select "Return To Auditor." If the audit can begin, please
      select "Approve Audit".
    </p>
  </ng-container>

  <ng-container>
    <mat-table [dataSource]="timeLineDataSource" class="table-styling pds-table pds-card pds-table--dense">
      <mat-header-row *matHeaderRowDef="menuColumns"></mat-header-row>
      <mat-row class="table-row-style" *matRowDef="let row; let i = index; columns: menuColumns;"></mat-row>

      <!-- Projected Timeline Column -->
      <ng-container matColumnDef="projectedTimeline">
        <mat-header-cell class="mat-header-cell-style" *matHeaderCellDef>Projected Timeline</mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index;" [formGroup]="element">
          <div>
            <div *ngIf="element.value.notApplicable || (isAuditTypePricing && i === 2)">
              <mat-form-field class="projected-timeline-datepicker-form pds-read-only-field cvs-compact-form-field">
                <mat-label>{{element.value?.title}}</mat-label>
                <input matInput disabled [value]="'N/A'">
              </mat-form-field>
            </div>
            <div *ngIf="!element.value.notApplicable">
              <ng-container *ngIf="!(isAuditTypePricing && i === 2) && (enabledIndexes.includes(i))">
                <mat-form-field class="projected-timeline-datepicker-form cvs-compact-form-field" appearance="outline" [ngClass]="{'pds-form-field--warn': isDateBeforeDependentDate(element.value)}">
                  <mat-label>{{element.value?.title}}</mat-label>
                  <input (keydown)="onKeyDown($event)" (dateChange)="handleValueChange($event, i)" matInput [matDatepicker]="pickerClosedFilled" formControlName="date">
                  <mat-icon class="icon" svgIcon="calendar--s" matSuffix tabindex="0" aria-label="Open Date Picker" (click)="pickerClosedFilled.open()" (keydown.enter)="pickerClosedFilled.open()"></mat-icon>
                  <mat-error *ngIf="element.controls.date.errors?.invalid || element.controls.date.errors?.required">This field is required.</mat-error>
                  <mat-hint [attr.aria-live]="'polite'" *ngIf="isDateBeforeDependentDate(element.value)" id="warning-message">
                    <mat-icon alt="warning" svgIcon="info-circle--s" matPrefix></mat-icon>
                    Date entered is on or before previous timeline step.
                  </mat-hint>
                  <mat-datepicker #pickerClosedFilled [startAt]="minDateChange(i)"></mat-datepicker>
                </mat-form-field>
              </ng-container>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <!-- N/A Column -->
      <ng-container matColumnDef="notApplicable">
        <mat-header-cell class="mat-header-cell-style" *matHeaderCellDef>N/A</mat-header-cell>
        <mat-cell class="table-text-style" *matCellDef="let element; let i = index;" [formGroup]="element">
          <mat-checkbox formControlName="notApplicable" (change)="handleValueChange($event, i)">N/A</mat-checkbox>
        </mat-cell>
      </ng-container>

      <!-- General Timeline Column -->
      <ng-container matColumnDef="generalTimeline">
        <mat-header-cell class="mat-header-cell-style" *matHeaderCellDef>General Timeline</mat-header-cell>
        <mat-cell class="table-text-style" *matCellDef="let element">
          <ng-container *ngIf="element.value?.duration > 0">
            {{ element.value?.duration }} business days
          </ng-container>
          <ng-container *ngIf="element.value?.duration === 0">
            -
          </ng-container>
        </mat-cell>
      </ng-container>

      <!-- Responsibility Column -->
      <ng-container matColumnDef="responsibility">
        <mat-header-cell class="mat-header-cell-style" *matHeaderCellDef>Responsibility</mat-header-cell>
        <mat-cell class="table-text-style" *matCellDef="let element"> {{ element.value?.responsibility }} </mat-cell>
      </ng-container>

      <!-- Notes Column -->
      <ng-container matColumnDef="notes">
        <mat-header-cell class="mat-header-cell-style" *matHeaderCellDef>Notes</mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
          <div>
            <div *ngIf="element.value?.notesExists === false">
              <button class="pds-btn-text-only" [disabled]="element.value.notApplicable || (i === 2 && isAuditTypePricing)"
                      (click)="notesModal(element, 'add')">
                <mat-icon class="icon" svgIcon="pencil--s"> </mat-icon>
                Add Notes
              </button>
            </div>
            <div *ngIf="element.value?.notesExists === true">
              <button  class="pds-btn-text-only" [disabled]="element.value.notApplicable || (i === 2 && isAuditTypePricing)"
                       (click)="notesModal(element, 'view')">
                <mat-icon class="icon" svgIcon="file-check--s"> </mat-icon>
                View/Add Notes
              </button>
            </div>
          </div>
        </mat-cell>
      </ng-container>
    </mat-table>
  </ng-container>

  <div class="mt-10" style="display: flex; justify-content: space-between; width: 58vw;">
    <div>
      <button type="button" class="pds-btn-ghost" (click)="saveAuditTimeLines()">Save</button>
      <button type="button" class="pds-btn-ghost" (click)="cancelAuditTimeLines()">Cancel</button>
      <button type="button" class="pds-btn-primary" [disabled]="isAuditApproved || auditDetail?.status !== 'Staging' || disableApproveSubmit" (click)="approveAudit()">Approve Audit</button>
    </div>

    <div *ngIf="auditDetail?.status !== 'Cancelled'">
      <button type="button" class="pds-btn-ghost" [disabled]="disableForCancelAuditor" (click)="auditReviewModalOpen(formRef, 'CANCEL')">Cancel Audit</button>
      <button type="button" class="pds-btn-ghost" [disabled]="isClientAuditCase || disableForReturnAuditor" (click)="auditReviewModalOpen(formRef, 'RETURN')">Return to Auditor</button>
    </div>
  </div>
</form>

<ng-template #formRef>
  <ams-core-audit-review-modal
    [auditReviewForm]="auditReviewForm"
    [recordId]="auditDetail.recordId"
    [reviewType]="reviewType"
    [viewerEmailList]="viewerEmailList"
    (auditReviewActionDetail)="auditReviewAction($event)"></ams-core-audit-review-modal>
</ng-template>

<ng-template #approveAuditModal>
  <div *ngIf="showLoader">
    <pds-spinner [loadingText]="'Approving...'" [mode]="'indeterminate'"></pds-spinner>
  </div>
  <div *ngIf="!showLoader" class="approveAuditContainer">
    <form [formGroup]="auditReviewForm" (ngSubmit)="approveAuditOnSave()">
      <mat-dialog-content class="audit-review-modal">
        <span class="audit-review-text">{{AUDIT_TIMELINE.hint.approve}}</span>
        <mat-form-field class="audit-review-notes" appearance="outline">
          <mat-label>Comments (Optional)</mat-label>
          <textarea [maxlength]="500" matInput placeholder="Enter Notes" formControlName="auditReviewNotes"></textarea>
          <mat-hint [align]="'start'">{{500 - auditReviewForm?.value?.auditReviewNotes?.length }} characters remaining!
          </mat-hint>
        </mat-form-field>
      </mat-dialog-content>
      <mat-dialog-actions class="audit-review-buttons">
        <div>
          <button class="pds-btn-white" type="button" (click)="confirmationDialog.close()">Cancel</button>
          <button class="pds-btn-primary" type="submit">Approve Audit</button>
        </div>
      </mat-dialog-actions>
    </form>
  </div>
</ng-template>

<ng-template #overallSaveTemplate>
  <cvs-banner [data]="bannerData">
    <h2>{{AUDIT_TIMELINE.errorMessage.errorTitle}}</h2>
    <p class="pt-15">{{AUDIT_TIMELINE.errorMessage.multiError}}</p>
    <ul>
      <li *ngIf="auditForm?.get('auditInfoForm')?.invalid || auditForm?.get('auditInfoForm')?.hasError('required')">
        {{AUDIT_TIMELINE.overAllSaveValidation.auditInfo}}</li>
      <li *ngIf="auditForm?.get('auditInfoForm')?.invalid
      && auditForm?.get('auditInfoForm')?.hasError('reviewDropDown')">
        {{AUDIT_TIMELINE.overAllSaveValidation.auditInfoReview}}</li>

      <li *ngIf="auditForm?.get('auditTypeForm')?.invalid || auditForm?.get('auditTypeForm')?.hasError('hasAuditType')">
        {{AUDIT_TIMELINE.overAllSaveValidation.auditTypes}}</li>

      <li *ngIf="auditForm?.get('auditContactForm')?.hasError('primaryAuditContact')
      || auditForm?.get('auditContactForm')?.get('primaryAuditContact')?.hasError('required')
       || auditForm?.get('auditContactForm')?.invalid">
        {{AUDIT_TIMELINE.overAllSaveValidation.auditContact.primaryAuditor}}</li>
      <li *ngIf="auditForm?.get('auditContactForm')?.hasError('hasAuditSubcontractor')
        || auditForm?.get('auditContactForm')?.invalid">
        {{AUDIT_TIMELINE.overAllSaveValidation.auditContact.subContractor}}</li>

      <li *ngIf="auditForm?.get('auditRoleForm')?.errors?.offeringId?.auditRoleSelection
      && auditForm?.get('auditRoleForm')?.get('divideAuditResponsibility')?.value === null
      && auditForm?.get('auditContactForm')?.get('hasAuditSubcontractor')?.value === 'true'">
        {{AUDIT_TIMELINE.overAllSaveValidation.auditRole.responsibilityMiss}}</li>

      <li *ngIf="auditForm?.get('auditRoleForm')?.errors?.offeringId?.auditRoleSelection
      && auditForm?.get('auditRoleForm')?.get('divideAuditResponsibility')?.value === 'true'
      && !(auditForm.get('auditRoleForm')?.get('subcontractorOneAuditRole')?.get('offeringId')?.value?.length
      || auditForm.get('auditRoleForm')?.get('subcontractorTwoAuditRole')?.get('offeringId')?.value?.length)
      && auditForm?.get('auditContactForm')?.get('hasAuditSubcontractor')?.value === 'true'">
        {{AUDIT_TIMELINE.overAllSaveValidation.auditRole.divideRoleSubCon}}</li>

      <li *ngIf="auditForm?.get('auditRoleForm')?.errors?.offeringId?.auditRoleSelection
      && auditForm?.get('auditRoleForm')?.get('divideAuditResponsibility')?.value === 'true'
      && (!auditForm.get('auditRoleForm')?.get('primaryAuditRole')?.get('offeringId')?.value?.length)
      && auditForm?.get('auditContactForm')?.get('hasAuditSubcontractor')?.value === 'true'">
        {{AUDIT_TIMELINE.overAllSaveValidation.auditRole.dividedRolePrimary}}</li>

      <li *ngIf="auditForm?.get('auditRoleForm')?.errors?.offeringId?.auditRoleSelection
      && auditForm?.get('auditContactForm')?.get('hasAuditSubcontractor')?.value === 'false'
      && auditForm?.get('auditRoleForm')?.get('divideAuditResponsibility')?.value === null">
        {{AUDIT_TIMELINE.overAllSaveValidation.auditRole.primaryAuditorRole}}</li>

      <li *ngIf="auditForm?.get('auditRoleForm')?.errors?.offeringId?.auditRoleSelection
      && auditForm?.get('auditContactForm')?.get('hasAuditSubcontractor')?.value === 'true'
      && auditForm?.get('auditRoleForm')?.get('divideAuditResponsibility')?.value === 'false'">
        {{AUDIT_TIMELINE.overAllSaveValidation.auditRole.subConRole}}</li>

      <li *ngIf="documentRows?.hasError('required') || auditForm?.get('auditDocumentForm')?.invalid ">
        {{AUDIT_TIMELINE.overAllSaveValidation.uploadDocs.apiFail}}</li>
      <li *ngIf="documentRows.controls.at(0)?.get('docGroup')?.value === 2
      && documentRows.controls.at(0)?.get('hasFiles')?.hasError('required')">
        {{AUDIT_TIMELINE.overAllSaveValidation.uploadDocs.scopeLetter}}</li>
      <li *ngIf="documentRows.controls.at(1)?.get('docGroup')?.value === 3
       && documentRows.controls.at(1)?.get('hasFiles')?.hasError('required')">
        {{AUDIT_TIMELINE.overAllSaveValidation.uploadDocs.clientAuth}}</li>
      <li *ngIf="documentRows.controls.at(2)?.get('docGroup')?.value === 5
      && documentRows.controls.at(2)?.get('hasFiles')?.hasError('required')">
        {{AUDIT_TIMELINE.overAllSaveValidation.uploadDocs.coalition}}</li>

      <li *ngIf="auditForm?.get('auditTimelineForm')?.invalid && timelineDateInvalid" >
        {{AUDIT_TIMELINE.overAllSaveValidation.timelineDate}}</li>

      <li *ngIf="auditForm?.get('startUpProcessForm')?.invalid
      && firmsVerificationNDAForm.get('isFileUploaded')?.value === null">
        {{AUDIT_TIMELINE.overAllSaveValidation.startUp.oneDoc}}
      </li>
      <li *ngIf="auditForm?.get('startUpProcessForm')?.invalid
      && (firmsVerificationNDAForm?.get('Firm Auditor')?.value === null || firmsVerificationNDAForm?.get('Subcontractor 1')?.value === null
        || firmsVerificationNDAForm?.get('Subcontractor 2')?.value === null || firmsVerificationNDAForm?.get('Client Auditor')?.value === null)">
        {{AUDIT_TIMELINE.overAllSaveValidation.startUp.ndaVerify}}
      </li>
    </ul>
  </cvs-banner>
</ng-template>

