import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {NotesViewObject, NotesViewRequest, ViewNotesModel} from './notes-management.model';

@Injectable({
  providedIn: 'root'
})
export class NotesManagementService {

  backendUrl = environment.backendUrl + '/audit/';
  headers!: HttpHeaders;

  constructor(public httpClient: HttpClient) { }

  getDetailNotes(auditId: number): Observable<any> {
    return this.httpClient.get<ViewNotesModel[]>(this.backendUrl + 'getDetailNotes/' + auditId);
  }

  getNotesViewData(requestBody: NotesViewObject): Observable<any> {
    return this.httpClient.post<ViewNotesModel[]>(this.backendUrl + 'getNotesView', requestBody);
  }

  updateAuditStatusNotes(detailViewNotesIds: number[]){
    return this.httpClient.put<any>(this.backendUrl + 'updateAuditStatusNotes', {detailViewNotesIds});
  }

  addNewNote(notesData: NotesViewRequest[]){
    return this.httpClient.post(this.backendUrl+'addNotes', notesData);
  }

}
