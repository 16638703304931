import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DateValidator} from '../../shared/validator/date.validator';
import {AuditInformationService} from '../../audit-request/audit-information/audit-information.service';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {MatStepper} from '@angular/material/stepper';
import {AuditRequest} from '../../audit-request/audit-request.model';
import {AuditContactService} from '../../audit-request/audit-contact/audit-contact.service';
import {FirmModel} from '../../models/firm.model';
import {CustomValidator} from '../../shared/validator/custom.validator';
import {AuditContactDetailModel} from '../../audit-request/audit-contact/audit-contact.model';
import {AuditTypeModel} from '../../audit-request/audit-types/audit-types.model';
import {Constants} from '../../constants/constants';
import {AuditRequestUtil} from '../../utilities/audit-request.util';
import {BaseComponent} from '../../shared/components/base/base.component';
import {BannerService} from '../../shared/services/banner.service';

@Component({
  selector: 'app-external-audit-request',
  templateUrl: './external-audit-request.component.html',
  styleUrls: ['./external-audit-request.component.scss']
})
export class ExternalAuditRequestComponent extends BaseComponent implements OnInit {
  @ViewChild('stepper') stepper!: MatStepper;
  protected readonly Constants = Constants;
  @Input() auditRequestMode!: any;
  amsRecordId!: any;
  auditDetail!: any;
  firmList: FirmModel[] = [];
  isAuditPrice: boolean;
  auditContactDetail!: AuditContactDetailModel;
  auditTypeDetail!: AuditTypeModel[];
  auditTypes!: AuditTypeModel[];
  auditRoleChange!: any;
  approvedAuditStatuses: string[] = ['In Progress', 'On Hold', 'Pending Client Approval', 'Cancelled', 'Closed'];

  auditForm = new FormGroup({
    auditInfoForm: new FormGroup({
        createdForPrimaryFirm: new FormControl<string|null>(null),
        createdByFirmId: new FormControl<number|null>(null),
        primaryAuditFirm: new FormControl<string|null>(null),
      commonRequest: new FormControl<string|null>(null),
      multipleCarrier: new FormControl<string|null>(null),
      coalition: new FormControl<string|null>(null, [
        Validators.required,
      ]),
      originalClientName: new FormControl<string|null>( null, [
          Validators.required,
          Validators.maxLength(255),
        ]
      ),
      clientId: new FormControl<string|null>(null),
      clientName: new FormControl<string|null>( null),
      firmId: new FormControl<number|null>(null),
      firmName: new FormControl<string|null>(null),
      auditLobOneId: new FormControl<number|any>( { disabled: false }, [
        Validators.required,
      ]),
      auditLobTwoList: new FormControl<string[]>({value: [], disabled: false}, [Validators.required]),
      auditScopeStartDate: new FormControl<Date|null>(null, [
        Validators.required,
        DateValidator.dateValidator,
        DateValidator.dateLessThanToday,
      ]),
      auditScopeEndDate: new FormControl<Date|null>(null, [
        Validators.required,
        DateValidator.dateValidator,
        DateValidator.dateLessThanToday,
      ]),
      auditInitiationType: new FormControl<string|null>(null, [
        Validators.required,
      ]),
      accountId: new FormControl<string|null>(null),
      accountName: new FormControl<string|null>(null),
      carrierId: new FormControl<string|null>(null),
      carrierName: new FormControl<string|null>(null),
    },
      Validators.compose([
        DateValidator.dateRangeValidator(),
        DateValidator.startDateLessThanToday(),
        DateValidator.endDateLessThanToday()
      ])
    ),
    auditTypeForm: new FormGroup({
      auditOffering: new FormArray([], [Validators.required]),
      customInformation: new FormArray([])
    }),
    auditContactForm: this.formBuilder.group({
      generalMail: [null, [Validators.email]],
      hasAuditSubcontractor: [null, [Validators.required]],
      firmId: [null],
      clientId: [null],
      clientName: [null],
      primaryAuditContact: this.formBuilder.array([], [Validators.required, Validators.maxLength(5)]),
      auditSubcontractorOne: this.formBuilder.array([], [Validators.maxLength(5)]),
      auditSubcontractorTwo: this.formBuilder.array([], [Validators.maxLength(5)]),
    }),
    auditRoleForm: this.formBuilder.group({
      auditId: [null, [Validators.required]],
      divideAuditResponsibility: [null],
      primaryAuditRole: this.formBuilder.group({
        auditId: [null, [Validators.required]],
        contactType: [null, [Validators.required]],
        offeringId: this.formBuilder.array([]),
        infoRequestId: this.formBuilder.array([]),
        customRequestId: this.formBuilder.array([]),
      }),
      subcontractorOneAuditRole: this.formBuilder.group({
        auditId: [null, [Validators.required]],
        contactType: [null, [Validators.required]],
        offeringId: this.formBuilder.array([]),
        infoRequestId: this.formBuilder.array([]),
        customRequestId: this.formBuilder.array([]),
      }),
      subcontractorTwoAuditRole: this.formBuilder.group({
        auditId: [null, [Validators.required]],
        contactType: [null, [Validators.required]],
        offeringId: this.formBuilder.array([]),
        infoRequestId: this.formBuilder.array([]),
        customRequestId: this.formBuilder.array([]),
      })
    }),
    auditDocumentForm: this.formBuilder.group({
      documentRows: this.formBuilder.array([], [Validators.required])
    }),
    auditTimelineForm: this.formBuilder.group({
      tableRows: this.formBuilder.array([], [Validators.required])
    }),
  });
  showStep = 0;
  getTimelines = false;
  stepState = ['number', 'number', 'number', 'number', 'number', 'number'];
  showWarningBanner = [false, false, false, false, false, false];
  stepChanged = [false, false, false, false, false, false];
  stepperForm = new FormGroup({
    infoStep: new FormControl('', [
      Validators.required,
    ]),
    typeStep: new FormControl('', [
      Validators.required,
    ]),
    contactStep: new FormControl('', [
      Validators.required,
    ]),
    roleStep: new FormControl('', [
      Validators.required,
    ]),
    documentStep: new FormControl('', [
      Validators.required,
    ]),
    timelineStep: new FormControl('', [
      Validators.required,
    ])
  });

  ngOnInit() {
    this.auditFormCustomValidator();
    this.auditTypeCustomValidator();
  }

  constructor(public auditInfoService: AuditInformationService,
    public auditContactService: AuditContactService,
    private route: ActivatedRoute,
    private router: Router,
    public formBuilder: FormBuilder,
    public bs: BannerService) {
    super(bs);
    this.route.url.subscribe(response => {
      if (response?.length === 2) {
        const type: any = response[1].path;
        this.setAuditRequestMode(type);
      }
    });
  }

  setAuditRequestMode(type: any) {
    if(type === 'add') {
      this.auditRequestMode = 'request';
    } else if(type !== 'add' && type?.includes('AMS')) {
      this.amsRecordId = type;
      this.auditRequestMode = this.auditRequestMode ?? 'edit';
      this.getAuditRequest();
    }
    this.getFirmList();
  }

  auditFormCustomValidator() {
    this.auditForm.get('auditContactForm').setValidators([
      CustomValidator.subcontractorLength('hasAuditSubcontractor', 'auditSubcontractorOne', 'auditSubcontractorTwo', {
        hasAuditSubcontractor: { length: true },
      }),
      CustomValidator.isPrimaryValidator('primaryAuditContact', 'isPrimaryContact', {
        primaryAuditContact: { primaryAuditor: true }
      })
    ]);
  }

  auditTypeCustomValidator() {
    this.auditForm.get('auditTypeForm').setValidators([
      CustomValidator.AuditTypesValidator('auditOffering',{hasAuditType: {required: true}})
    ]);
  }

  getAuditRequest() {
    if(this.amsRecordId) {
      this.auditInfoService.getAuditRequest(this.amsRecordId).subscribe({
        next: (auditRequest: AuditRequest) => {
          auditRequest.mode = 'request';
          this.auditDetail = auditRequest;
        },
        error: (err: any) => {
          if (err?.error?.code === '403') {
            this.auditInfoService.backClicked();
          }
        }
      });
    }
  }

  getFirmList() {
    this.auditContactService.getFirmList().subscribe( (firm: FirmModel[]) => {
      this.firmList = firm ?? [];
    });
  }

  /**
   * Event listener for when the audit information form is saved.
   * Sets stepperForm for infoStep to true, making it 'complete' in the stepper.
   * Sets stepState[0] to done, so we get the checkbox icon.
   *
   * @param event - event object to get the amsRecordId
   */
  savedAuditInfoForm(event: any) {
    this.amsRecordId = event?.auditDetail?.recordId;
    this.auditDetail = event?.auditDetail;
    this.setAuditStepperStates(0);

    if(this.auditRequestMode === 'request') {
        this.router.navigate(['audit-request/'+this.amsRecordId]).then();
    }
  }

  savedAuditTypeForm(event: any) {
    this.auditTypes = event?.auditTypes ?? [];
    this.auditTypeDetail = event?.auditTypeDetail ?? [];
    this.auditForm.get('auditRoleForm')?.setValidators([
      CustomValidator.auditRoleValidator(this.auditTypeDetail, {
        offeringId: { auditRoleSelection: true },
      }),
      CustomValidator.auditSubcontractorRole({ offeringId: { primaryAuditRole: true }})
    ]);
    this.auditForm.get('auditRoleForm')?.get('primaryAuditRole')?.setValidators([
      CustomValidator.auditOfferingValidator(this.auditTypes, { offeringId: { infoSelection: true }})
    ]);
    this.auditForm.get('auditRoleForm')?.get('subcontractorOneAuditRole')?.setValidators([
      CustomValidator.auditOfferingValidator(this.auditTypes, { offeringId: { infoSelection: true }})
    ]);
    this.auditForm.get('auditRoleForm')?.get('subcontractorTwoAuditRole')?.setValidators([
      CustomValidator.auditOfferingValidator(this.auditTypes, { offeringId: { infoSelection: true }})
    ]);
    this.setAuditStepperStates(1);
  }

  inProgressAuditContactForm() {
    this.stepChanged[2] = true;
    this.stepper.steps.get(2).interacted = true;
    this.stepState[2] = 'number';
    this.stepperForm.controls['contactStep'].setValue('');
  }

  savedAuditContactForm(event: any) {
    this.auditContactDetail = event?.auditContactDetail ?? null;
    this.setAuditStepperStates(2);
  }

  inProgressAuditRoleForm() {
    this.stepChanged[3] = true;
    this.stepper.steps.get(3).interacted = true;
    this.stepState[3] = 'number';
    this.stepperForm.controls['roleStep'].setValue('');
  }

  onUpdateAuditRole(event: any) {
    this.auditRoleChange = event;
  }

  inProgressAuditDocumentForm() {
    this.stepper.steps.get(4).interacted = true;
    this.stepState[4] = 'number';
    this.stepperForm.controls['documentStep'].setValue('');
  }

  savedAuditTimelineForm() {
    this.setAuditStepperStates(5);
  }

  /**
   * Event listener for when stepper changes steps
   * if step had changes on previous index:
   *  Sets showStep var to what step was selected on stepper.
   *  Sets stepState for current step to 'edit'.
   *  Sets stepState for previous step to 'done' or 'number' depending on what that step was.
   *  Sets showWarningBanner flag back to false.
   * else:
   *  Sets step back to previous step.
   *  Sets showWarningBanner flag to true.
   *  Sets stepChanged flag back to false.
   *
   * @param event - stepper selection event object that contains stepper indices
   */
  onStepChange(event: StepperSelectionEvent) {
    const previousSelectedStepState = this.stepState[event.previouslySelectedIndex];
    if(event.selectedIndex === 5) {
      this.getTimelines = true;
    }
    if(this.stepChanged[event.previouslySelectedIndex] !== true) {
      this.showStep = event.selectedIndex;
      this.stepState[event.selectedIndex] = this.stepState[event.selectedIndex] === 'done'? 'editDone' : 'edit';
      this.stepState[event.previouslySelectedIndex] = (previousSelectedStepState === 'done' || previousSelectedStepState === 'editDone') ?
        'done' : 'number';
      this.showWarningBanner[event.previouslySelectedIndex] = false;
    } else {
      setTimeout(() => {
        this.stepper.selectedIndex = event.previouslySelectedIndex;
      });
      this.showWarningBanner[event.previouslySelectedIndex] = true;
    }

    if(this.showStep === 3) {
      const customInfoCount = this.auditForm.get('auditTypeForm')?.get('customInformation').value.length;
      const auditTypesCount = this.auditForm.get('auditTypeForm')?.get('auditOffering').value.filter(
        offering => offering === true
      ).length;
      // Checks if there is no subcontractor on audit
      if (this.auditContactDetail !== null && !this.auditContactDetail.hasAuditSubcontractor) {
        customInfoCount > 0 ?
          // Add validator for Other Information Request
          this.auditForm.get('auditRoleForm')?.get('primaryAuditRole')?.setValidators([
            CustomValidator.auditOfferingValidator(this.auditTypes, {offeringId: {infoSelection: true}}),
            CustomValidator.auditOfferingValidatorWithNoSubContractors(
              this.auditTypes,
              {auditTypes: {infoSelection: true}},
              auditTypesCount),
            CustomValidator.customOfferingValidatorWithNoSubContractors(
              this.auditTypes,
              {customRequestId: {infoSelection: true}},
              customInfoCount)
          ]) :
          this.auditForm.get('auditRoleForm')?.get('primaryAuditRole')?.setValidators([
            CustomValidator.auditOfferingValidator(this.auditTypes, {offeringId: {infoSelection: true}}),
            CustomValidator.auditOfferingValidatorWithNoSubContractors(
              this.auditTypes,
              {auditTypes: {infoSelection: true}},
              auditTypesCount)
          ]);
      }
    }
  }

  /**
   * Event listener for an already valid form.
   * If it catches a number, set that step in the stepper to complete.
   *
   * @param step - emitted step number from children forms
   */
  setAuditStepperStates(step: number) {
    const infoStep = this.stepperForm.controls['infoStep'];
    const typeStep = this.stepperForm.controls['typeStep'];
    const contactStep = this.stepperForm.controls['contactStep'];
    const roleStep = this.stepperForm.controls['roleStep'];
    const documentStep = this.stepperForm.controls['documentStep'];
    const timelineStep = this.stepperForm.controls['timelineStep'];
    switch(step) {
      case 0:
        if(infoStep.value !== 'true') {
          infoStep.setValue('true');
          if(this.stepState[0] === 'number') {
            this.stepState[0] = 'done';
          } else {
            this.stepState[0] = 'editDone';
          }
          this.stepper.steps.get(0).interacted = true;
        }
        break;
      case 1:
        if(typeStep?.value !== 'true') {
          typeStep.setValue('true');
          this.stepState[1] = 'done';
          this.stepper.steps.get(1).interacted = true;
        }
        break;
      case 2:
        if(contactStep?.value !== 'true') {
          contactStep.setValue('true');
          this.stepState[2] = 'done';
          this.stepper.steps.get(2).interacted = true;
        }
        break;
      case 3:
        if(roleStep?.value !== 'true') {
          roleStep.setValue('true');
          this.stepState[3] = 'done';
          this.stepper.steps.get(3).interacted = true;
        }
        break;
      case 4:
        if(documentStep?.value !== 'true') {
          documentStep.setValue('true');
          this.stepState[4] = 'done';
          this.stepper.steps.get(4).interacted = true;
        }
        break;
      case 5:
        if(timelineStep?.value !== 'true') {
          timelineStep.setValue('true');
          this.stepState[5] = 'done';
          this.stepper.steps.get(5).interacted = true;
        }
        break;
      default:
    }
  }

  /**
   * Event listener for mat-stepper so on click, we check the status of the page and
   * determine if the user can navigate away or if we show the banner.
   */
  errorBannerStepCheck() {
    const infoStep = this.stepperForm.controls['infoStep'];
    const typeStep = this.stepperForm.controls['typeStep'];
    const contactStep = this.stepperForm.controls['contactStep'];
    const roleStep = this.stepperForm.controls['roleStep'];
    const timelineStep = this.stepperForm.controls['timelineStep'];
    switch(this.showStep) {
      case 0:
        if(infoStep.value === '' && this.stepChanged[0]) {
          this.showNavErrorBanner(Constants.NAVIGATION_ERROR_DETAIL, '#audit-info-alert-div');
        }
        break;
      case 1:
        if(typeStep.value === '' && this.stepChanged[1]) {
          this.showNavErrorBanner(Constants.NAVIGATION_ERROR_DETAIL, '#audit-type-alert-div');
        }
        break;
      case 2:
        if(contactStep.value === '' && this.stepChanged[2]) {
          this.showNavErrorBanner(Constants.NAVIGATION_ERROR_DETAIL, '#audit-contact-alert-div');
        }
        break;
      case 3:
        if(roleStep.value === '' && this.stepChanged[3]) {
          this.showNavErrorBanner(Constants.NAVIGATION_ERROR_DETAIL_NO_CANCEL, '#audit-role-alert-div');
        }
        break;
      case 5:
        if(timelineStep.value === '' && this.stepChanged[5]) {
          this.showNavErrorBanner(Constants.NAVIGATION_ERROR_DETAIL, '#audit-timeline-alert-div');
        }
        break;
      default:
        break;
    }
  }

  /**
   * Event listener to show a Warning banner if a value change was detected in the form.
   * Sets a flag that can be passed to the children forms to display the warning banner.
   * We set the value back to '' if we are showing the warning banner because we do not want
   * the user to move to further steps.
   * Note: We are not including Upload Documents because every interaction with the page "saves".
   *
   * @param step - emitted step number from children forms
   * @param showWarning - flag to show warning banner
   */
  showWarning(step: number, showWarning: boolean) {
    const infoStep = this.stepperForm.controls['infoStep'];
    const typeStep = this.stepperForm.controls['typeStep'];
    const contactStep = this.stepperForm.controls['contactStep'];
    const roleStep = this.stepperForm.controls['roleStep'];
    const timelineStep = this.stepperForm.controls['timelineStep'];
    switch(step) {
      case 0:
        this.stepChanged[0] = showWarning;
        if(showWarning) {
          infoStep.setValue('');
        }
        break;
      case 1:
        this.stepChanged[1] = showWarning;
        if(showWarning) {
          typeStep.setValue('');
        }
        break;
      case 2:
        this.stepChanged[2] = showWarning;
        if(showWarning) {
          contactStep.setValue('');
        }
        break;
      case 3:
        this.stepChanged[3] = showWarning;
        if(showWarning) {
          roleStep.setValue('');
        }
        break;
      case 5:
        this.stepChanged[5] = showWarning;
        if(showWarning) {
          timelineStep.setValue('');
        }
        break;
      default:
        break;
    }
  }

  setIsAuditPricing(value: any){
    this.isAuditPrice = value;
  }

  get clientCoalitionLabel() {
    return AuditRequestUtil.getClientOrCoalitionLabel(this.auditDetail?.auditInformation?.coalition);
  }

  get clientOrAccountName() {
    return AuditRequestUtil.getClientOrAccountName(this.auditDetail);
  }

}
