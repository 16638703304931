export const environment = {
  production: false,
  name: 'local',
  region: '- LOCAL',
  backendUrl: 'http://localhost:8080',
  salesforceUrl: 'https://pbmcvshealth--preprod.sandbox.lightning.force.com/lightning/r',
  myPBMSiteURL: 'https://portal-web-dev.core.mypbm-np.caremark.com',
  authorizationUrl: 'https://dev.api.mypbm-np.caremark.com/portal-api-dev/api/v1/auth/authorizedUser/ams',
  ssoBaseUrl: 'https://ngamypbmnonprod.b2clogin.com/ngamypbmnonprod.onmicrosoft.com',
  issuer: 'https://ngamypbmnonprod.b2clogin.com/2a2762e7-d56f-47ab-a72a-df0102c4579a/v2.0/',
  clientId: 'f9aa5503-92cb-4df3-9c3c-5da553f29a01',
  tokenUrl: 'https://ngamypbmnonprod.b2clogin.com/ngamypbmnonprod.onmicrosoft.com/oauth2/v2.0/token?p=B2C_1A_signup_signin',
  accessDeniedMessage: 'It appears as though you do not have access to use this application. ' +
    'If you think this is an error, please contact a BRM or the Administrator of this tool',
  authenticationEnabled: true,
  useAccessToken: false,
  idle: 600,
  timeOut: 300,
  discoveryDocumentUrl: 'https://ngamypbmnonprod.b2clogin.com/ngamypbmnonprod.onmicrosoft.com/' +
    'v2.0/.well-known/openid-configuration?p=B2C_1A_signup_signin',
  scope: 'openid offline_access',
  loginUrl: '/B2C_1A_signup_signin/oauth2/v2.0/authorize',
  logoutUrl: '/B2C_1A_signup_signin/oauth2/v2.0/logout',
  appDynamicsKey: ''
};
