import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { TimeLine } from 'src/app/models/time-line.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotesService {

  constructor(private _http: HttpClient) {}

  headers!: HttpHeaders;
  respe !: HttpResponse<string>;

  updateOrDeleteNotesRequest(body: TimeLine): Observable<any>{
    this.headers = new HttpHeaders({
      createdById: sessionStorage.getItem('email') || '',
      createdByName: sessionStorage.getItem('name') || ''
    });

    return this._http.post<any>(environment.backendUrl+'/audit/notes', body,
    {headers: this.headers});
  }

}
