import {Component, OnInit} from '@angular/core';
import {UserService} from '../services/user.service';
import {Router} from '@angular/router';
import {AuthenticationService} from 'angular-component-library';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  isUnknownUser = true;
  constructor(
    private userService: UserService,
    private router: Router, private authenticationService: AuthenticationService
  ) {
  }

  ngOnInit() {
    this.routeUser();
  }


  routeUser() {
    if(this.userService.isExternalUser()) {
      console.log('External user');
      this.isUnknownUser = false;
      this.router.navigate(['/external']).then();
    } else if(this.userService.isInternalUser()) {
      console.log('Internal user');
      this.isUnknownUser = false;
      this.router.navigate(['/internal']).then();
    } else {
      this.isUnknownUser = true;
      console.log('Unknown user');
    }
  }

  logOut() {
    this.userService.clearUserSession();
    this.authenticationService.logout();
  }
}
