<ng-container *ngIf="documentCategories$ | async as documentCategories">
  <div id="audit-documents-alert-div">
  </div>
    <div class="pt-10">
        <h2 class="pt-24">Upload Documents</h2>
        <p class="paragraph-style" *ngIf="auditRequestMode !== 'review'">Please attach the following required documents by using Select File.
          Once you have finished uploading your documents, you can navigate to the next step of the audit request by
          selecting "Projected Timelines" in the progress menu on the left side of the page.</p>
        <p class="paragraph-style" *ngIf="auditRequestMode === 'review'">The primary auditor has uploaded all required documents. Please review
          the attached files and select Complete Start Up Process in the progress menu to move to the next step in the Audit Review.</p>
    </div>
    <div *ngFor="let category of documentCategories">
        <app-upload-docs
            [header]="category.uploadDocName"
            [uploadDocId]="category.uploadDocId"
            [amRecordId]="recordId"
            [auditId]="auditDetail.auditId"
            [files]="category.documentDetailDtoList"
            [auditRequestMode]="auditRequestMode"
            [outletId]="'#audit-documents-alert-div'"
            *ngIf="!(category.uploadDocCode === 'COALITIONLISTWITHLOB*' && !isCoalition)"
            (uploadedFileGroup)="checkFormValidity($event)"
            >
        </app-upload-docs>
    </div>
</ng-container>
